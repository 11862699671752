import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IUser } from '@modules/Users/user.model';
import { UserService } from '@modules/Users/services/UserService';
import { UserFormBuilder } from '@modules/Users/services/UserFormBuilder';

@Component({
  selector: 'sf-app-user-edit-form-actions',
  templateUrl: './user-edit-form-actions.component.html',
  styleUrls: ['./user-edit-form-actions.component.scss'],
})
export class UserEditFormActionsComponent implements OnInit {
  @Input() formUser: FormGroup;
  public error: any = null;
  public submitting = false;
  public password: string;
  @Input() user: IUser;
  public isSendResetPasswordEnabled = false;
  public isSetPasswordEnabled =  false;
  constructor(
    private service: UserService,
    private formBuilder: UserFormBuilder,
  ) { }

  ngOnInit() {
  }

  async resetPassword() {
    const user = this.formBuilder.map(this.formUser, this.user);
    try {
      this.isSendResetPasswordEnabled = true;
      this.submitting = true;
      await this.service.resetPassword(user);
    } catch (error) {
      this.error = error;
    } finally {
      this.isSendResetPasswordEnabled = false;
      this.submitting = false;
    }
  }

  async setPassword() {
    try {
       if (!this.password || (this.password && this.password.length < 8)) {
        return;
      }
      const user = this.formBuilder.map(this.formUser, this.user);
      this.isSetPasswordEnabled = true;
      this.submitting = true;
      await this.service.setUserPassword(this.password, user);
    } catch (error) {
      this.error = error;
    } finally {
      this.isSetPasswordEnabled = false;
      this.submitting = false;
    }
  }

  isValid(date: any) {
    return (date !== '0001-01-01T00:00:00') && (date !== '0001-01-01T00:00:00+00:00');
  }

}
