/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./product-select.component";
var styles_ProductSelectComponent = [i0.styles];
var RenderType_ProductSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductSelectComponent, data: {} });
export { RenderType_ProductSelectComponent as RenderType_ProductSelectComponent };
function View_ProductSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "col-md-4 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "figure", [["class", "figure"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "figure-img img-fluid"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "figcaption", [["class", "figure-caption"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.src, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.alt, ""); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.imgCaption; _ck(_v, 5, 0, currVal_2); }); }
export function View_ProductSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Client home page"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Simplifai AS product"])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "fieldset", [["class", "scheduler-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "legend", [["class", "scheduler-border"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Available Products"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductSelectComponent_1)), i1.ɵdid(11, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productData; _ck(_v, 11, 0, currVal_0); }, null); }
export function View_ProductSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sf-product-select", [], null, null, null, View_ProductSelectComponent_0, RenderType_ProductSelectComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProductSelectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductSelectComponentNgFactory = i1.ɵccf("sf-product-select", i3.ProductSelectComponent, View_ProductSelectComponent_Host_0, { productData: "productData" }, {}, []);
export { ProductSelectComponentNgFactory as ProductSelectComponentNgFactory };
