import { config } from '../environments/environment';
import { DataService } from '@core/services/DataService';
import { TranslationLoader } from '@core/services/TranslationLoader';
var ɵ0 = function createTranslateLoader(dataService) {
    return new TranslationLoader(dataService);
}, ɵ1 = config;
var CoreModule = /** @class */ (function () {
    function CoreModule() {
    }
    return CoreModule;
}());
export { CoreModule };
export { ɵ0, ɵ1 };
