var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Validators } from '@angular/forms';
import { FormHelper } from '@core/services/FormHelper';
import { StringHelper } from '@core/utils/StringHelper';
import { map } from 'lodash';
import { CustomValidators } from '@core/utils/CustomeValidator';
import { regexUrl1, regexUrl2 } from '@config/constants';
var IdentityClientFormBuilder = /** @class */ (function (_super) {
    __extends(IdentityClientFormBuilder, _super);
    function IdentityClientFormBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IdentityClientFormBuilder.prototype.createEmpty = function () {
        return this.fromModel({
            clientId: '',
            clientName: '',
            allowedScopes: [],
            allowedCorsOrigins: [],
            allowedGrantTypes: [],
            protocolType: '',
            clientUri: '',
            logoUri: '',
            requireClientSecret: false,
            requireConsent: false,
            allowRememberConsent: false,
            requirePkce: false,
            allowPlainTextPkce: false,
            alwaysIncludeUserClaimsInIdToken: false,
            updateAccessTokenClaimsOnRefresh: false,
            enableLocalLogin: false,
            allowAccessTokensViaBrowser: false,
            includeJwtId: false,
            alwaysSendClientClaims: false,
            clientSecrets: [],
            redirectUris: [],
            postLogoutRedirectUris: [],
            properties: {},
            identityTokenLifetime: 300,
            accessTokenLifetime: 3600,
            authorizationCodeLifetime: 300,
            absoluteRefreshTokenLifetime: 2592000,
            slidingRefreshTokenLifetime: 1296000,
            consentLifetime: null,
            refreshTokenUsage: 1,
            refreshTokenExpiration: 1,
            accessTokenType: 0,
            clientClaimsPrefix: 'client_',
            pairWiseSubjectSalt: '',
            deleteClientSecretsIds: [],
            deleteAllowedGrantTypesIds: [],
            deletePropertiesIds: [],
            deleteAllowedScopesIds: [],
            deletePostLogoutRedirectUrisIds: [],
            deleteRedirectUrisIds: [],
            deleteAllowedCorsOriginsIds: [],
        });
    };
    IdentityClientFormBuilder.prototype.fromModel = function (model) {
        var _this = this;
        return this.builder.group({
            id: [{ value: model.id, disabled: true }],
            clientId: [model.clientId, [
                    Validators.required,
                    Validators.pattern('[0-9A-Za-z_.-]+'),
                    Validators.maxLength(75)
                ],
            ],
            clientName: [model.clientName, [
                    Validators.required,
                    Validators.pattern('[0-9A-Za-z_-\\s]+'),
                    Validators.maxLength(75)
                ],
            ],
            allowedScopes: this.builder.array(map(model.allowedScopes, function (scope) { return _this.createScope(scope); })),
            allowedCorsOrigins: this.builder.array(map(model.allowedCorsOrigins, function (origin) { return _this.createCorsOrigin(origin); })),
            allowedGrantTypes: this.builder.array(map(model.allowedGrantTypes, function (grant) { return _this.createGrantType(grant); })),
            protocolType: [model.protocolType, [Validators.maxLength(75)]],
            clientUri: [model.clientUri, [Validators.minLength(0), Validators.maxLength(75),
                    CustomValidators.pattern([regexUrl1, regexUrl2])]],
            logoUri: [model.logoUri, [Validators.minLength(0), Validators.maxLength(75),
                    CustomValidators.pattern([regexUrl1, regexUrl2])]],
            requireClientSecret: [model.requireClientSecret],
            requireConsent: [model.requireConsent],
            allowRememberConsent: [model.allowRememberConsent],
            requirePkce: [model.requirePkce],
            allowPlainTextPkce: [model.allowPlainTextPkce],
            alwaysIncludeUserClaimsInIdToken: [model.alwaysIncludeUserClaimsInIdToken],
            updateAccessTokenClaimsOnRefresh: [model.updateAccessTokenClaimsOnRefresh],
            enableLocalLogin: [model.enableLocalLogin],
            allowAccessTokensViaBrowser: [model.allowAccessTokensViaBrowser],
            includeJwtId: [model.includeJwtId],
            alwaysSendClientClaims: [model.alwaysSendClientClaims],
            clientSecrets: this.builder.array(map(model.clientSecrets, function (secret) { return _this.createSecrets(secret); })),
            redirectUris: this.builder.array(map(model.redirectUris, function (redirectUri) { return _this.createRedirectUri(redirectUri); })),
            postLogoutRedirectUris: this.builder.array(map(model.postLogoutRedirectUris, function (postLogoutRedirectUri) { return _this.createPostRedirectUri(postLogoutRedirectUri); })),
            properties: this.builder.array(map(model.properties, function (property) { return _this.createProperties(property); })),
            identityTokenLifetime: [model.identityTokenLifetime || 300,
                [Validators.min(0), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
            accessTokenLifetime: [model.accessTokenLifetime || 3600,
                [Validators.min(0), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
            authorizationCodeLifetime: [model.authorizationCodeLifetime || 300,
                [Validators.min(0), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
            absoluteRefreshTokenLifetime: [model.absoluteRefreshTokenLifetime || 2592000,
                [Validators.min(0), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
            slidingRefreshTokenLifetime: [model.slidingRefreshTokenLifetime || 1296000,
                [Validators.min(0), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
            consentLifetime: [model.consentLifetime,
                [Validators.min(0), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
            refreshTokenUsage: [model.refreshTokenUsage],
            refreshTokenExpiration: [model.refreshTokenExpiration],
            accessTokenType: [model.accessTokenType],
            clientClaimsPrefix: [model.clientClaimsPrefix, [Validators.maxLength(75)]],
            pairWiseSubjectSalt: [model.pairWiseSubjectSalt, [Validators.maxLength(75)]],
            deleteClientSecretsIds: [model.deleteClientSecretsIds],
            deleteAllowedGrantTypesIds: [model.deleteAllowedGrantTypesIds],
            deletePropertiesIds: [model.deletePropertiesIds],
            deleteAllowedScopesIds: [model.deleteAllowedScopesIds],
            deletePostLogoutRedirectUrisIds: [model.deletePostLogoutRedirectUrisIds],
            deleteRedirectUrisIds: [model.deleteRedirectUrisIds],
            deleteAllowedCorsOriginsIds: [model.deleteAllowedCorsOriginsIds],
        });
    };
    IdentityClientFormBuilder.prototype.formClientData = function (model) {
        model.allowedGrantTypes = StringHelper.toArray(model.allowedGrantTypes);
        model.allowedCorsOrigins = StringHelper.toArray(model.allowedCorsOrigins);
        model.allowedScopes = StringHelper.toArray(model.allowedScopes);
        return model;
    };
    IdentityClientFormBuilder.prototype.createRedirectUri = function (redirectUri) {
        return this.builder.group({
            id: redirectUri.id || 0,
            redirectUri: [redirectUri.redirectUri || '', [Validators.required, Validators.minLength(0), Validators.maxLength(2000),
                    CustomValidators.pattern([regexUrl1, regexUrl2])]],
            deleteOperation: 1,
        });
    };
    IdentityClientFormBuilder.prototype.createPostRedirectUri = function (postUri) {
        return this.builder.group({
            id: postUri.id || 0,
            postLogoutRedirectUri: [postUri.postLogoutRedirectUri || '',
                [Validators.required, Validators.minLength(0), Validators.maxLength(2000),
                    CustomValidators.pattern([regexUrl1, regexUrl2])]],
            deleteOperation: 1,
        });
    };
    IdentityClientFormBuilder.prototype.createCorsOrigin = function (origin) {
        return this.builder.group({
            id: origin.id || 0,
            origin: [origin.origin || '', [Validators.required, Validators.minLength(0), Validators.maxLength(150),
                    CustomValidators.pattern([regexUrl1, regexUrl2])]],
            deleteOperation: 1,
        });
    };
    IdentityClientFormBuilder.prototype.createGrantType = function (grant) {
        return this.builder.group({
            id: grant.id || 0,
            grantType: [grant.grantType || '', [Validators.required, Validators.minLength(0), Validators.maxLength(250)]],
            deleteOperation: 1,
        });
    };
    IdentityClientFormBuilder.prototype.createScope = function (scope) {
        return this.builder.group({
            id: scope.id || 0,
            scope: [scope.scope || '', [Validators.required, Validators.minLength(0), Validators.maxLength(200)]],
            deleteOperation: 1,
        });
    };
    IdentityClientFormBuilder.prototype.createProperties = function (property) {
        return this.builder.group({
            id: property.id || 0,
            key: [property.key || '', [Validators.required, Validators.minLength(0), Validators.maxLength(250)]],
            value: [property.value || '', [Validators.required, Validators.minLength(0), Validators.maxLength(2000)]],
            deleteOperation: 1,
        });
    };
    IdentityClientFormBuilder.prototype.createSecrets = function (secret) {
        return this.builder.group({
            id: secret.id || 0,
            description: [secret.description || '', [Validators.maxLength(2000)]],
            value: [secret.value || '', Validators.required],
            expiration: new Date(secret.expiration || new Date()),
            type: [secret.type || '', [Validators.required, Validators.minLength(0), Validators.maxLength(250)]],
            deleteOperation: 1,
        });
    };
    return IdentityClientFormBuilder;
}(FormHelper));
export { IdentityClientFormBuilder };
