import { OnInit, OnDestroy, ElementRef, AfterViewChecked } from '@angular/core';
import { ToastService } from './toast.service';
import { assignIn, isEqual, map } from 'lodash';
var TOAST_EXIT_DURATION = 300;
var ToastContainerComponent = /** @class */ (function () {
    function ToastContainerComponent(service) {
        this.service = service;
        this.toasts = [];
        this.lastOrder = [];
        this.timers = [];
        this.handleToast = this.handleToast.bind(this);
    }
    ToastContainerComponent.prototype.ngOnInit = function () {
        this.service.subscribe(this.handleToast);
    };
    ToastContainerComponent.prototype.ngAfterViewChecked = function () {
        var currentOrder = map(this.toasts, 'id');
        if (isEqual(currentOrder, this.lastOrder)) {
            return;
        }
        this.animate();
        this.lastOrder = currentOrder;
    };
    ToastContainerComponent.prototype.ngOnDestroy = function () {
        this.service.unsubscribe();
    };
    ToastContainerComponent.prototype.handleToast = function (toast) {
        this.toasts.push(toast);
        if (toast.autoclose) {
            this.createAutocloseTimer(toast);
        }
    };
    ToastContainerComponent.prototype.trackOrder = function (index, item) {
        return item.id;
    };
    ToastContainerComponent.prototype.animate = function () {
        var element = this.container.nativeElement;
        if (!this.toasts.length) {
            element.style.transform = 'translateY(0)';
            return;
        }
        var children = element.querySelectorAll('.toast');
        var containerBounds = assignIn({}, element.getBoundingClientRect());
        var elementsBounds = Array.from(children).map(function (node) {
            var bounds = node.getBoundingClientRect();
            node.parentElement.style.height = bounds.height + 'px';
            return assignIn({}, bounds);
        });
        var distanceOffset = elementsBounds[0].top - containerBounds.top;
        var targetElementBounds = elementsBounds[this.toasts.length - 1];
        var offset = targetElementBounds.top -
            containerBounds.top +
            targetElementBounds.height +
            distanceOffset;
        var isRemoving = this.toasts.length < this.lastOrder.length;
        if (isRemoving) {
            element.style.transition = 'none';
        }
        window.setTimeout(function () {
            element.style.transform = "translateY(-" + offset + "px)";
            element.style.transition = null;
        }, 0);
    };
    ToastContainerComponent.prototype.closeToast = function (toast) {
        var index = this.toasts.indexOf(toast);
        this.clearTimer(toast.id);
        this.toasts.splice(index, 1);
    };
    ToastContainerComponent.prototype.onClose = function (toast) {
        this.closeToast(toast);
    };
    ToastContainerComponent.prototype.createAutocloseTimer = function (toast) {
        var _this = this;
        var timer = window.setTimeout(function () {
            _this.closeToast(toast);
        }, toast.timeout);
        this.timers.push({
            id: toast.id,
            timer: timer,
        });
    };
    ToastContainerComponent.prototype.clearTimer = function (id) {
        var timerIndex = this.timers.findIndex(function (item) {
            return item.id === id;
        });
        if (timerIndex >= 0) {
            clearTimeout(this.timers[timerIndex].timer);
            this.timers.splice(timerIndex, 1);
        }
    };
    return ToastContainerComponent;
}());
export { ToastContainerComponent };
