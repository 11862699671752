<div class="page">
  <h1 class="table-heading">{{ 'FE_General_Lable_Products' | translate }}</h1>
  <div class="row">
    <div class="col-md-6">
      <a class="btn custom-btn" routerLink="/products/new"><i class="fa fa-plus" aria-hidden="true"></i> {{ 'FE_General_Add' | translate }}</a>
    </div>
    <div class="col-md-6">
      <div class="search-box">
        <i class="fa fa-search fa-2x"></i>
        <input type="text" class="search" placeholder="{{ 'FE_General_Search_PlaceHolder' | translate }}" sfDebouncedInput (debouncedChange)="search($event)" [debounceTime]="700">
      </div>
    </div>
  </div>
  <sf-app-loading [loading]="loading"></sf-app-loading>
  <div class="wrapper" *ngIf="products">
    <sf-products-list [products]="products" [actions]="['edit']" [currentPage]="currentPage"></sf-products-list>
    <div class="d-flex justify-content-center">
      <ngb-pagination *ngIf="totalPages > 1"
        [collectionSize]="totalRecords"
        [maxSize]="5" 
        [(page)]="page" 
        (pageChange) = "loadProducts(page)">
      </ngb-pagination>
    </div>
  </div>
</div>
  