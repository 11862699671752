import { OnInit } from '@angular/core';
import { config } from '@environments/environment';
import { Router } from '@angular/router';
var LogoutCallbackPageComponent = /** @class */ (function () {
    function LogoutCallbackPageComponent(router) {
        this.router = router;
        this.url = config.publicUrl;
    }
    LogoutCallbackPageComponent.prototype.ngOnInit = function () {
        this.router.navigate(['signout']);
    };
    return LogoutCallbackPageComponent;
}());
export { LogoutCallbackPageComponent };
