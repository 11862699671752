import { HttpHeaders } from '@angular/common/http';
import { Endpoints } from '../../config/endpoints';
import { from } from 'rxjs';
/**
 * TranslationLoader override the existing class to implement custom functionality.
 */
var TranslationLoader = /** @class */ (function () {
    function TranslationLoader(dataService) {
        this.dataService = dataService;
    }
    TranslationLoader.prototype.getTranslation = function (language) {
        return from(this.dataService.request(Endpoints.Localization, { headers: this.getHttpHeaders(language) }));
    };
    TranslationLoader.prototype.getHttpHeaders = function (language) {
        return new HttpHeaders().set('Accept-Language', language);
    };
    return TranslationLoader;
}());
export { TranslationLoader };
