import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService, IToastParams } from '../../components/toast/toast.service';

@Injectable()
export class StatusService {

  constructor(private toast: ToastService) {}

  httpError(
    error: HttpErrorResponse,
    options: Partial<IToastParams> = {},
  ) {
    this.toast.error({
      title: 'Network error',
      subtitle: error.statusText,
      footer: `Timestamp: ${new Date().toLocaleString()}`,
      autoclose: false,
      ...options,
    });
  }

}
