<div class="container-fluid">
  <h1>Simplifai</h1>
  <h5 class="text-muted">Customer access</h5>
  <div class="row pt-4">
    <p>We found that your user has access to more than one client. Please select client you would like to connect form</p>
  </div>
  <div class="pt-2">
    <fieldset class="scheduler-border">
      <legend class="scheduler-border">Available Customers</legend>
      <div class="row">
        <div *ngFor="let data of clientData" class="col-md-6 text-center">
          <a routerLink="/access/select/product-select">
            <figure class="figure">
              <img src="{{data.src}}" class="figure-img img-fluid" alt="{{data.alt}}">
              <figcaption class="figure-caption">{{data.imgCaption}}</figcaption>
            </figure>
          </a>
        </div>
      </div>
    </fieldset>
  </div>
</div>
