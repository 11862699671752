/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./products.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../../modules/Product/components/products-list/products-list.component.ngfactory";
import * as i5 from "../../../modules/Product/components/products-list/products-list.component";
import * as i6 from "../../../modules/Customer/services/CustomerService";
import * as i7 from "../../../modules/Product/services/ProductService";
import * as i8 from "../../../core/services/ExceptionHandlingService";
import * as i9 from "@angular/common";
import * as i10 from "@ngx-translate/core";
import * as i11 from "@angular/router";
import * as i12 from "../../../directives/debounce.directive";
import * as i13 from "../loading/loading.component.ngfactory";
import * as i14 from "../loading/loading.component";
import * as i15 from "./products.page";
var styles_ProductsPageComponent = [i0.styles];
var RenderType_ProductsPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductsPageComponent, data: {} });
export { RenderType_ProductsPageComponent as RenderType_ProductsPageComponent };
function View_ProductsPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngb-pagination", [["role", "navigation"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = ((_co.page = $event) !== false);
        ad = (pd_0 && ad);
    } if (("pageChange" === en)) {
        var pd_1 = (_co.loadProducts(_co.page) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NgbPagination_0, i2.RenderType_NgbPagination)), i1.ɵdid(1, 573440, null, 0, i3.NgbPagination, [i3.NgbPaginationConfig], { collectionSize: [0, "collectionSize"], maxSize: [1, "maxSize"], page: [2, "page"] }, { pageChange: "pageChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.totalRecords; var currVal_1 = 5; var currVal_2 = _co.page; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ProductsPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "sf-products-list", [], null, null, null, i4.View_ProductsListComponent_0, i4.RenderType_ProductsListComponent)), i1.ɵdid(2, 49152, null, 0, i5.ProductsListComponent, [i6.CustomerService, i7.ProductService, i3.NgbModal, i8.ExcepionHandlingService], { products: [0, "products"], actions: [1, "actions"], currentPage: [2, "currentPage"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductsPageComponent_2)), i1.ɵdid(6, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.products; var currVal_1 = _ck(_v, 3, 0, "edit"); var currVal_2 = _co.currentPage; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.totalPages > 1); _ck(_v, 6, 0, currVal_3); }, null); }
export function View_ProductsPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "table-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "a", [["class", "btn custom-btn"], ["routerLink", "/products/new"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i11.RouterLinkWithHref, [i11.Router, i11.ActivatedRoute, i9.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", ""])), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "search-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "fa fa-search fa-2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "input", [["class", "search"], ["sfDebouncedInput", ""], ["type", "text"]], [[8, "placeholder", 0]], [[null, "debouncedChange"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).changeEvent($event) !== false);
        ad = (pd_0 && ad);
    } if (("debouncedChange" === en)) {
        var pd_1 = (_co.search($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 81920, null, 0, i12.DebounceDirective, [], { debounceTime: [0, "debounceTime"] }, { debouncedChange: "debouncedChange" }), i1.ɵpid(131072, i10.TranslatePipe, [i10.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, null, 1, "sf-app-loading", [], null, null, null, i13.View_LoadingComponent_0, i13.RenderType_LoadingComponent)), i1.ɵdid(18, 114688, null, 0, i14.LoadingComponent, [], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductsPageComponent_1)), i1.ɵdid(20, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "/products/new"; _ck(_v, 7, 0, currVal_3); var currVal_6 = 700; _ck(_v, 15, 0, currVal_6); var currVal_7 = _co.loading; _ck(_v, 18, 0, currVal_7); var currVal_8 = _co.products; _ck(_v, 20, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("FE_General_Lable_Products")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).target; var currVal_2 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("FE_General_Add")); _ck(_v, 9, 0, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 16).transform("FE_General_Search_PlaceHolder")), ""); _ck(_v, 14, 0, currVal_5); }); }
export function View_ProductsPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ProductsPageComponent_0, RenderType_ProductsPageComponent)), i1.ɵdid(1, 114688, null, 0, i15.ProductsPageComponent, [i7.ProductService, i8.ExcepionHandlingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductsPageComponentNgFactory = i1.ɵccf("ng-component", i15.ProductsPageComponent, View_ProductsPageComponent_Host_0, {}, {}, []);
export { ProductsPageComponentNgFactory as ProductsPageComponentNgFactory };
