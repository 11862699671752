import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserService } from '@modules/Users/services/UserService';
import { UserFormBuilder } from '@modules/Users/services/UserFormBuilder';
import { IUser } from '@modules/Users/user.model';

@Component({
  selector: 'sf-app-user-edit-form',
  templateUrl: './user-edit-form.component.html',
  styleUrls: ['./user-edit-form.component.scss'],
})
export class UserEditFormComponent implements OnInit {
  @Input() formUser: FormGroup;
  @Input() showMsg: boolean;
  @Input() showVerificationField: boolean;
  public error: any = null;
  public submitting = false;
  @Input() user: IUser;
  public isEmailVerificationEnabled = false;

  public loginTypes = [
    { key: 'Ordinary', value: '0' },
    { key: 'Microsoft', value: '1' },
    { key: 'Google', value: '2' },
  ];

  constructor(
    private service: UserService,
    private formBuilder: UserFormBuilder,
  ) { }

  ngOnInit() {
  }

  get controls() {
    return this.formUser.controls;
  }


  async verifyEmail() {
    const user = this.formBuilder.map(this.formUser, this.user);
    try {
      this.isEmailVerificationEnabled = true;
      this.submitting = true;
      await this.service.emailVerification(user);
    } catch (error) {
      this.error = error;
    } finally {
      this.isEmailVerificationEnabled = false;
      this.submitting = false;
    }
  }
}
