import { EventEmitter } from '@angular/core';
var ToastMessageComponent = /** @class */ (function () {
    function ToastMessageComponent() {
        this.close = new EventEmitter();
    }
    ToastMessageComponent.prototype.onClose = function () {
        this.close.emit();
    };
    return ToastMessageComponent;
}());
export { ToastMessageComponent };
