var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormHelper } from '@core/services/FormHelper';
import { Validators } from '@angular/forms';
import { regexName } from '@config/constants';
var ProductFormBuilder = /** @class */ (function (_super) {
    __extends(ProductFormBuilder, _super);
    function ProductFormBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductFormBuilder.prototype.createEmpty = function () {
        return this.fromModel({
            name: '',
            webpage: '',
            code: 0,
            description: '',
        });
    };
    ProductFormBuilder.prototype.fromModel = function (model) {
        return this.builder.group({
            name: [model.name, [Validators.required,
                    Validators.pattern(regexName),
                    Validators.maxLength(75)],
            ],
            webpage: [model.webpage],
            code: [model.code, [
                    Validators.required,
                    Validators.pattern('^[0-9]*$'),
                    Validators.maxLength(5)
                ],
            ],
            description: [model.description, [Validators.maxLength(1000)]],
        });
    };
    return ProductFormBuilder;
}(FormHelper));
export { ProductFormBuilder };
