import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
var IdentityClientsFormOthersComponent = /** @class */ (function () {
    function IdentityClientsFormOthersComponent() {
    }
    IdentityClientsFormOthersComponent.prototype.ngOnInit = function () {
    };
    IdentityClientsFormOthersComponent.prototype.setDropDownValue = function (value, formControl) {
        this.form.controls[formControl].patchValue(value);
    };
    return IdentityClientsFormOthersComponent;
}());
export { IdentityClientsFormOthersComponent };
