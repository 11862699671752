/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./products-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../modules/AccessManager/component/product-select/product-select.component.ngfactory";
import * as i3 from "../../../modules/AccessManager/component/product-select/product-select.component";
import * as i4 from "./products-select.component";
var styles_ProductsSelectPageComponent = [i0.styles];
var RenderType_ProductsSelectPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductsSelectPageComponent, data: {} });
export { RenderType_ProductsSelectPageComponent as RenderType_ProductsSelectPageComponent };
export function View_ProductsSelectPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sf-product-select", [], null, null, null, i2.View_ProductSelectComponent_0, i2.RenderType_ProductSelectComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProductSelectComponent, [], { productData: [0, "productData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProductsSelectPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sf-products-select", [], null, null, null, View_ProductsSelectPageComponent_0, RenderType_ProductsSelectPageComponent)), i1.ɵdid(1, 114688, null, 0, i4.ProductsSelectPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductsSelectPageComponentNgFactory = i1.ɵccf("sf-products-select", i4.ProductsSelectPageComponent, View_ProductsSelectPageComponent_Host_0, {}, {}, []);
export { ProductsSelectPageComponentNgFactory as ProductsSelectPageComponentNgFactory };
