import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IApiResource } from '@modules/IdentityServer/identityServer.model';
import { ApiResourceService } from '@modules/IdentityServer/services/ApiResourceService';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sf-api-resources-list',
  templateUrl: './api-resources-list.component.html',
  styleUrls: ['./api-resources-list.component.scss'],
})
export class ApiResourcesListComponent implements OnInit {

  @Input() apiResources: IApiResource[];
  @Input() error: any;
  @Output() update = new EventEmitter<void>();
  public resource: IApiResource;
  public modalReference: NgbModalRef;

  constructor(
    private service: ApiResourceService,
    private exception: ExcepionHandlingService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
  }

  confirmModal(content: any, resource: IApiResource) {
    this.resource = resource;
    this.modalReference = this.modalService.open(content, { centered: true });
  }

  async delete() {
    try {
      await this.service.delete(this.resource);
      this.update.emit();
    } catch (error) {
      this.error = this.exception.errorDisplay(error);
    } finally {
      this.modalReference.close();
     }

  }

}
