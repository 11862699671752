var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ToastService } from '../../components/toast/toast.service';
var StatusService = /** @class */ (function () {
    function StatusService(toast) {
        this.toast = toast;
    }
    StatusService.prototype.httpError = function (error, options) {
        if (options === void 0) { options = {}; }
        this.toast.error(__assign({ title: 'Network error', subtitle: error.statusText, footer: "Timestamp: " + new Date().toLocaleString(), autoclose: false }, options));
    };
    return StatusService;
}());
export { StatusService };
