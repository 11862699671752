import { Component, OnInit, Input } from '@angular/core';
import { IRolesUploadResponse } from '@modules/Roles/role.model';
import { RoleService } from '@modules/Roles/services/role.service';

@Component({
  selector: 'sf-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss'],
})
export class RolesListComponent implements OnInit {
  @Input() rolesResult: IRolesUploadResponse;
  public searchVal: string;
  constructor (private roleService: RoleService) { }

  ngOnInit() {
    this.roleService.rolesStatus.subscribe(
      (data: IRolesUploadResponse) => this.rolesResult = data,
    );
  }
}
