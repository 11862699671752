var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { EventEmitter } from '@angular/core';
import { Generator } from '@core/utils/Generator';
import { DEFAULT_TOAST_TIMEOUT } from '@config/constants';
export var ToastType;
(function (ToastType) {
    ToastType["Success"] = "success";
    ToastType["Warning"] = "warning";
    ToastType["Info"] = "info";
    ToastType["Error"] = "error";
})(ToastType || (ToastType = {}));
var ToastService = /** @class */ (function () {
    function ToastService() {
        this.change = new EventEmitter();
    }
    ToastService.prototype.notify = function (data) {
        this.change.emit(data);
    };
    ToastService.prototype.subscribe = function (callback) {
        this.change.subscribe(callback);
    };
    ToastService.prototype.unsubscribe = function () {
        this.change.unsubscribe();
    };
    ToastService.prototype.success = function (data) {
        this.createNotification(data, ToastType.Success);
    };
    ToastService.prototype.warning = function (data) {
        this.createNotification(data, ToastType.Warning);
    };
    ToastService.prototype.info = function (data) {
        this.createNotification(data, ToastType.Info);
    };
    ToastService.prototype.error = function (data) {
        this.createNotification(data, ToastType.Error);
    };
    ToastService.prototype.createNotification = function (data, type) {
        var autoclose = data.autoclose === undefined ? true : data.autoclose;
        this.notify(__assign({ id: Generator.randomKey(), type: type,
            autoclose: autoclose, timeout: autoclose ? DEFAULT_TOAST_TIMEOUT : Infinity }, data));
    };
    return ToastService;
}());
export { ToastService };
