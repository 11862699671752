/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/router";
import * as i4 from "./dashboard.page";
import * as i5 from "../../../core/services/DataService";
var styles_DashboardPageComponent = [i0.styles];
var RenderType_DashboardPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardPageComponent, data: {} });
export { RenderType_DashboardPageComponent as RenderType_DashboardPageComponent };
export function View_DashboardPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "jumbotron"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [["class", "display-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [["class", "lead"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 0, "hr", [["class", "my-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 12, "p", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "button", [["class", "btn-custom-primary mr-1"], ["role", "button"], ["routerLink", "/customers"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "label", [["class", "btn-lable-primary"], ["routerLink", "/customers"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 5, "button", [["class", "btn-custom-primary"], ["role", "button"], ["routerLink", "/products"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 3, "label", [["class", "btn-lable-primary"], ["routerLink", "/products"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, null, 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(20, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = "/customers"; _ck(_v, 11, 0, currVal_2); var currVal_3 = "/customers"; _ck(_v, 13, 0, currVal_3); var currVal_5 = "/products"; _ck(_v, 17, 0, currVal_5); var currVal_6 = "/products"; _ck(_v, 19, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("FE_Dashboard_Welcome")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("FE_Dashboard_Welcome_Title")); _ck(_v, 6, 0, currVal_1); var currVal_4 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("FE_Headers_Label_Customers")); _ck(_v, 14, 0, currVal_4); var currVal_7 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("FE_General_Lable_Products")); _ck(_v, 20, 0, currVal_7); }); }
export function View_DashboardPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DashboardPageComponent_0, RenderType_DashboardPageComponent)), i1.ɵdid(1, 114688, null, 0, i4.DashboardPageComponent, [i5.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardPageComponentNgFactory = i1.ɵccf("ng-component", i4.DashboardPageComponent, View_DashboardPageComponent_Host_0, {}, {}, []);
export { DashboardPageComponentNgFactory as DashboardPageComponentNgFactory };
