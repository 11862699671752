var StringHelper = /** @class */ (function () {
    function StringHelper() {
    }
    StringHelper.toCamelCase = function (value) {
        return value[0].toLowerCase() + value.slice(1);
    };
    StringHelper.toArray = function (value) {
        if (typeof value === 'string' && value.length > 0) {
            return StringHelper.removeWhitespaces(value).split(',');
        }
        else {
            return [];
        }
    };
    StringHelper.removeWhitespaces = function (value) {
        return value.replace(/\s/g, '');
    };
    StringHelper.search = function (collection, value) {
        return collection.filter(function (role) { return role.name.toLowerCase().indexOf(value.toLowerCase()) !== -1; });
    };
    return StringHelper;
}());
export { StringHelper };
