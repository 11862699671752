import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CustomerService } from '@modules/Customer/services/CustomerService';
import { ICustomer } from '@modules/Customer/customer.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sf-customer-list',
  templateUrl: 'customers-list.component.html',
  styleUrls: ['customers-list.component.scss'],
})
export class CustomerListComponent {
  @Input() customers: ICustomer[];
  @Input() currentPageNumber: number;
  @Output() update = new EventEmitter<void>();
  public modalReference: any;
  public customer: ICustomer;

  constructor(private customerService: CustomerService,
    private modalService: NgbModal) {
  }

  confirmModal(content: any, customer: ICustomer) {
    this.customer = customer;
    this.modalReference = this.modalService.open(content, { centered: true });
  }

  async deleteCustomer() {
    try {
      await this.customerService.delete(this.customer);
      this.update.emit();
      this.modalReference.close();
    } catch (error) {
      alert('error occured while customer delete' + error);
    }
  }
}
