import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { IIdentityServerClientResponse } from '@modules/IdentityServer/identityServer.model';
import { IdentityClientFormBuilder } from '@modules/IdentityServer/services/IdentityClientFormBuildur';
import { IdentityClientService } from '@modules/IdentityServer/services/IdentityClientService';
import { NgbModal, NgbModalRef, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'sf-app-identity-clients-form-scopes-secrets',
  templateUrl: './identity-clients-form-scopes-secrets.component.html',
  styleUrls: ['./identity-clients-form-scopes-secrets.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
})
export class IdentityClientsFormScopesSecretsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() client: IIdentityServerClientResponse;
  public showSaveScopes = false;
  public showSaveProperties = false;
  public showSaveClientSecretes = false;
  public editId: number;
  public key: string;
  public newRow = false;
  public items: FormArray;
  public loading = false;
  @Input() error: any;
  public modalReference: NgbModalRef;
  public hashValue: string;
  public isHide = false;
  public deletedType: string;
  public flag = false;
  public oldValue: any;
  public validationMsg = false;
  public duplicateNameValidation = false;
  constructor(
    private formBuilder: IdentityClientFormBuilder,
    private service: IdentityClientService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
  }
  toggle(id: number, key: string) {
    if (this.newRow) {
      const length = this.form.controls[this.key].value.length;
      const control = <FormArray>this.form.controls[this.key];
      control.removeAt(length - 1);
      this.newRow = false;
      this.editId = null;
      this.flag = false;
    }
    this.removeItem(this.key);
    this.hideEdit(this.key);
    this.key = key;
    this.showEdit(key);
    const target = <FormArray>this.form.controls[this.key];
    this.oldValue = target.value[id];
    this.editId = id;
    this.flag = true;
    this.validationMsg = false;
    this.duplicateNameValidation = false;
  }

  resetRedirectUri(key?: string) {
    this.key = key || this.key;
    if (this.newRow) {
      const length = this.form.controls[this.key].value.length;
      const control = <FormArray>this.form.controls[this.key];
      control.removeAt(length - 1);
      this.flag = false;
      this.newRow = false;
    }
    this.removeItem(key);
    this.hideEdit(this.key);
    this.editId = null;
    this.validationMsg = false;
    this.isHide = false;
    this.duplicateNameValidation = false;
  }

  showEdit(key: string) {
    switch (key) {
      case 'allowedScopes':
        this.showSaveScopes = true;
        if (this.newRow) {
          this.items = this.form.controls[key] as FormArray;
          this.items.push(this.formBuilder.createScope(''));
        }
        break;
      case 'properties':
        this.showSaveProperties = true;
        if (this.newRow) {
          this.items = this.form.controls[key] as FormArray;
          this.items.push(this.formBuilder.createProperties(''));
        }
        break;
      case 'clientSecrets':
        this.showSaveClientSecretes = true;
        if (this.newRow) {
          this.items = this.form.controls[key] as FormArray;
          this.items.push(this.formBuilder.createSecrets(''));
        }
        break;
    }
  }

  hideEdit(key: string) {
    switch (key) {
      case 'allowedScopes':
        this.showSaveScopes = false;
        break;
      case 'properties':
        this.showSaveProperties = false;
        break;
      case 'clientSecrets':
        this.showSaveClientSecretes = false;
        break;
    }
  }

  addItem(key: string): void {
    if (this.key !== undefined && this.newRow) {
      const length = this.form.controls[this.key].value.length;
      const control = <FormArray>this.form.controls[this.key];
      control.removeAt(length - 1);
      this.flag = false;
      this.newRow = false;
    }
    this.removeItem(this.key);
    this.hideEdit(this.key);
    this.key = key;
    this.newRow = true;
    this.showEdit(key);
    this.editId = this.items.length - 1;
    this.flag = true;
    this.validationMsg = false;
    this.duplicateNameValidation = false;
  }

  duplicateIdentifier(field: string) {
    const control = <FormArray>this.form.controls[this.key];
    const target = control.controls[this.editId];
    let flag = 0;
    for (let i = 0; i < control.value.length; i++) {
      if (control.value[i][field] === target.value[field] && control.value[i].deleteOperation !== 0) {
        flag++;
      }
    }
    if (flag > 1) {
      this.duplicateNameValidation = true;
      return true;
    }
    this.duplicateNameValidation = false;
    return false;
  }

  update() {
    const control = <FormArray>this.form.controls[this.key];
    const target = control.controls[this.editId];
    if (target.invalid || this.duplicateNameValidation) {
      this.validationMsg = true;
      return;
    }
    this.hideEdit(this.key);
    this.newRow = false;
    this.editId = null;
    this.flag = false;
    this.validationMsg = false;
    this.isHide = false;
    this.duplicateNameValidation = false;
  }

  async updateHashValue(i: number) {
    try {
      this.loading = true;
      const hashValue = await this.service.updateHash();
      const control = <FormArray>this.form.controls['clientSecrets'];
      const target = control.controls[i];
      target.patchValue({ value: hashValue.result.value });
      this.hashValue = hashValue.result.key;
      this.isHide = true;
    } catch (err) {
      this.error = err;
    } finally {
      this.loading = false;
    }
  }

  deleteRedirectUri() {
    const control = <FormArray>this.form.controls[this.key];
    const target = control.controls[this.editId];
    if (target.value['id'] === 0) {
      control.removeAt(this.editId);
    } else {
      target.patchValue({ deleteOperation: 0 });
      this.items = this.form.controls[this.key] as FormArray;
      this.form.controls[this.deletedType].value.push(this.items.value[this.editId]['id']);
    }
    this.editId = null;
    this.modalReference.close();
  }

  confirmModal(content: any, index: number, key: string, deletedType: string) {
    this.hideEdit(this.key);
    this.removeItem(this.key);
    this.flag = false;
    this.newRow = false;
    this.editId = index;
    this.deletedType = deletedType;
    this.key = key;
    this.modalReference = this.modalService.open(content, { centered: true });
  }

  removeItem(key?: string) {
    if (this.newRow) {
      const length = this.form.controls[this.key].value.length;
      const control = <FormArray>this.form.controls[this.key];
      control.removeAt(length - 1);
    } else if (this.flag) {
      this.key = key || this.key;
      const control = <FormArray>this.form.controls[this.key];
      const target = control.controls[this.editId];
      target.patchValue(this.oldValue);
      this.flag = false;
      this.hideEdit(this.key);
      this.editId = null;
    }
  }

}
