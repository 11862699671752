var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { HttpParams } from '@angular/common/http';
import { find, reduce } from 'lodash';
import { StringHelper } from '@core/utils/StringHelper';
var UrlBuilder = /** @class */ (function () {
    function UrlBuilder(config) {
        this.baseUrl = config.apiBaseUrl;
    }
    UrlBuilder.prototype.build = function (params) {
        var path;
        if (params instanceof Array) {
            path = params.join('/');
        }
        else {
            path = params;
        }
        path = this.normalizePath(path);
        return this.baseUrl + path;
    };
    UrlBuilder.prototype.getEndpoint = function (key, links) {
        var result = find(links, function (link) { return link.rel === key; });
        return __assign({}, result, { query: this.getQueryParams(result.href), href: this.build(result.href) });
    };
    UrlBuilder.prototype.findEndpoint = function (key) {
        return this.getEndpoint(key, this.links);
    };
    UrlBuilder.prototype.setEndpoints = function (links) {
        this.links = links.slice();
    };
    UrlBuilder.prototype.getQueryParams = function (url) {
        var index = url.indexOf('?');
        if (index < 0) {
            return {};
        }
        var fromString = url.slice(index + 1);
        var params = new HttpParams({
            fromString: fromString,
        });
        return reduce(params.keys(), function (result, key) {
            var currentKey = StringHelper.toCamelCase(key);
            result[currentKey] = params.get(key);
            return result;
        }, {});
    };
    UrlBuilder.prototype.normalizePath = function (path) {
        var separatorIndex = path.indexOf('?');
        if (separatorIndex < 0) {
            return path;
        }
        else {
            return path.slice(0, separatorIndex);
        }
    };
    return UrlBuilder;
}());
export { UrlBuilder };
