import { Component, OnInit } from '@angular/core';
import { IProduct } from '@modules/Product/product.model';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductFormBuilder } from '@modules/Product/services/ProductFormBuilder';
import { ProductService } from '@modules/Product/services/ProductService';
import { RoleService } from '@modules/Roles/services/role.service';
import { IRolesUploadResponse } from '@modules/Roles/role.model';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  selector: 'sf-product-edit',
  templateUrl: './product-edit.page.html',
  styleUrls: ['./product-edit.page.scss'],
})
export class ProductEditPageComponent implements OnInit {
  public loading = false;
  public disableLogoChange = true;
  public submitting = false;
  public error: any = null;
  public product: IProduct;
  public form: FormGroup;
  public rolesResult: IRolesUploadResponse;
  public selectedTab: string;
  public tabRoutes: any;
  public imagePath: any;

  constructor(
    private service: ProductService,
    private formBuilder: ProductFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private roleService: RoleService,
    private sanitizer: DomSanitizer,
    private exception: ExcepionHandlingService,
  ) { }

  onTabChange($event?: NgbTabChangeEvent) {
    this.loadProduct();
    this.loadRoles();
    this.router.navigateByUrl(this.tabRoutes[$event.nextId]);

  }

  get productId() {
    return this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.tabRoutes = {
      general: '/products/' + this.productId + '/general',
      roles: '/products/' + this.productId + '/roles',
    };
    this.loadProduct();
    this.loadRoles();
    this.selectedTab = this.route.snapshot.params.tab;
  }

  loadingToggle() {
    this.loading = !this.loading;
  }

  async loadProduct() {
    try {
      this.error = null;
      this.loading = true;
      const product = await this.service.getById(this.productId);
      const form = this.formBuilder.fromModel(product);
      this.product = product;
      this.form = form;
      this.getLogo(this.product);
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {

    }
  }

  async getLogo(customer: any) {
    try {
      const logo = await this.service.getLogo(customer);
      if (logo.logoImage) {
        this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + logo.logoImage);
      }
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    }
    finally {
      this.loading = false;
    }
  }

  async onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const product = this.formBuilder.map(this.form, this.product);
    try {
      this.error = null;
      this.loading = true;
      this.submitting = true;
      await this.service.update(product);
      this.router.navigate(['products']);
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
      this.submitting = false;
    }
  }

  async loadRoles() {
    try {
      this.error = null;
      this.loading = true;
      this.rolesResult = await this.roleService.getRoleById(this.productId);
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally { }
  }
}
