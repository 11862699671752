var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { SessionService } from '@core/services/Session';
import { DataService } from '@core/services/DataService';
import { config } from '@environments/environment';
import { StaticEndpoints, Endpoints } from '@config/endpoints';
import { TranslationService } from '@core/services/TranslateService';
var ApplicationLayoutComponent = /** @class */ (function () {
    function ApplicationLayoutComponent(session, dataService, translation) {
        this.session = session;
        this.dataService = dataService;
        this.translation = translation;
        this.links = [
            { href: '/dashboard', title: 'FE_Headers_Label_Dashboard' },
            { href: '/customers', title: 'FE_Headers_Label_Customers' },
            { href: '/products', title: 'FE_Headers_Label_Products' },
            { href: '/identity-resources', title: 'FE_Headers_Label_IdentityResources' },
            { href: '/identity-clients', title: 'FE_Headers_Label_IdentityClients' },
            { href: '/identity/api-resources', title: 'FE_Headers_Label_ApiResources' },
        ];
        this.ready = false;
        this.error = null;
    }
    ApplicationLayoutComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        this.session.startSession();
                        return [4 /*yield*/, this.dataService.defineEndpoints()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getAppStatus()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getUser()];
                    case 3:
                        _a.sent();
                        this.ready = true;
                        this.translation.setLanguagePreference();
                        return [4 /*yield*/, this.getUserProfile()];
                    case 4:
                        _a.sent();
                        this.getLanguageName();
                        return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        this.error = err_1;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ApplicationLayoutComponent.prototype.getAppStatus = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.clientInfo = {
                            environment: config.environmentName,
                            version: config.version,
                        };
                        _a = this;
                        return [4 /*yield*/, this.dataService.get(StaticEndpoints.ApiInfo)];
                    case 1:
                        _a.apiInfo = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApplicationLayoutComponent.prototype.changeLanguage = function (langauge) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.translation.onLanguageChange(langauge)];
                    case 1:
                        _a.sent();
                        this.getLanguageName();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApplicationLayoutComponent.prototype.getUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.request(Endpoints.GetLoggedInUser)];
                    case 1:
                        user = _a.sent();
                        this.loggedUser = user.result.username;
                        return [2 /*return*/];
                }
            });
        });
    };
    ApplicationLayoutComponent.prototype.getUserProfile = function () {
        return __awaiter(this, void 0, void 0, function () {
            var link;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dataService.request(Endpoints.Profilelink)];
                    case 1:
                        link = _a.sent();
                        this.profileLink = link.result.profilePage;
                        return [2 /*return*/];
                }
            });
        });
    };
    ApplicationLayoutComponent.prototype.goToProfile = function () {
        window.location.href = this.profileLink;
    };
    ApplicationLayoutComponent.prototype.logout = function () {
        this.session.logout();
    };
    ApplicationLayoutComponent.prototype.getLanguageName = function () {
        if (sessionStorage.getItem('language') == null || sessionStorage.getItem('language') === 'en') {
            this.languageName = 'English';
        }
        else {
            this.languageName = 'Norwegian';
        }
    };
    return ApplicationLayoutComponent;
}());
export { ApplicationLayoutComponent };
