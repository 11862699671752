var CustomValidators = /** @class */ (function () {
    function CustomValidators() {
    }
    CustomValidators.pattern = function (reg) {
        var _this = this;
        return function (control) {
            var value = control.value;
            if (value !== null && value !== '') {
                var pattern1 = reg[0].test(control.value);
                var pattern2 = reg[1].test(control.value);
                _this.validUrl = (pattern1 || pattern2) ? false : true;
                return (pattern1 || pattern2) ? null : { pattern: true };
            }
        };
    };
    return CustomValidators;
}());
export { CustomValidators };
