import { IPermissionDetail } from '@modules/Roles/role.model';

export class StringHelper {
  static toCamelCase(value: string) {
    return value[0].toLowerCase() + value.slice(1);
  }

  static toArray(value: string): string[] {
    if (typeof value === 'string' && value.length > 0) {
      return StringHelper.removeWhitespaces(value).split(',');
    } else {
      return [];
    }
  }

  static removeWhitespaces(value: string) {
    return value.replace(/\s/g, '');
  }

  static search(collection: IPermissionDetail[], value: string) {
    return collection.filter((role: IPermissionDetail) => role.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
}
