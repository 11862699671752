import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { LoginCallbackPageComponent } from './login-callback/login-callback.page';
import { LogoutCallbackPageComponent } from './logout-callback/logout-callback.page';
import { DashboardPageComponent } from './dashboard/dashboard.page';
import { UsersModule } from '@modules/Users/users.module';
import { CustomertModule } from '@modules/Customer/customer.module';
import { UsersPageComponent } from './users/users.page';
import { CustomersPageComponent } from './customers/customers.page';
import { CustomerEditPageComponent } from './customer-edit/customer-edit.page';
import { CustomerCreatePageComponent } from './customer-create/customer-create.page';
import { FormsModule } from '@angular/forms';
import { ProductModule } from '@modules/Product/product.module';
import { ProductCreatePageComponent } from './product-create/product-create.page';
import { ProductEditPageComponent } from './product-edit/product-edit.page';
import { RolesModule } from '@modules/Roles/roles.module';
import { ComponentsModule } from '../../components/components.module';
import { ProductsPageComponent } from './products/products.page';
import { IdentityResourcesListComponent } from './identity-resources/identity-resources.component';
import { IdentityServerModule } from '@modules/IdentityServer/identityServer.module';
import { IdentityResourcesEditComponent } from './identity-resources-edit/identity-resources-edit.component';
import { IdentityResourcesCreateComponent } from './identity-resources-create/identity-resources-create.component';
import { IdentityClientsListComponent } from './identity-server-clients/identity-server-clients.component';
import { IdentityClientsCreateComponent } from './identity-server-clients-create/identity-server-clients-create.component';
import { IdentityClientsEditComponent } from './identity-server-clients-edit/identity-server-clients-edit.component';
import { ApiResourcesPageComponent } from './api-resources/api-resources.component';
import { ApiResourceCreatePageComponent } from './api-resource-create/api-resource-create.component';
import { ApiResourceEditPageComponent } from './api-resource-edit/api-resource-edit.component';

import { ClientsSelectPageComponent } from './clients-select/clients-select.component';
import { ProductsSelectPageComponent } from './products-select/products-select.component';
import { AccessManagerModule } from '@modules/AccessManager/accessManager.module';
import { LoadingComponent } from './loading/loading.component';


@NgModule({
  declarations: [
    LoginCallbackPageComponent,
    LogoutCallbackPageComponent,
    DashboardPageComponent,
    UsersPageComponent,
    CustomersPageComponent,
    CustomerEditPageComponent,
    CustomerCreatePageComponent,
    ProductsPageComponent,
    ProductCreatePageComponent,
    ProductEditPageComponent,
    IdentityResourcesListComponent,
    IdentityResourcesEditComponent,
    IdentityResourcesCreateComponent,
    IdentityClientsListComponent,
    IdentityClientsCreateComponent,
    IdentityClientsEditComponent,
    ApiResourcesPageComponent,
    ApiResourceCreatePageComponent,
    ApiResourceEditPageComponent,
    ClientsSelectPageComponent,
    ProductsSelectPageComponent,
    LoadingComponent,
  ],
  imports: [
    CoreModule,
    UsersModule,
    CustomertModule,
    ProductModule,
    RolesModule,
    FormsModule,
    ComponentsModule,
    IdentityServerModule,
    AccessManagerModule,
  ],
})
export class ApplicationPagesModule { }
