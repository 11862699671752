import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SessionService } from '@core/services/Session';

@Component({
  templateUrl: 'login-callback.page.html',
  styleUrls: ['login-callback.page.scss'],
})
export class LoginCallbackPageComponent implements OnInit {

  constructor(private session: SessionService, private router: Router) { }

  async ngOnInit() {
    await this.session.loginCallback();
    this.router.navigate(['']);
  }
}
