<div class="form-group row mt-5" *ngIf="form">
  <div class=" col-sm-12 col-md-6">
    <div class="form-group row mr-pr-0">
      <label for="identityTokenLifetime" class="control col-md-12 col-lg-6 col-form-label text-field-label">IdentityTokenLifetime</label>
      <input id="identityTokenLifetime" class="form-control col-md-12 col-lg-6" [formControl]="form.controls['identityTokenLifetime']"
      [ngClass]="{ 'is-invalid': (form.controls.identityTokenLifetime.dirty || form.controls.identityTokenLifetime.touched) && form.controls.identityTokenLifetime.errors || form.controls.identityTokenLifetime.untouched && showMsg && form.controls.identityTokenLifetime.invalid }">
      <div class="col-md-12 col-lg-6"></div>
      <div class="col-md-12 col-lg-6 pl-lg-0">
        <div *ngIf="(form.controls.identityTokenLifetime.dirty || form.controls.identityTokenLifetime.touched) && form.controls.identityTokenLifetime.errors || form.controls.identityTokenLifetime.untouched && showMsg && form.controls.identityTokenLifetime.invalid" class=" validation-msg ml-0">
          <div *ngIf="form.controls.identityTokenLifetime.errors?.maxlength && !form.controls.identityTokenLifetime.errors.pattern">Should not be more than 10 digits.</div>
          <div *ngIf="form.controls.identityTokenLifetime.errors?.pattern">Number only(must not include spaces)</div>
        </div>
      </div>
    </div>
    <div class="form-group row mr-pr-0">
      <label for="accessTokenLifetime" class="control col-md-12 col-lg-6 col-form-label text-field-label">AccessTokenLifetime</label>
      <input id="accessTokenLifetime" class="form-control col-md-12 col-lg-6" [formControl]="form.controls['accessTokenLifetime']"
      [ngClass]="{ 'is-invalid': (form.controls.accessTokenLifetime.dirty || form.controls.accessTokenLifetime.touched) && form.controls.accessTokenLifetime.errors || form.controls.accessTokenLifetime.untouched && showMsg && form.controls.accessTokenLifetime.invalid }">
      <div class="col-md-12 col-lg-6"></div>
      <div class="col-md-12 col-lg-6 pl-lg-0">
        <div *ngIf="(form.controls.accessTokenLifetime.dirty || form.controls.accessTokenLifetime.touched) && form.controls.accessTokenLifetime.errors || form.controls.accessTokenLifetime.untouched && showMsg && form.controls.accessTokenLifetime.invalid" class=" validation-msg ml-0">
          <div *ngIf="form.controls.accessTokenLifetime.errors?.maxlength && !form.controls.accessTokenLifetime.errors.pattern">Should not be more than 10 digits.</div>
          <div *ngIf="form.controls.accessTokenLifetime.errors?.pattern">Number only(must not include spaces)</div>
        </div>
      </div>
    </div>
    <div class="form-group row mr-pr-0">
      <label for="authorizationCodeLifetime" class="control col-md-12 col-lg-6 col-form-label text-field-label">AuthorizationCodeLifetime</label>
      <input id="authorizationCodeLifetime" class="form-control col-md-12 col-lg-6" [formControl]="form.controls['authorizationCodeLifetime']"
      [ngClass]="{ 'is-invalid': (form.controls.authorizationCodeLifetime.dirty || form.controls.authorizationCodeLifetime.touched) && form.controls.authorizationCodeLifetime.errors || form.controls.authorizationCodeLifetime.untouched && showMsg && form.controls.authorizationCodeLifetime.invalid }">
      <div class="col-md-12 col-lg-6"></div>
      <div class="col-md-12 col-lg-6 pl-lg-0">
        <div *ngIf="(form.controls.authorizationCodeLifetime.dirty || form.controls.authorizationCodeLifetime.touched) && form.controls.authorizationCodeLifetime.errors || form.controls.authorizationCodeLifetime.untouched && showMsg && form.controls.authorizationCodeLifetime.invalid" class=" validation-msg ml-0">
          <div *ngIf="form.controls.authorizationCodeLifetime.errors?.maxlength && !form.controls.authorizationCodeLifetime.errors.pattern">Should not be more than 10 digits.</div>
          <div *ngIf="form.controls.authorizationCodeLifetime.errors?.pattern">Number only(must not include spaces)</div>
        </div>
      </div>
    </div>
    <div class="form-group row mr-pr-0">
      <label for="absoluteRefreshTokenLifetime" class="control col-md-12 col-lg-6 col-form-label text-field-label">AbsoluteRefreshTokenLifetime</label>
      <input id="absoluteRefreshTokenLifetime" class="form-control col-md-12 col-lg-6" [formControl]="form.controls['absoluteRefreshTokenLifetime']"
      [ngClass]="{ 'is-invalid': (form.controls.absoluteRefreshTokenLifetime.dirty || form.controls.absoluteRefreshTokenLifetime.touched) && form.controls.absoluteRefreshTokenLifetime.errors || form.controls.absoluteRefreshTokenLifetime.untouched && showMsg && form.controls.absoluteRefreshTokenLifetime.invalid }">
      <div class="col-md-12 col-lg-6"></div>
      <div class="col-md-12 col-lg-6 pl-lg-0">
        <div *ngIf="(form.controls.absoluteRefreshTokenLifetime.dirty || form.controls.absoluteRefreshTokenLifetime.touched) && form.controls.absoluteRefreshTokenLifetime.errors || form.controls.absoluteRefreshTokenLifetime.untouched && showMsg && form.controls.absoluteRefreshTokenLifetime.invalid" class=" validation-msg ml-0">
          <div *ngIf="form.controls.absoluteRefreshTokenLifetime.errors?.maxlength && !form.controls.absoluteRefreshTokenLifetime.errors.pattern">Should not be more than 10 digits.</div>
          <div *ngIf="form.controls.absoluteRefreshTokenLifetime.errors?.pattern">Number only(must not include spaces)</div>
        </div>
      </div>
    </div>
    <div class="form-group row mr-pr-0">
      <label for="slidingRefreshTokenLifetime" class="control col-md-12 col-lg-6 col-form-label text-field-label">SlidingRefreshTokenLifetime</label>
      <input id="slidingRefreshTokenLifetime" class="form-control col-md-12 col-lg-6" [formControl]="form.controls['slidingRefreshTokenLifetime']"
      [ngClass]="{ 'is-invalid': (form.controls.slidingRefreshTokenLifetime.dirty || form.controls.slidingRefreshTokenLifetime.touched) && form.controls.slidingRefreshTokenLifetime.errors || form.controls.slidingRefreshTokenLifetime.untouched && showMsg && form.controls.slidingRefreshTokenLifetime.invalid }">
      <div class="col-md-12 col-lg-6"></div>
      <div class="col-md-12 col-lg-6 pl-lg-0">
        <div *ngIf="(form.controls.slidingRefreshTokenLifetime.dirty || form.controls.slidingRefreshTokenLifetime.touched) && form.controls.slidingRefreshTokenLifetime.errors || form.controls.slidingRefreshTokenLifetime.untouched && showMsg && form.controls.slidingRefreshTokenLifetime.invalid" class="validation-msg ml-0">
          <div *ngIf="form.controls.slidingRefreshTokenLifetime.errors?.maxlength && !form.controls.slidingRefreshTokenLifetime.errors.pattern">Should not be more than 10 digits.</div>
          <div *ngIf="form.controls.slidingRefreshTokenLifetime.errors?.pattern">Number only(must not include spaces)</div>
        </div>
      </div>
    </div>
    <div class="form-group row mr-pr-0">
      <label for="consentLifetime" class="control col-md-12 col-lg-6 col-form-label text-field-label">ConsentLifetime</label>
      <input id="consentLifetime" class="form-control col-md-12 col-lg-6" [formControl]="form.controls['consentLifetime']"
      [ngClass]="{ 'is-invalid': (form.controls.consentLifetime.dirty || form.controls.consentLifetime.touched) && form.controls.consentLifetime.errors || form.controls.consentLifetime.untouched && showMsg && form.controls.consentLifetime.invalid }">
      <div class="col-md-12 col-lg-6"></div>
      <div class="col-md-12 col-lg-6 pl-lg-0">
        <div *ngIf="(form.controls.consentLifetime.dirty || form.controls.consentLifetime.touched) && form.controls.consentLifetime.errors || form.controls.consentLifetime.untouched && showMsg && form.controls.consentLifetime.invalid" class="validation-msg ml-0">
          <div *ngIf="form.controls.consentLifetime.errors?.maxlength && !form.controls.consentLifetime.errors.pattern">Should not be more than 10 digits.</div>
          <div *ngIf="form.controls.consentLifetime.errors?.pattern">Number only(must not include spaces)</div>
        </div>
      </div>
    </div>
    <div class="form-group row mr-pr-0">
      <label class="control col-md-12 col-lg-6 col-form-label text-field-label">RefereshTokeUsage</label>
      <div ngbDropdown class="col-md-12 col-lg-6">
        <button type="button" class="btn1 form-control col-md-12 col-lg-12" ngbDropdownToggle *ngIf="!form.get('refreshTokenUsage').value"><label class="dropdown-label">ReUse</label></button>
        <button type="button" class="btn1 form-control col-md-12 col-lg-12" ngbDropdownToggle *ngIf="form.get('refreshTokenUsage').value"><label class="dropdown-label">OneTimeOnly</label></button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button type="button" class="dropdown-item" (click)="setDropDownValue(0, 'refreshTokenUsage')">ReUse</button>
          <button type="button" class="dropdown-item" (click)="setDropDownValue(1, 'refreshTokenUsage')">OneTimeOnly</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 col-md-6">
    <div class="form-group row mr-pr-0">
      <label for="refreshTokenExpiration" class="control col-md-12 col-lg-6 col-form-label text-field-label">RefreshTokenExpiration</label>
      <div ngbDropdown class="col-md-12 col-lg-6">
        <button type="button" class="btn1 form-control col-md-12 col-lg-12" ngbDropdownToggle *ngIf="!form.get('refreshTokenExpiration').value"><label class="dropdown-label">Sliding</label></button>
        <button type="button" class="btn1 form-control col-md-12 col-lg-12" ngbDropdownToggle *ngIf="form.get('refreshTokenExpiration').value"><label class="dropdown-label">Absolute</label></button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button type="button" class="dropdown-item" (click)="setDropDownValue(0, 'refreshTokenExpiration')">Sliding</button>
          <button type="button" class="dropdown-item" (click)="setDropDownValue(1, 'refreshTokenExpiration')">Absolute</button>
        </div>
      </div>
    </div>
    <div class="form-group row mr-pr-0">
      <label for="accessTokenType" class="control col-md-12 col-lg-6 col-form-label text-field-label">AccessTokenType</label>
      <div ngbDropdown class="col-md-12 col-lg-6">
        <button type="button" class="btn1 form-control col-md-12 col-lg-12" ngbDropdownToggle *ngIf="!form.get('accessTokenType').value"><label class="dropdown-label">Jwt</label></button>
        <button type="button" class="btn1 form-control col-md-12 col-lg-12" ngbDropdownToggle *ngIf="form.get('accessTokenType').value"><label class="dropdown-label">References</label></button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button type="button" class="dropdown-item" (click)="setDropDownValue(0, 'accessTokenType')">Jwt</button>
          <button type="button" class="dropdown-item" (click)="setDropDownValue(1, 'accessTokenType')">References</button>
        </div>
      </div>
    </div>
    <div class="form-group row mr-pr-0">
      <label for="clientClaimsPrefix" class="control col-md-12 col-lg-6 col-form-label text-field-label">ClientClaimsPrefix</label>
      <input id="clientClaimsPrefix" class="form-control col-md-12 col-lg-6" [formControl]="form.controls['clientClaimsPrefix']"
      [ngClass]="{ 'is-invalid': (form.controls.clientClaimsPrefix.dirty || form.controls.clientClaimsPrefix.touched) && form.controls.clientClaimsPrefix.errors || form.controls.clientClaimsPrefix.untouched && showMsg && form.controls.clientClaimsPrefix.invalid }">
      <div class="col-md-12 col-lg-6"></div>
      <div class="col-md-12 col-lg-6 pl-lg-0">
        <div *ngIf="(form.controls.clientClaimsPrefix.dirty || form.controls.clientClaimsPrefix.touched) && form.controls.clientClaimsPrefix.errors || form.controls.clientClaimsPrefix.untouched && showMsg && form.controls.clientClaimsPrefix.invalid" class="validation-msg  ml-0">
          <div *ngIf="form.controls.clientClaimsPrefix.errors?.maxlength">Should not be more than 75 characters</div>
        </div>
      </div>
    </div>
    <div class="form-group row mr-pr-0">
      <label for="pairWiseSubjectSalt" class="control col-md-12 col-lg-6 col-form-label text-field-label">PairWiseSubjectSalt</label>
      <input id="pairWiseSubjectSalt" class="form-control col-md-12 col-lg-6" [formControl]="form.controls['pairWiseSubjectSalt']"
      [ngClass]="{ 'is-invalid': (form.controls.pairWiseSubjectSalt.dirty || form.controls.pairWiseSubjectSalt.touched) && form.controls.pairWiseSubjectSalt.errors || form.controls.pairWiseSubjectSalt.untouched && showMsg && form.controls.pairWiseSubjectSalt.invalid }">
      <div class="col-md-12 col-lg-6"></div>
      <div class="col-md-12 col-lg-6 pl-lg-0">
        <div *ngIf="(form.controls.pairWiseSubjectSalt.dirty || form.controls.pairWiseSubjectSalt.touched) && form.controls.pairWiseSubjectSalt.errors || form.controls.pairWiseSubjectSalt.untouched && showMsg && form.controls.pairWiseSubjectSalt.invalid" class="validation-msg ml-0">
          <div *ngIf="form.controls.pairWiseSubjectSalt.errors?.maxlength">Should not be more than 75 characters</div>
        </div>
      </div>
    </div>
  </div>
</div>