/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./access-manager-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./access-manager-layout.component";
import * as i5 from "../../../core/services/Session";
import * as i6 from "../../../core/services/DataService";
var styles_AccessManagerLayoutComponent = [i0.styles];
var RenderType_AccessManagerLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccessManagerLayoutComponent, data: {} });
export { RenderType_AccessManagerLayoutComponent as RenderType_AccessManagerLayoutComponent };
function View_AccessManagerLayoutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "nav-item"], ["routerLinkActive", "active"]], null, null, null, null, null)), i1.ɵdid(1, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpod(4, { exact: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "nav-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, true); var currVal_1 = "active"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.href; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 6).target; var currVal_3 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = _v.context.$implicit.title; _ck(_v, 7, 0, currVal_5); }); }
function View_AccessManagerLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "app"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "header", [["class", "navbar navbar-dark navbar-expand-lg bg-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", "navbar-brand"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Simplifai Admin"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "nav", [["class", "collapse navbar-collapse"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccessManagerLayoutComponent_2)), i1.ɵdid(9, 802816, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "container main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(12, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/"; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.links; _ck(_v, 9, 0, currVal_3); _ck(_v, 12, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_AccessManagerLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccessManagerLayoutComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ready; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AccessManagerLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sf-access-manager-layout", [], null, null, null, View_AccessManagerLayoutComponent_0, RenderType_AccessManagerLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i4.AccessManagerLayoutComponent, [i5.SessionService, i6.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccessManagerLayoutComponentNgFactory = i1.ɵccf("sf-access-manager-layout", i4.AccessManagerLayoutComponent, View_AccessManagerLayoutComponent_Host_0, {}, {}, []);
export { AccessManagerLayoutComponentNgFactory as AccessManagerLayoutComponentNgFactory };
