var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { CustomerService } from '@modules/Customer/services/CustomerService';
import { DomSanitizer } from '@angular/platform-browser';
var CustomerEditFormComponent = /** @class */ (function () {
    function CustomerEditFormComponent(clientService, sanitizer) {
        this.clientService = clientService;
        this.sanitizer = sanitizer;
        this.loadingToggle = new EventEmitter();
        this.fileToUpload = null;
        this.imageUrl = 'assets/upload.png';
    }
    Object.defineProperty(CustomerEditFormComponent.prototype, "controls", {
        get: function () {
            return this.formCustomer.controls;
        },
        enumerable: true,
        configurable: true
    });
    CustomerEditFormComponent.prototype.focusIn = function () {
        this.showMsg = false;
    };
    CustomerEditFormComponent.prototype.handleFileInput = function (file) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var reader;
            return __generator(this, function (_a) {
                if (!this.disableLogoChange) {
                    this.fileToUpload = file.item(0);
                    reader = new FileReader();
                    this.imagePath = null;
                    reader.onload = function (event) {
                        _this.imageUrl = _this.sanitizer.bypassSecurityTrustResourceUrl(event.target.result);
                    };
                    reader.readAsDataURL(this.fileToUpload);
                    this.image.nativeElement.value = null;
                }
                return [2 /*return*/];
            });
        });
    };
    CustomerEditFormComponent.prototype.upload = function () {
        return __awaiter(this, void 0, void 0, function () {
            var formData, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        if (!this.fileToUpload) return [3 /*break*/, 2];
                        this.loadingToggle.emit();
                        formData = new FormData();
                        formData.append('file', this.fileToUpload);
                        return [4 /*yield*/, this.clientService.uploadLogo(formData, this.customer)];
                    case 1:
                        _a.sent();
                        this.loadingToggle.emit();
                        this.fileToUpload = null;
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return CustomerEditFormComponent;
}());
export { CustomerEditFormComponent };
