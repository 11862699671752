import { EventEmitter, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
var DebounceDirective = /** @class */ (function () {
    function DebounceDirective() {
        this.change = new Subject();
        this.debouncedChange = new EventEmitter();
    }
    DebounceDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.change.pipe(debounceTime(this.debounceTime))
            .pipe(distinctUntilChanged()).subscribe(function (event) {
            _this.debouncedChange.emit(event);
        });
    };
    DebounceDirective.prototype.changeEvent = function (event) {
        this.change.next(event.target.value);
    };
    return DebounceDirective;
}());
export { DebounceDirective };
