import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@core/services/FormHelper';
import { IIdentityResourceResponse, IUserClaims } from '@modules/IdentityServer/identityServer.model';
import { map } from 'lodash';

@Injectable()
export class IdentityResourceFormBuilder extends FormHelper<IIdentityResourceResponse> {

  createEmpty(): FormGroup {
    return this.fromModel({
      name: '',
      displayName: '',
      description: '',
      enabled: true,
      emphasize: false,
      required: false,
      showInDiscoveryDocument: false,
      userClaims: [],
      deleteUserClaimsIds: [],
    });
  }

  fromModel(model: Partial<IIdentityResourceResponse>): FormGroup {
    return this.builder.group({
      id: [{ value: model.id, disabled: true }],
      name: [model.name, [
        Validators.required,
        Validators.pattern('[0-9A-Za-z_-]+'),
        Validators.maxLength(75)],
      ],
      displayName: [model.displayName, [
        Validators.required,
        Validators.maxLength(75)],
      ],
      description: [model.description, [Validators.maxLength(1000)]],
      enabled: [model.enabled],
      emphasize: [model.emphasize],
      required: [model.required],
      showInDiscoveryDocument: [model.showInDiscoveryDocument],
      userClaims: this.builder.array(
        map(model.userClaims, (userClaim: IUserClaims) => this.createUserClaim(userClaim))),
      deleteUserClaimsIds: [model.deleteUserClaimsIds],
    });
  }

  createUserClaim(userClaim: any): FormGroup {
    return this.builder.group({
      id: userClaim.id || 0,
      type: [userClaim.type || '',  [Validators.required, Validators.minLength(0), Validators.maxLength(75)]],
      deleteOperation: 1,
    });
  }

}
