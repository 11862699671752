import { OnInit } from '@angular/core';
import { RoleService } from '@modules/Roles/services/role.service';
var RolesListComponent = /** @class */ (function () {
    function RolesListComponent(roleService) {
        this.roleService = roleService;
    }
    RolesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.roleService.rolesStatus.subscribe(function (data) { return _this.rolesResult = data; });
    };
    return RolesListComponent;
}());
export { RolesListComponent };
