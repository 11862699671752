<div class="app" *ngIf="ready">
  <header class="navbar navbar-dark navbar-expand-lg bg-primary">
    <div class="container">
      <a routerLink="/" class="navbar-brand">Simplifai Admin</a>
      <nav class="collapse navbar-collapse">
        <ul class="navbar-nav">
          <li *ngFor="let link of links" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <a class="nav-link" [routerLink]="link.href">{{link.title}}</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <div class="container main">
    <router-outlet></router-outlet>
  </div>
</div>
