import { StatusService } from '@core/services/StatusService';
var ExcepionHandlingService = /** @class */ (function () {
    function ExcepionHandlingService(status) {
        this.status = status;
    }
    ExcepionHandlingService.prototype.errorDisplay = function (error) {
        if (error.status === 409) {
            return error;
        }
        else if (error.status === 422) {
            error.error.Message = JSON.parse(error.error.Message);
            return error;
        }
        else if (error.status === 400 || error.status === 500) {
            this.status.httpError(error, { autoclose: true });
            return null;
        }
    };
    return ExcepionHandlingService;
}());
export { ExcepionHandlingService };
