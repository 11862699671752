<div class="form-group row">
  <label for="username" class="control col-sm-3 col-md-2 col-form-label text-field-label">{{ 'FE_General_Lable_Username' | translate }}
    <span class="mandetory-input ml-1">*</span>
  </label>
  <input id="username" name="username" class="form-control col-sm-6" [formControl]="formUser.controls['username']" [attr.disabled]="showVerificationField ?  '' : null"
    [ngClass]="{ 'is-invalid': (controls.username.dirty || controls.username.touched) && controls.username.errors || controls.username.untouched && showMsg && controls.username.invalid}"
    required>
  <button class="btn btn-secondary col-sm-3 ml-2" *ngIf="showVerificationField" [disabled]="isEmailVerificationEnabled" (click)="verifyEmail()">{{ 'FE_General_Button_SendVerificationEmail' | translate }}</button>
  <div *ngIf="(controls.username.dirty || controls.username.touched) && controls.username.errors || controls.username.untouched && showMsg && controls.username.invalid"
    class="invalid-feedback validation-msg">
    <div *ngIf="controls.username.errors?.required">Username is required</div>
    <div *ngIf="controls.username.errors?.email">Username must be a valid email address</div>
    <div *ngIf="controls.username.errors?.maxlength && !controls.username.errors.email">Should not be more than 75 characters</div>
  </div>
</div>
<div class="form-group row">
  <label for="firstName" class="control col-sm-3 col-md-2 col-form-label text-field-label">{{ 'FE_General_Lable_FirstName' | translate }}
    <span class="mandetory-input ml-1">*</span>
  </label>
  <input id="firstName" name="firstName" class="form-control col-sm-6" [formControl]="formUser.controls['firstName']" [ngClass]="{ 'is-invalid': (controls.firstName.dirty || controls.firstName.touched) && controls.firstName.errors || controls.firstName.untouched && showMsg && controls.firstName.invalid }"
    required>
  <div *ngIf="(controls.firstName.dirty || controls.firstName.touched) && controls.firstName.errors || controls.firstName.untouched && showMsg && controls.firstName.invalid"
    class="invalid-feedback validation-msg">
    <div *ngIf="controls.firstName.errors?.required">First Name is required</div>
    <div *ngIf="controls.firstName.errors?.pattern">'-' and '_' special characters allowed only</div>
    <div *ngIf="controls.firstName.errors?.maxlength && !controls.firstName.errors.pattern">Should not be more than 75 characters</div>
  </div>
</div>
<div class="form-group row">
  <label for="lastName" class="control col-sm-3 col-md-2 col-form-label text-field-label">{{ 'FE_General_Lable_LastName' | translate }}
    <span class="mandetory-input ml-1">*</span>
  </label>
  <input id="lastName" class="form-control col-sm-6" [formControl]="formUser.controls['lastName']" [ngClass]="{ 'is-invalid': (controls.lastName.dirty || controls.lastName.touched) && controls.lastName.errors || controls.lastName.untouched && showMsg && controls.lastName.invalid }"
    required>
  <div *ngIf="(controls.lastName.dirty || controls.lastName.touched) && controls.lastName.errors || controls.lastName.untouched && showMsg && controls.lastName.invalid"
    class="invalid-feedback validation-msg">
    <div *ngIf="controls.lastName.errors?.required">Last Name is required</div>
    <div *ngIf="controls.lastName.errors?.pattern">'-' and '_' special characters allowed only</div>
    <div *ngIf="controls.lastName.errors?.maxlength  && !controls.lastName.errors.pattern">Should not be more than 75 characters</div>
  </div>
</div>
<div class="form-group row">
  <label for="phoneNumber" class="control col-sm-3 col-md-2 col-form-label text-field-label">{{ 'FE_General_Lable_Phone' | translate }}
  </label>
  <input id="phoneNumber" class="form-control col-sm-6 mr-md-2" [formControl]="formUser.controls['phoneNumber']" [ngClass]="{ 'is-invalid': (controls.phoneNumber.dirty || controls.phoneNumber.touched) && controls.phoneNumber.errors || controls.phoneNumber.untouched && showMsg && controls.phoneNumber.invalid }">
  <button class="btn btn-secondary col-sm-3" *ngIf="showVerificationField" disabled>{{ 'FE_General_Button_SendVerificationSMS' | translate }}</button>
  <div *ngIf="(controls.phoneNumber.dirty || controls.phoneNumber.touched) && controls.phoneNumber.errors || controls.phoneNumber.untouched && showMsg && controls.phoneNumber.invalid"
    class="invalid-feedback validation-msg">
    <div *ngIf="controls.phoneNumber.errors?.pattern">Number only</div>
    <div *ngIf="controls.phoneNumber.errors?.maxlength && !controls.phoneNumber.errors.pattern">Should not be more than 10 digits.</div>
  </div>
</div>

<div class="form-group row">
  <label for="loginType" class="control col-sm-3 col-md-2 col-form-label text-field-label">{{ 'FE_User_Label_LoginType' | translate }}</label>
  <select id="loginType" [formControl]="formUser.controls['loginType']" class="dropdown-control">
    <option *ngFor="let loginType of loginTypes" value="{{loginType.value}}">{{loginType.key}}
  </select>
</div>
 
<div class="wrapper" *ngIf="showVerificationField">
  <ngb-tabset>
    <ngb-tab title="{{ 'FE_General_Lable_Actions' | translate }}">
      <ng-template ngbTabContent>
        <sf-app-user-edit-form-actions [formUser]="formUser" [user]="user"></sf-app-user-edit-form-actions>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="{{ 'FE_General_Lable_ProductsandRoles' | translate }}">
      <ng-template ngbTabContent>
        <div class="col-sm-6 mt-3">
          <input type="text" class="form-control" placeholder="{{ 'FE_General_Search_PlaceHolder' | translate }}" disabled>
        </div>
        <sf-app-user-edit-roles-products [formUser]="formUser"></sf-app-user-edit-roles-products>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>