var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Validators } from '@angular/forms';
import { FormHelper } from '@core/services/FormHelper';
import { map } from 'lodash';
var IdentityResourceFormBuilder = /** @class */ (function (_super) {
    __extends(IdentityResourceFormBuilder, _super);
    function IdentityResourceFormBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IdentityResourceFormBuilder.prototype.createEmpty = function () {
        return this.fromModel({
            name: '',
            displayName: '',
            description: '',
            enabled: true,
            emphasize: false,
            required: false,
            showInDiscoveryDocument: false,
            userClaims: [],
            deleteUserClaimsIds: [],
        });
    };
    IdentityResourceFormBuilder.prototype.fromModel = function (model) {
        var _this = this;
        return this.builder.group({
            id: [{ value: model.id, disabled: true }],
            name: [model.name, [
                    Validators.required,
                    Validators.pattern('[0-9A-Za-z_-]+'),
                    Validators.maxLength(75)
                ],
            ],
            displayName: [model.displayName, [
                    Validators.required,
                    Validators.maxLength(75)
                ],
            ],
            description: [model.description, [Validators.maxLength(1000)]],
            enabled: [model.enabled],
            emphasize: [model.emphasize],
            required: [model.required],
            showInDiscoveryDocument: [model.showInDiscoveryDocument],
            userClaims: this.builder.array(map(model.userClaims, function (userClaim) { return _this.createUserClaim(userClaim); })),
            deleteUserClaimsIds: [model.deleteUserClaimsIds],
        });
    };
    IdentityResourceFormBuilder.prototype.createUserClaim = function (userClaim) {
        return this.builder.group({
            id: userClaim.id || 0,
            type: [userClaim.type || '', [Validators.required, Validators.minLength(0), Validators.maxLength(75)]],
            deleteOperation: 1,
        });
    };
    return IdentityResourceFormBuilder;
}(FormHelper));
export { IdentityResourceFormBuilder };
