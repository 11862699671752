
import { TranslateLoader } from '@ngx-translate/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './DataService';
import { Endpoints } from '../../config/endpoints';
import { from } from 'rxjs';
/**
 * TranslationLoader override the existing class to implement custom functionality.
 */
export class TranslationLoader implements TranslateLoader {
    constructor(private dataService: DataService) { }

    getTranslation(language: string): Observable<any> {
        return from(this.dataService.request<string>(Endpoints.Localization, { headers: this.getHttpHeaders(language) }));
    }

    private getHttpHeaders(language: string): HttpHeaders {
        return new HttpHeaders().set('Accept-Language', language);
    }

}
