import { Component, ViewChild, Input, ElementRef, EventEmitter, Output } from '@angular/core';
import { RoleService } from '@modules/Roles/services/role.service';

@Component({
  selector: 'sf-roles-file-upload',
  templateUrl: './roles-file-upload.component.html',
  styleUrls: ['./roles-file-upload.component.scss'],
})
export class RolesFileUploadComponent {

  @Input() productId: string;
  @ViewChild('form') form: ElementRef<HTMLFormElement>;
  public file: File;
  @Output() loadingToggle = new EventEmitter<void>();
  constructor(private roleService: RoleService) { }

  onFileChange(e: Event) {
    const target = e.target as HTMLInputElement;
    this.file = target.files[0];
  }

  async upload() {
    try {
      if (this.file) {
        this.loadingToggle.emit();
        const formData = new FormData();
        formData.append('file', this.file);
        await this.roleService.update(formData, this.productId);
        await this.roleService.getRoleById(this.productId);
        this.form.nativeElement.reset();
        this.file = null;
        this.loadingToggle.emit();
      }
    } catch (ex) {
      alert('An error occured, please try after some time');
    }
    finally {
      // this.loadingToggle.emit();
    }
  }
}
