var Generator = /** @class */ (function () {
    function Generator() {
    }
    Generator.randomKey = function () {
        return Math.random()
            .toString(36)
            .substr(2, 5);
    };
    return Generator;
}());
export { Generator };
