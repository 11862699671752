import { Component, OnInit, Input } from '@angular/core';
import { IClientSelect } from '@modules/AccessManager/accessManager.model';

@Component({
  selector: 'sf-client-select',
  templateUrl: './client-select.component.html',
  styleUrls: ['./client-select.component.scss'],
})
export class ClientSelectComponent implements OnInit {
  @Input() clientData: IClientSelect[];
  constructor() { }

  ngOnInit() {
  }
}
