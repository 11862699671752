var User = /** @class */ (function () {
    function User(data) {
        this.id = data.id;
        this.username = data.username;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.lastLogin = data.lastLogin;
        this.isActive = data.isActive;
        this.email = data.email;
        this.phone = data.phone;
        this.phoneNumber = data.phoneNumber;
        this.lockoutEnabled = data.lockoutEnabled;
        this.changePasswordOnNextLogin = data.changePasswordOnNextLogin;
        this.lockoutEnd = data.lockoutEnd;
        this.emailConfirmed = data.emailConfirmed;
        this.roles = data.roles;
        this.userProduct = data.userProduct ? data.userProduct.slice() : [];
        this.links = data.links ? data.links.slice() : [];
        this.loginType = data.loginType;
        this.enableMFA = data.enableMFA;
    }
    Object.defineProperty(User.prototype, "name", {
        get: function () {
            return this.firstName + " " + this.lastName + " (@" + this.username + ")";
        },
        enumerable: true,
        configurable: true
    });
    return User;
}());
export { User };
export var LoginType;
(function (LoginType) {
    LoginType[LoginType["Regular"] = 0] = "Regular";
    LoginType[LoginType["Microsoft"] = 1] = "Microsoft";
    LoginType[LoginType["Google"] = 2] = "Google";
})(LoginType || (LoginType = {}));
