<div class="app" *ngIf="ready">
  <header class="navbar navbar-dark navbar-expand-lg bg-primary">
    <div class="container-fluid ml-6">
      <div class="col-lg-9 col-md-5 col-sm-6 col-xs-12 row d-flex ">
        <input class="custommenu" type="checkbox" id="menubar">
        <label class="customemenutoggle d-lg-none" for="menubar" ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" focusable="false"><title>Menu</title><path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"></path></svg></label>
        <a routerLink="/" class="navbar-brand"><img class="app_logo" src="../../../assets/simplifai_logo.png" /></a>
        <nav class="collapse navbar-collapse" id="bd-docs-nav">
          <ul class="navbar-nav">
            <li *ngFor="let link of links" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
              <a class="nav-link" [routerLink]="link.href">{{ link.title | translate }}</a>
             </li>
          </ul>
        </nav>
      </div>
      <hr class="header-separator">
      <div class="col-lg-3 col-md-7 col-sm-6 col-xs-12 row d-flex justify-content-start justify-content-sm-end">
        <div ngbDropdown class="d-inline-block" [placement]="['bottom-center', 'bottom-center']">
          <button class="btn btn-outline-default langselect mr-md-3" id="dropdownBasic1" ngbDropdownToggle>{{languageName}}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-menu dropdown-menu-right">
            <button (click)="changeLanguage('en')" class="dropdown-item">
              <img class="flag-icon" src="assets/images/us.svg"> English</button>
            <button (click)="changeLanguage('no')" class="dropdown-item">
              <img class="flag-icon" src="assets/images/no.svg"> Norwegian</button>
          </div>
        </div>
        <div ngbDropdown class="user-profile text-nowrap" [placement]="['bottom-right', 'bottom-right']">
          <span class="mr-2 user-icon"><i class="fa fa-user-circle-o"></i></span>
          <a class="navbar-brand" [routerLink]="" id="dropdownBasic1" role="button" ngbDropdownToggle> {{loggedUser}} </a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button class="dropdown-item" (click)="goToProfile()">Profile</button>
            <button class="dropdown-item" (click)="logout()">Logout</button>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="container main">
    <router-outlet></router-outlet>
  </div>
  <footer class="footer">
    <div class="container">
      <div class="mx-sm-0 row">
        <div class="col-md-6">
          <span class="badge">API: <strong>{{ apiInfo.applicationName }}</strong></span>
          <span class="badge ml-1">Version: <strong>{{ apiInfo.applivationVersion }}</strong></span>
          <span class="badge ml-1">Environment: <strong>{{ apiInfo.aspEnvironment }}</strong></span>
          <span class="badge ml-1">Status: <strong>{{ apiInfo.status }}</strong></span>
          <hr class="d-block d-sm-none">
        </div>
        <div class="col-md-6">
          <span class="badge">Client application: <strong>Simplifai Admin</strong></span>
          <span class="badge ml-1">Version: <strong>{{ clientInfo.version }}</strong></span>
          <span class="badge ml-1">Environment: <strong>{{ clientInfo.environment }}</strong></span>
        </div>
      </div>
    </div>
  </footer>
</div>
<sf-toast-container></sf-toast-container>