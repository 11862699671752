import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { RolesListComponent } from './components/roles-list/roles-list.component';
import { RoleService } from '@modules/Roles/services/role.service';
import { RolesFileUploadComponent } from './components/roles-file-upload/roles-file-upload.component';

@NgModule({
  imports: [CoreModule],
  providers: [RoleService],
  declarations: [RolesListComponent, RolesFileUploadComponent],
  exports: [RolesListComponent, RolesFileUploadComponent],
})
export class RolesModule {}
