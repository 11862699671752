
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslationService {
    constructor(private translate: TranslateService) { }

    setLanguagePreference() {
        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');

        // check for language present is session storage .
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        if (sessionStorage.getItem('language') != null) {
            this.translate.use(sessionStorage.getItem('language'));
        } else {
            this.translate.use('en');
        }
    }

    async onLanguageChange(langauge: string) {
        await this.translate.currentLoader.getTranslation(langauge);
        this.translate.use(langauge);
        sessionStorage.setItem('language', langauge);
    }

}
