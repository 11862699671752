/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logout-callback.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./logout-callback.page";
import * as i3 from "@angular/router";
var styles_LogoutCallbackPageComponent = [i0.styles];
var RenderType_LogoutCallbackPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutCallbackPageComponent, data: {} });
export { RenderType_LogoutCallbackPageComponent as RenderType_LogoutCallbackPageComponent };
export function View_LogoutCallbackPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "jumbotron container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "display-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["User succesfully logged out. To log in again please "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "lead mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "btn-custom-primary btn-lable-primary"], ["role", "button"]], [[1, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["click here"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 4, 0, currVal_0); }); }
export function View_LogoutCallbackPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_LogoutCallbackPageComponent_0, RenderType_LogoutCallbackPageComponent)), i1.ɵdid(1, 114688, null, 0, i2.LogoutCallbackPageComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutCallbackPageComponentNgFactory = i1.ɵccf("ng-component", i2.LogoutCallbackPageComponent, View_LogoutCallbackPageComponent_Host_0, {}, {}, []);
export { LogoutCallbackPageComponentNgFactory as LogoutCallbackPageComponentNgFactory };
