<div class="toast-container " #container>
  <div
    class="toast-wrapper"
    *ngFor="let toast of toasts; trackBy: trackOrder"
    [@fadeAnimation]="'in'"
  >
    <sf-toast-message
      class="toast"
      [toast]="toast"
      (close)="onClose(toast)"
    ></sf-toast-message>
  </div>
</div>
