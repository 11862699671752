/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-edit.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/forms";
import * as i4 from "../../../modules/Product/components/product-edit-form/product-edit-form.component.ngfactory";
import * as i5 from "../../../modules/Product/components/product-edit-form/product-edit-form.component";
import * as i6 from "../../../modules/Product/services/ProductService";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/router";
import * as i9 from "../../../modules/Roles/components/roles-list/roles-list.component.ngfactory";
import * as i10 from "../../../modules/Roles/components/roles-list/roles-list.component";
import * as i11 from "../../../modules/Roles/services/role.service";
import * as i12 from "../../../modules/Roles/components/roles-file-upload/roles-file-upload.component.ngfactory";
import * as i13 from "../../../modules/Roles/components/roles-file-upload/roles-file-upload.component";
import * as i14 from "@angular/common";
import * as i15 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i16 from "@ng-bootstrap/ng-bootstrap";
import * as i17 from "../loading/loading.component.ngfactory";
import * as i18 from "../loading/loading.component";
import * as i19 from "./product-edit.page";
import * as i20 from "../../../modules/Product/services/ProductFormBuilder";
import * as i21 from "../../../core/services/ExceptionHandlingService";
var styles_ProductEditPageComponent = [i0.styles];
var RenderType_ProductEditPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductEditPageComponent, data: {} });
export { RenderType_ProductEditPageComponent as RenderType_ProductEditPageComponent };
function View_ProductEditPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", " ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("FE_General_Lable_Edit")); var currVal_1 = _co.product.name; var currVal_2 = i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 3).transform("FE_General_Lable_Products")); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ProductEditPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "form", [["class", "mt-3 col-md-12"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i3.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(3, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(5, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "sf-product-edit-form", [], null, [[null, "loadingToggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadingToggle" === en)) {
        var pd_0 = (_co.loadingToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ProductEditFormComponent_0, i4.RenderType_ProductEditFormComponent)), i1.ɵdid(7, 114688, null, 0, i5.ProductEditFormComponent, [i6.ProductService, i7.DomSanitizer], { form: [0, "form"], productId: [1, "productId"], pageNumber: [2, "pageNumber"], disableLogoChange: [3, "disableLogoChange"], imagePath: [4, "imagePath"], error: [5, "error"] }, { loadingToggle: "loadingToggle" }), (_l()(), i1.ɵeld(8, 0, null, null, 12, "div", [["class", "actions btn-toolbar mt-3 justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "btn-wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["class", "btn-custom-primary"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "label", [["class", "btn-lable-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "btn-wrapper"], ["routerLink", "/products"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i8.RouterLink, [i8.Router, i8.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["class", "btn-custom-secondary"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "label", [["class", "btn-lable-secondary"]], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 3, 0, currVal_7); var currVal_8 = _co.form; var currVal_9 = _co.productId; var currVal_10 = _co.pageNumber; var currVal_11 = _co.disableLogoChange; var currVal_12 = _co.imagePath; var currVal_13 = _co.error; _ck(_v, 7, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_16 = "/products"; _ck(_v, 16, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_14 = _co.submitting; _ck(_v, 11, 0, currVal_14); var currVal_15 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("FE_General_Button_Save")); _ck(_v, 13, 0, currVal_15); var currVal_17 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("FE_General_Button_Cancel")); _ck(_v, 19, 0, currVal_17); }); }
function View_ProductEditPageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "sf-roles-list", [], null, null, null, i9.View_RolesListComponent_0, i9.RenderType_RolesListComponent)), i1.ɵdid(2, 114688, null, 0, i10.RolesListComponent, [i11.RoleService], { rolesResult: [0, "rolesResult"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "sf-roles-file-upload", [], null, [[null, "loadingToggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadingToggle" === en)) {
        var pd_0 = (_co.loadingToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_RolesFileUploadComponent_0, i12.RenderType_RolesFileUploadComponent)), i1.ɵdid(4, 49152, null, 0, i13.RolesFileUploadComponent, [i11.RoleService], { productId: [0, "productId"] }, { loadingToggle: "loadingToggle" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rolesResult; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.productId; _ck(_v, 4, 0, currVal_1); }, null); }
function View_ProductEditPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductEditPageComponent_5)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rolesResult; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProductEditPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "wrapper mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "ngb-tabset", [], null, [[null, "tabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tabChange" === en)) {
        var pd_0 = (_co.onTabChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_NgbTabset_0, i15.RenderType_NgbTabset)), i1.ɵdid(2, 2146304, [["tabs", 4]], 1, i16.NgbTabset, [i16.NgbTabsetConfig], { activeId: [0, "activeId"] }, { tabChange: "tabChange" }), i1.ɵqud(603979776, 1, { tabs: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 6, "ngb-tab", [["id", "general"]], null, null, null, null, null)), i1.ɵdid(5, 2113536, [[1, 4]], 2, i16.NgbTab, [], { id: [0, "id"], title: [1, "title"] }, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { contentTpls: 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 1, null, View_ProductEditPageComponent_3)), i1.ɵdid(10, 16384, [[3, 4]], 0, i16.NgbTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 6, "ngb-tab", [["id", "roles"]], null, null, null, null, null)), i1.ɵdid(12, 2113536, [[1, 4]], 2, i16.NgbTab, [], { id: [0, "id"], title: [1, "title"] }, null), i1.ɵqud(603979776, 4, { titleTpls: 1 }), i1.ɵqud(603979776, 5, { contentTpls: 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 1, null, View_ProductEditPageComponent_4)), i1.ɵdid(17, 16384, [[5, 4]], 0, i16.NgbTabContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedTab; _ck(_v, 2, 0, currVal_0); var currVal_1 = "general"; var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 5, 1, i1.ɵnov(_v, 8).transform("FE_General_Lable_General")), ""); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = "roles"; var currVal_4 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 12, 1, i1.ɵnov(_v, 15).transform("FE_General_Lable_Roles")), ""); _ck(_v, 12, 0, currVal_3, currVal_4); }, null); }
export function View_ProductEditPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "page"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductEditPageComponent_1)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "sf-app-loading", [], null, null, null, i17.View_LoadingComponent_0, i17.RenderType_LoadingComponent)), i1.ɵdid(4, 114688, null, 0, i18.LoadingComponent, [], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductEditPageComponent_2)), i1.ɵdid(6, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.product; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.product; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ProductEditPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sf-product-edit", [], null, null, null, View_ProductEditPageComponent_0, RenderType_ProductEditPageComponent)), i1.ɵdid(1, 114688, null, 0, i19.ProductEditPageComponent, [i6.ProductService, i20.ProductFormBuilder, i8.ActivatedRoute, i8.Router, i11.RoleService, i7.DomSanitizer, i21.ExcepionHandlingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductEditPageComponentNgFactory = i1.ɵccf("sf-product-edit", i19.ProductEditPageComponent, View_ProductEditPageComponent_Host_0, {}, {}, []);
export { ProductEditPageComponentNgFactory as ProductEditPageComponentNgFactory };
