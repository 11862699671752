import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { IIdentityResourceResponse } from '@modules/IdentityServer/identityServer.model';
import { IdentityResourceFormBuilder } from '@modules/IdentityServer/services/IdentityResourceFormBuilder';
import { IdentityResourceService } from '@modules/IdentityServer/services/IdentityResourceService';
import {
  EditFormIdentityResourcesComponent,
} from '@modules/IdentityServer/component/edit-form-identity-resources/edit-form-identity-resources.component';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  selector: 'sf-app-identityresources-edit',
  templateUrl: './identity-resources-edit.component.html',
  styleUrls: ['./identity-resources-edit.component.scss'],
})
export class IdentityResourcesEditComponent implements OnInit {
  public loading = false;
  public submitting = false;
  public error: any = null;
  public client: IIdentityResourceResponse;
  public form: FormGroup;
  @ViewChild(EditFormIdentityResourcesComponent) formComponent: EditFormIdentityResourcesComponent;

  constructor(
    private service: IdentityResourceService,
    private formBuilder: IdentityResourceFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private exception: ExcepionHandlingService,
  ) {}

  get clientId() {
    return this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.loadIdentityResourceClient();
  }

  async loadIdentityResourceClient() {
    try {
      this.error = null;
      this.loading = true;
      const client = await this.service.getClientInfo(parseInt(this.clientId, 10));
      const form = this.formBuilder.fromModel(client);
      this.client = client;
      this.form = form;
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
    }
  }

  async onSubmit() {
    this.formComponent.removeItem();
    if (this.form.invalid) {
      return;
    }
    const client = this.formBuilder.map(this.form, this.client);
    try {
      this.error = null;
      this.loading = true;
      this.submitting = true;
      await this.service.updateIdentityResourceClient(client);
      this.router.navigate(['identity-resources']);
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
      this.submitting = false;
    }
  }


}
