import { Component, OnInit } from '@angular/core';
import { SessionService } from '@core/services/Session';
import { DataService } from '@core/services/DataService';
import { config } from '@environments/environment';
import { StaticEndpoints, Endpoints } from '@config/endpoints';
import { IApiInfo, IClientInfo } from '@core/model/AppStatus';
import { IUserInfo, IUserProfile } from '@core/model/UserInfo';
import { TranslationService } from '@core/services/TranslateService';
import { IServerResponse } from '@core/core.interfaces';

@Component({
  templateUrl: 'application-layout.component.html',
  styleUrls: ['application-layout.component.scss'],
})
export class ApplicationLayoutComponent implements OnInit {

  public links = [
    { href: '/dashboard', title: 'FE_Headers_Label_Dashboard' },
    { href: '/customers', title: 'FE_Headers_Label_Customers' },
    { href: '/products', title: 'FE_Headers_Label_Products' },
    { href: '/identity-resources', title: 'FE_Headers_Label_IdentityResources' },
    { href: '/identity-clients', title: 'FE_Headers_Label_IdentityClients' },
    { href: '/identity/api-resources', title: 'FE_Headers_Label_ApiResources' },
  ];

  public ready = false;
  public error: any = null;
  public apiInfo: IApiInfo;
  public clientInfo: IClientInfo;
  public loggedUser: string;
  public profileLink: string;
  public languageName: string;
  constructor(private session: SessionService, private dataService: DataService, private translation: TranslationService) { }

  async ngOnInit() {
    try {
      this.session.startSession();
      await this.dataService.defineEndpoints();
      await this.getAppStatus();
      await this.getUser();
      this.ready = true;
      this.translation.setLanguagePreference();
      await this.getUserProfile();
      this.getLanguageName();
    } catch (err) {
      this.error = err;
    }
  }

  async getAppStatus() {
    this.clientInfo = {
      environment: config.environmentName,
      version: config.version,
    };
    this.apiInfo = await this.dataService.get<IApiInfo>(StaticEndpoints.ApiInfo);
  }

  async changeLanguage(langauge: string) {
    await this.translation.onLanguageChange(langauge);
    this.getLanguageName();
  }

  async getUser() {
    const user = await this.dataService.request<IServerResponse<IUserInfo>>(Endpoints.GetLoggedInUser);
    this.loggedUser = user.result.username;
  }

  async getUserProfile() {
    const link = await this.dataService.request<IServerResponse<IUserProfile>>(Endpoints.Profilelink);
    this.profileLink = link.result.profilePage;
  }

  goToProfile() {
    window.location.href = this.profileLink;
  }

  logout() {
    this.session.logout();
  }

  private getLanguageName() {
    if (sessionStorage.getItem('language') == null || sessionStorage.getItem('language') === 'en') {
      this.languageName = 'English';
    } else {
      this.languageName = 'Norwegian';
    }
  }

}
