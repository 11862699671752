<div class="container" [ngClass]="toast.type">
  <button class="close" (click)="onClose()"><i class="fa fa-times" aria-hidden="true"></i></button>
  <header class="title">
    {{ toast.title }}
  </header>
  <div *ngIf="toast.subtitle" class="subtitle">
    {{ toast.subtitle }}
  </div>
  <footer *ngIf="toast.footer" class="footer">
    {{ toast.footer }}
  </footer>
</div>
