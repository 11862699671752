<div *ngIf="users!=undefined && users.length<=0" class="text-center">
  <strong>{{ 'FE_General_Label_NoResult' | translate }}</strong>
</div>
<div class="table-responsive table-wraper" [ngClass]="( users==undefined || users.length<=0)?'visible-table':'hide-table'">
  <table class="table table-hover">
    <thead>
      <tr>
        <th>{{ 'FE_General_Lable_Username' | translate }}</th>
        <!-- <th>{{ 'FE_General_Lable_Email' | translate }}</th> -->
        <th>{{ 'FE_General_Lable_FirstName' | translate }}</th>
        <th>{{ 'FE_General_Lable_LastName' | translate }}</th>
        <th>{{ 'FE_General_Lable_IsActive' | translate }}</th>
        <th>{{ 'FE_General_Lable_LastLogin' | translate }}</th>
        <th>{{ 'FE_General_Lable_ProdutsAccess' | translate }}</th>
        <th class="text-center">{{ 'FE_General_Lable_Actions' | translate }}</th>
      </tr>
    </thead>
    <tr *ngFor="let user of users">
      <td>{{user.username}}</td>
      <!-- <td>{{user.email}}</td> -->
      <td>{{user.firstName}}</td>
      <td>{{user.lastName}}</td>
      <!-- <td>{{user.isActive}}</td> -->
      <td>
        <div class="checkbox-aligncenter">
          <input class="styled-checkbox" id="isActive" type="checkbox" [checked]="user.isActive" disabled>
          <label for="isActive"></label>
        </div>
      </td>
      <td>{{user.lastLogin | date: 'dd/MM/y h:mm:ss a'}}</td>
      <td>
        <span *ngFor="let product of filter(user.userProduct); let i=index;">
          <span *ngIf="!i; else showNameByComma">{{product.name}}</span>
          <ng-template #showNameByComma> | {{product.name}}</ng-template>
        </span>
      </td>
      <td class="text-center">
        <div class="btn-group">
          <button class="btn custom-btn" (click)="editModel(content, user)">
            <img src="../../../../assets/images/edit.svg" class="Combined-Shape">
          </button>
          <span class="line-sperator"></span>
          <button (click)="confirmModal(usercontent,user)" class="btn custom-btn">
            <img class="Combined-Shape delete-opacity" src="../../../../assets/images/delete.svg">
          </button>
        </div>
      </td>
      <ng-template #content let-close="close" let-dismiss="dismiss">
        <div class="modal-header">
          <span class="modal-title">{{ 'FE_General_Lable_Edit' | translate }} {{user.username}} {{ 'FE_General_Lable_User' | translate }}</span>
          <button type="button" class="close" aria-label="Close" (click)="dismiss('Cross click'); resetValidationMsg();">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body-user">
          <form [formGroup]="formUser" class="col-md-12">
            <sf-app-user-edit-form [formUser]="formUser" [showVerificationField]="true" [user]="user" [showMsg]="showMsg"></sf-app-user-edit-form>
            <div class="actions btn-toolbar mt-3 justify-content-end">
              <div class="modal-footer">
                <div class="actions btn-toolbar mt-3 justify-content-end">
                  <div class="mr-3">
                    <div (click)="updateUser()" class="btn-wrapper">
                      <button class="btn-custom-primary-modal" type="submit" [disabled]="submitting">
                        <label class="btn-lable-primary-modal">{{ 'FE_General_Button_Save' | translate }}</label>
                      </button>
                    </div>
                  </div>
                  <div (click)="close('Close click')" class="btn-wrapper">
                    <button class="btn-custom-secondary-modal" type="button" (click)="close('Close click')">
                      <label (click)="close('Close click')" class="btn-lable-secondary-modal">{{ 'FE_General_Button_Cancel' | translate }}</label>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
    </tr>
  </table>
</div>


<ng-template #usercontent let-close="close" let-dismiss="dismiss">
  <div class="modal-header">
    <span class="delete-icon-back">
      <img src="../../../../assets/images/delete.svg" class="delete-shape">
    </span>
    <span class="modal-title">Delete Item</span>
    <button type="button" class="close" aria-label="Close" (click)="close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p> Are you sure you want to delete this user?</p>
  </div>
  <div class="modal-footer">
    <div class="actions btn-toolbar mt-3 justify-content-end">
      <div class="mr-3">
        <div (click)="deleteUser()" class="btn-wrapper">
          <button class="btn-custom-primary-modal" type="button" [disabled]="submitting">
            <label class="btn-lable-primary-modal">{{ 'FE_General_Button_Ok' | translate }}</label>
          </button>
        </div>
      </div>
      <div (click)="close('Close click')" class="btn-wrapper">
        <button class="btn-custom-secondary-modal" type="button">
          <label class="btn-lable-secondary-modal">{{ 'FE_General_Button_Cancel' | translate }}</label>
        </button>
      </div>
    </div>
  </div>
</ng-template>