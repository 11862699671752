var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IdentityClientFormBuilder } from '@modules/IdentityServer/services/IdentityClientFormBuildur';
import { IdentityClientService } from '@modules/IdentityServer/services/IdentityClientService';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
var IdentityClientsFormScopesSecretsComponent = /** @class */ (function () {
    function IdentityClientsFormScopesSecretsComponent(formBuilder, service, modalService) {
        this.formBuilder = formBuilder;
        this.service = service;
        this.modalService = modalService;
        this.showSaveScopes = false;
        this.showSaveProperties = false;
        this.showSaveClientSecretes = false;
        this.newRow = false;
        this.loading = false;
        this.isHide = false;
        this.flag = false;
        this.validationMsg = false;
        this.duplicateNameValidation = false;
    }
    IdentityClientsFormScopesSecretsComponent.prototype.ngOnInit = function () {
    };
    IdentityClientsFormScopesSecretsComponent.prototype.toggle = function (id, key) {
        if (this.newRow) {
            var length_1 = this.form.controls[this.key].value.length;
            var control = this.form.controls[this.key];
            control.removeAt(length_1 - 1);
            this.newRow = false;
            this.editId = null;
            this.flag = false;
        }
        this.removeItem(this.key);
        this.hideEdit(this.key);
        this.key = key;
        this.showEdit(key);
        var target = this.form.controls[this.key];
        this.oldValue = target.value[id];
        this.editId = id;
        this.flag = true;
        this.validationMsg = false;
        this.duplicateNameValidation = false;
    };
    IdentityClientsFormScopesSecretsComponent.prototype.resetRedirectUri = function (key) {
        this.key = key || this.key;
        if (this.newRow) {
            var length_2 = this.form.controls[this.key].value.length;
            var control = this.form.controls[this.key];
            control.removeAt(length_2 - 1);
            this.flag = false;
            this.newRow = false;
        }
        this.removeItem(key);
        this.hideEdit(this.key);
        this.editId = null;
        this.validationMsg = false;
        this.isHide = false;
        this.duplicateNameValidation = false;
    };
    IdentityClientsFormScopesSecretsComponent.prototype.showEdit = function (key) {
        switch (key) {
            case 'allowedScopes':
                this.showSaveScopes = true;
                if (this.newRow) {
                    this.items = this.form.controls[key];
                    this.items.push(this.formBuilder.createScope(''));
                }
                break;
            case 'properties':
                this.showSaveProperties = true;
                if (this.newRow) {
                    this.items = this.form.controls[key];
                    this.items.push(this.formBuilder.createProperties(''));
                }
                break;
            case 'clientSecrets':
                this.showSaveClientSecretes = true;
                if (this.newRow) {
                    this.items = this.form.controls[key];
                    this.items.push(this.formBuilder.createSecrets(''));
                }
                break;
        }
    };
    IdentityClientsFormScopesSecretsComponent.prototype.hideEdit = function (key) {
        switch (key) {
            case 'allowedScopes':
                this.showSaveScopes = false;
                break;
            case 'properties':
                this.showSaveProperties = false;
                break;
            case 'clientSecrets':
                this.showSaveClientSecretes = false;
                break;
        }
    };
    IdentityClientsFormScopesSecretsComponent.prototype.addItem = function (key) {
        if (this.key !== undefined && this.newRow) {
            var length_3 = this.form.controls[this.key].value.length;
            var control = this.form.controls[this.key];
            control.removeAt(length_3 - 1);
            this.flag = false;
            this.newRow = false;
        }
        this.removeItem(this.key);
        this.hideEdit(this.key);
        this.key = key;
        this.newRow = true;
        this.showEdit(key);
        this.editId = this.items.length - 1;
        this.flag = true;
        this.validationMsg = false;
        this.duplicateNameValidation = false;
    };
    IdentityClientsFormScopesSecretsComponent.prototype.duplicateIdentifier = function (field) {
        var control = this.form.controls[this.key];
        var target = control.controls[this.editId];
        var flag = 0;
        for (var i = 0; i < control.value.length; i++) {
            if (control.value[i][field] === target.value[field] && control.value[i].deleteOperation !== 0) {
                flag++;
            }
        }
        if (flag > 1) {
            this.duplicateNameValidation = true;
            return true;
        }
        this.duplicateNameValidation = false;
        return false;
    };
    IdentityClientsFormScopesSecretsComponent.prototype.update = function () {
        var control = this.form.controls[this.key];
        var target = control.controls[this.editId];
        if (target.invalid || this.duplicateNameValidation) {
            this.validationMsg = true;
            return;
        }
        this.hideEdit(this.key);
        this.newRow = false;
        this.editId = null;
        this.flag = false;
        this.validationMsg = false;
        this.isHide = false;
        this.duplicateNameValidation = false;
    };
    IdentityClientsFormScopesSecretsComponent.prototype.updateHashValue = function (i) {
        return __awaiter(this, void 0, void 0, function () {
            var hashValue, control, target, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.loading = true;
                        return [4 /*yield*/, this.service.updateHash()];
                    case 1:
                        hashValue = _a.sent();
                        control = this.form.controls['clientSecrets'];
                        target = control.controls[i];
                        target.patchValue({ value: hashValue.result.value });
                        this.hashValue = hashValue.result.key;
                        this.isHide = true;
                        return [3 /*break*/, 4];
                    case 2:
                        err_1 = _a.sent();
                        this.error = err_1;
                        return [3 /*break*/, 4];
                    case 3:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    IdentityClientsFormScopesSecretsComponent.prototype.deleteRedirectUri = function () {
        var control = this.form.controls[this.key];
        var target = control.controls[this.editId];
        if (target.value['id'] === 0) {
            control.removeAt(this.editId);
        }
        else {
            target.patchValue({ deleteOperation: 0 });
            this.items = this.form.controls[this.key];
            this.form.controls[this.deletedType].value.push(this.items.value[this.editId]['id']);
        }
        this.editId = null;
        this.modalReference.close();
    };
    IdentityClientsFormScopesSecretsComponent.prototype.confirmModal = function (content, index, key, deletedType) {
        this.hideEdit(this.key);
        this.removeItem(this.key);
        this.flag = false;
        this.newRow = false;
        this.editId = index;
        this.deletedType = deletedType;
        this.key = key;
        this.modalReference = this.modalService.open(content, { centered: true });
    };
    IdentityClientsFormScopesSecretsComponent.prototype.removeItem = function (key) {
        if (this.newRow) {
            var length_4 = this.form.controls[this.key].value.length;
            var control = this.form.controls[this.key];
            control.removeAt(length_4 - 1);
        }
        else if (this.flag) {
            this.key = key || this.key;
            var control = this.form.controls[this.key];
            var target = control.controls[this.editId];
            target.patchValue(this.oldValue);
            this.flag = false;
            this.hideEdit(this.key);
            this.editId = null;
        }
    };
    return IdentityClientsFormScopesSecretsComponent;
}());
export { IdentityClientsFormScopesSecretsComponent };
