import { Component, OnInit } from '@angular/core';
import { SessionService } from '@core/services/Session';
import { DataService } from '@core/services/DataService';

@Component({
  selector: 'sf-access-manager-layout',
  templateUrl: './access-manager-layout.component.html',
  styleUrls: ['./access-manager-layout.component.scss'],
})
export class AccessManagerLayoutComponent implements OnInit {

  public links = [
    { href: 'select/client-select', title: 'Client Selection'},
    { href: 'select/product-select', title: 'Product Selection'},
  ];

  public ready = false;
  public error: any = null;

  constructor(private session: SessionService, private dataService: DataService) {}

  async ngOnInit() {
    try {
      this.session.startSession();
      await this.dataService.defineEndpoints();
      // await this.session.loadProfile();
      this.ready = true;
    } catch (err) {
      this.error = err;
    }
  }

}
