import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
var UserEditRolesProductsComponent = /** @class */ (function () {
    function UserEditRolesProductsComponent() {
    }
    UserEditRolesProductsComponent.prototype.ngOnInit = function () {
        var userProduct = this.formUser.controls['userProduct'];
        this.userProduct = userProduct.controls;
    };
    UserEditRolesProductsComponent.prototype.isProductRolesDisabled = function (index) {
        return !this.formUser.value.userProduct[index].checked;
    };
    return UserEditRolesProductsComponent;
}());
export { UserEditRolesProductsComponent };
