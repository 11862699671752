import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IdentityResourceFormBuilder } from '@modules/IdentityServer/services/IdentityResourceFormBuilder';
var EditFormIdentityResourcesComponent = /** @class */ (function () {
    function EditFormIdentityResourcesComponent(formBuilder, modalService) {
        this.formBuilder = formBuilder;
        this.modalService = modalService;
        this.showSaveUserClaim = false;
        this.newRow = false;
        this.flag = false;
        this.validationMsg = false;
        this.duplicateNameValidation = false;
    }
    EditFormIdentityResourcesComponent.prototype.ngOnInit = function () {
        var userClaims = this.form.controls['userClaims'];
        this.userClaims = userClaims.controls;
    };
    EditFormIdentityResourcesComponent.prototype.toggle = function (id, key) {
        if (this.newRow) {
            var length_1 = this.form.controls[this.key].value.length;
            var control = this.form.controls[this.key];
            control.removeAt(length_1 - 1);
            this.newRow = false;
            this.editId = null;
            this.flag = false;
        }
        this.removeItem(this.key);
        this.showSaveUserClaim = false;
        this.key = key;
        this.showEdit(key);
        var target = this.form.controls[this.key];
        this.oldValue = target.value[id];
        this.editId = id;
        this.flag = true;
        this.validationMsg = false;
        this.duplicateNameValidation = false;
    };
    EditFormIdentityResourcesComponent.prototype.reset = function (key) {
        this.key = key || this.key;
        if (this.newRow) {
            var length_2 = this.form.controls[this.key].value.length;
            var control = this.form.controls[this.key];
            control.removeAt(length_2 - 1);
            this.flag = false;
            this.newRow = false;
        }
        this.removeItem(key);
        this.showSaveUserClaim = false;
        this.editId = null;
        this.validationMsg = false;
        this.duplicateNameValidation = false;
    };
    EditFormIdentityResourcesComponent.prototype.showEdit = function (key) {
        this.showSaveUserClaim = true;
        if (this.newRow) {
            this.items = this.form.controls[key];
            this.items.push(this.formBuilder.createUserClaim(''));
        }
    };
    EditFormIdentityResourcesComponent.prototype.addItem = function (key) {
        if (this.key !== undefined && this.newRow) {
            var length_3 = this.form.controls[this.key].value.length;
            var control = this.form.controls[this.key];
            control.removeAt(length_3 - 1);
            this.flag = false;
            this.newRow = false;
        }
        this.removeItem(this.key);
        this.showSaveUserClaim = false;
        this.key = key;
        this.newRow = true;
        this.showEdit(key);
        this.editId = this.items.length - 1;
        this.flag = true;
        this.validationMsg = false;
        this.duplicateNameValidation = false;
    };
    EditFormIdentityResourcesComponent.prototype.duplicateIdentifier = function () {
        this.duplicateNameValidation = true;
        var control = this.form.controls[this.key];
        var target = control.controls[this.editId];
        var flag = 0;
        for (var i = 0; i < control.value.length; i++) {
            if (control.value[i].type === target.value.type && control.value[i].deleteOperation !== 0) {
                flag++;
            }
        }
        if (flag > 1) {
            this.duplicateNameValidation = true;
            return true;
        }
        this.duplicateNameValidation = false;
        return false;
    };
    EditFormIdentityResourcesComponent.prototype.update = function () {
        var control = this.form.controls[this.key];
        var target = control.controls[this.editId];
        if (target.invalid || this.duplicateIdentifier()) {
            this.validationMsg = true;
            return;
        }
        this.showSaveUserClaim = false;
        this.newRow = false;
        this.editId = null;
        this.flag = false;
        this.validationMsg = false;
        this.duplicateNameValidation = false;
    };
    EditFormIdentityResourcesComponent.prototype.delete = function () {
        var control = this.form.controls[this.key];
        var target = control.controls[this.editId];
        if (target.value['id'] === 0) {
            control.removeAt(this.editId);
        }
        else {
            target.patchValue({ deleteOperation: 0 });
            this.items = this.form.controls[this.key];
            this.form.controls[this.deletedType].value.push(this.items.value[this.editId]['id']);
        }
        this.editId = null;
        this.modalReference.close();
    };
    EditFormIdentityResourcesComponent.prototype.confirmModal = function (content, index, key, deletedType) {
        this.showSaveUserClaim = false;
        this.removeItem(this.key);
        this.flag = false;
        this.newRow = false;
        this.editId = index;
        this.key = key;
        this.deletedType = deletedType;
        this.modalReference = this.modalService.open(content, { centered: true });
    };
    EditFormIdentityResourcesComponent.prototype.removeItem = function (key) {
        if (this.newRow) {
            var length_4 = this.form.controls[this.key].value.length;
            var control = this.form.controls[this.key];
            control.removeAt(length_4 - 1);
        }
        else if (this.flag) {
            this.key = key || this.key;
            var control = this.form.controls[this.key];
            var target = control.controls[this.editId];
            target.patchValue(this.oldValue);
            this.flag = false;
            this.showSaveUserClaim = false;
            this.editId = null;
        }
    };
    return EditFormIdentityResourcesComponent;
}());
export { EditFormIdentityResourcesComponent };
