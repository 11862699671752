import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IdentityClientsFormUrlsComponent, } from '@modules/IdentityServer/component/identity-clients-form-urls/identity-clients-form-urls.component';
import { IdentityClientsFormScopesSecretsComponent, } from '@modules/IdentityServer/component/identity-clients-form-scopes-secrets/identity-clients-form-scopes-secrets.component';
var EditFormIdentityServerClientComponent = /** @class */ (function () {
    function EditFormIdentityServerClientComponent() {
        this.protocolTypes = [
            { key: 'oidc', value: 'oidc' },
            { key: 'wsfed', value: 'WsFederation' },
            { key: 'Saml2p', value: 'Saml2p' },
        ];
    }
    EditFormIdentityServerClientComponent.prototype.ngOnInit = function () {
    };
    EditFormIdentityServerClientComponent.prototype.resetRedirectUri = function () {
        if (this.scope !== undefined) {
            this.scope.resetRedirectUri();
        }
        else if (this.url !== undefined) {
            this.url.resetRedirectUri();
        }
    };
    EditFormIdentityServerClientComponent.prototype.removeItem = function () {
        if (this.scope !== undefined) {
            this.scope.removeItem();
        }
        else if (this.url !== undefined) {
            this.url.removeItem();
        }
    };
    return EditFormIdentityServerClientComponent;
}());
export { EditFormIdentityServerClientComponent };
