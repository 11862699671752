import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, AbstractControl } from '@angular/forms';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IdentityResourceFormBuilder } from '@modules/IdentityServer/services/IdentityResourceFormBuilder';

@Component({
  selector: 'sf-app-edit-form-identityresources',
  templateUrl: './edit-form-identity-resources.component.html',
  styleUrls: ['./edit-form-identity-resources.component.scss'],
})
export class EditFormIdentityResourcesComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() showFields: boolean;
  @Input() showMsg: boolean;
  public userClaims: AbstractControl[];
  public showSaveUserClaim = false;
  public editId: number;
  public newRow = false;
  public key: string;
  public modalReference: NgbModalRef;
  public deletedType: string;
  public selectedValue: string;
  public flag = false;
  public oldValue: any;
  public items: FormArray;
  public validationMsg = false;
  @Input() error: any;
  public duplicateNameValidation = false;

  constructor(
    private formBuilder: IdentityResourceFormBuilder,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    const userClaims = <FormArray>this.form.controls['userClaims'];
    this.userClaims = userClaims.controls;
  }

  toggle(id: number, key: string) {
    if (this.newRow) {
      const length = this.form.controls[this.key].value.length;
      const control = <FormArray>this.form.controls[this.key];
      control.removeAt(length - 1);
      this.newRow = false;
      this.editId = null;
      this.flag = false;
    }
    this.removeItem(this.key);
    this.showSaveUserClaim = false;
    this.key = key;
    this.showEdit(key);
    const target = <FormArray>this.form.controls[this.key];
    this.oldValue = target.value[id];
    this.editId = id;
    this.flag = true;
    this.validationMsg = false;
    this.duplicateNameValidation = false;
  }

  reset(key?: string) {
    this.key = key || this.key;
    if (this.newRow) {
      const length = this.form.controls[this.key].value.length;
      const control = <FormArray>this.form.controls[this.key];
      control.removeAt(length - 1);
      this.flag = false;
      this.newRow = false;
    }
    this.removeItem(key);
    this.showSaveUserClaim = false;
    this.editId = null;
    this.validationMsg = false;
    this.duplicateNameValidation = false;
  }

  showEdit(key: string) {
    this.showSaveUserClaim = true;
    if (this.newRow) {
      this.items = this.form.controls[key] as FormArray;
      this.items.push(this.formBuilder.createUserClaim(''));
    }
  }

  addItem(key: string): void {
    if (this.key !== undefined && this.newRow) {
      const length = this.form.controls[this.key].value.length;
      const control = <FormArray>this.form.controls[this.key];
      control.removeAt(length - 1);
      this.flag = false;
      this.newRow = false;
    }
    this.removeItem(this.key);
    this.showSaveUserClaim = false;
    this.key = key;
    this.newRow = true;
    this.showEdit(key);
    this.editId = this.items.length - 1;
    this.flag = true;
    this.validationMsg = false;
    this.duplicateNameValidation = false;
  }

  duplicateIdentifier() {
    this.duplicateNameValidation = true;
    const control = <FormArray>this.form.controls[this.key];
    const target = control.controls[this.editId];
    let flag = 0;
    for (let i = 0; i < control.value.length; i++) {
      if (control.value[i].type === target.value.type && control.value[i].deleteOperation !== 0) {
        flag++;
      }
    }
    if (flag > 1) {
      this.duplicateNameValidation = true;
      return true;
    }
    this.duplicateNameValidation = false;
    return false;
  }

  update() {
    const control = <FormArray>this.form.controls[this.key];
    const target = control.controls[this.editId];
    if (target.invalid || this.duplicateIdentifier()) {
      this.validationMsg = true;
      return;
    }
    this.showSaveUserClaim = false;
    this.newRow = false;
    this.editId = null;
    this.flag = false;
    this.validationMsg = false;
    this.duplicateNameValidation = false;
  }

  delete() {
    const control = <FormArray>this.form.controls[this.key];
    const target = control.controls[this.editId];
    if (target.value['id'] === 0) {
      control.removeAt(this.editId);
    } else {
      target.patchValue({ deleteOperation: 0 });
      this.items = this.form.controls[this.key] as FormArray;
      this.form.controls[this.deletedType].value.push(this.items.value[this.editId]['id']);
    }
    this.editId = null;
    this.modalReference.close();
  }

  confirmModal(content: any, index: number, key: string, deletedType: string) {
    this.showSaveUserClaim = false;
    this.removeItem(this.key);
    this.flag = false;
    this.newRow = false;
    this.editId = index;
    this.key = key;
    this.deletedType = deletedType;
    this.modalReference = this.modalService.open(content, { centered: true });
  }

  removeItem(key?: string) {
    if (this.newRow) {
      const length = this.form.controls[this.key].value.length;
      const control = <FormArray>this.form.controls[this.key];
      control.removeAt(length - 1);
    } else if (this.flag) {
      this.key = key || this.key;
      const control = <FormArray>this.form.controls[this.key];
      const target = control.controls[this.editId];
      target.patchValue(this.oldValue);
      this.flag = false;
      this.showSaveUserClaim = false;
      this.editId = null;
    }
  }

}
