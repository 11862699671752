/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./client-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./client-select.component";
var styles_ClientSelectComponent = [i0.styles];
var RenderType_ClientSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientSelectComponent, data: {} });
export { RenderType_ClientSelectComponent as RenderType_ClientSelectComponent };
function View_ClientSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "col-md-6 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["routerLink", "/access/select/product-select"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "figure", [["class", "figure"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "figure-img img-fluid"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "figcaption", [["class", "figure-caption"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_2 = "/access/select/product-select"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.src, ""); var currVal_4 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.alt, ""); _ck(_v, 4, 0, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.imgCaption; _ck(_v, 6, 0, currVal_5); }); }
export function View_ClientSelectComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Simplifai"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [["class", "text-muted"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Customer access"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "row pt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We found that your user has access to more than one client. Please select client you would like to connect form"])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "pt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "fieldset", [["class", "scheduler-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "legend", [["class", "scheduler-border"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Available Customers"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientSelectComponent_1)), i1.ɵdid(14, 802816, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.clientData; _ck(_v, 14, 0, currVal_0); }, null); }
export function View_ClientSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sf-client-select", [], null, null, null, View_ClientSelectComponent_0, RenderType_ClientSelectComponent)), i1.ɵdid(1, 114688, null, 0, i4.ClientSelectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientSelectComponentNgFactory = i1.ɵccf("sf-client-select", i4.ClientSelectComponent, View_ClientSelectComponent_Host_0, { clientData: "clientData" }, {}, []);
export { ClientSelectComponentNgFactory as ClientSelectComponentNgFactory };
