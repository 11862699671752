import { Component, OnInit } from '@angular/core';
import { IIdentityResourceResponse } from '@modules/IdentityServer/identityServer.model';
import { IdentityResourceService } from '@modules/IdentityServer/services/IdentityResourceService';
import { IPageInfoResponse } from '@core/core.interfaces';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  selector: 'sf-app-identityresources',
  templateUrl: './identity-resources.component.html',
  styleUrls: ['./identity-resources.component.scss'],
})
export class IdentityResourcesListComponent implements OnInit {

  public loading = false;
  public error: any = null;
  public clients: IIdentityResourceResponse[];
  public pageInfo: IPageInfoResponse;
  public pageNumbers: any;
  public currentPageNumber: number;
  public collectionSize: number;
  public totalPages: number;
  public searchText: string;

  constructor(private service: IdentityResourceService, private exception: ExcepionHandlingService) { }

  ngOnInit() {
    this.currentPageNumber = 1;
    this.loadIdentityResourceClients(1);
  }

  async loadIdentityResourceClients(pageNumber: number) {
    if (this.clients && this.clients.length === 1 && pageNumber !== 1) {
      pageNumber--;
      this.currentPageNumber = pageNumber;
    }
    try {
      this.loading = true;
      const result = await this.service.getAllClients(pageNumber, this.searchText);
      this.clients = result.records;
      this.pageInfo = result.pageInfo;
      this.collectionSize = this.pageInfo.totalRecords;
      this.pageNumbers = new Array(this.pageInfo.totalPages);
      this.totalPages = this.pageInfo.totalPages;
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
    }
  }

  search(search: string) {
    this.searchText = search;
    this.loadIdentityResourceClients(1);
  }

}
