var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Validators } from '@angular/forms';
import { FormHelper } from '@core/services/FormHelper';
import { map } from 'lodash';
import { regexName } from '@config/constants';
var UserFormBuilder = /** @class */ (function (_super) {
    __extends(UserFormBuilder, _super);
    function UserFormBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UserFormBuilder.prototype.createEmpty = function () {
        return this.fromModel({
            username: '',
            firstName: '',
            lastName: '',
            userProduct: [],
            isActive: true,
            phoneNumber: '',
            lastLogin: new Date(),
            changePasswordOnNextLogin: false,
            lockoutEnd: '1/1/0001 12:00:00 AM +00:00',
            lockoutEnabled: false,
            emailConfirmed: true,
            roles: [],
            loginType: 0,
            enableMFA: false,
        });
    };
    UserFormBuilder.prototype.fromModel = function (model) {
        var _this = this;
        return this.builder.group({
            username: [model.username, [Validators.required,
                    Validators.email,
                    Validators.maxLength(75)],
            ],
            firstName: [model.firstName, [Validators.required,
                    Validators.pattern(regexName),
                    Validators.maxLength(75)],
            ],
            lastName: [model.lastName, [Validators.required,
                    Validators.pattern(regexName),
                    Validators.maxLength(75)],
            ],
            isActive: [model.isActive],
            phoneNumber: [model.phoneNumber, [
                    Validators.pattern('^[0-9]*$'),
                    Validators.maxLength(10)
                ],
            ],
            lastLogin: [model.lastLogin],
            changePasswordOnNextLogin: [model.changePasswordOnNextLogin],
            loginType: [model.loginType],
            lockoutEnd: [model.lockoutEnd],
            lockoutEnabled: [model.lockoutEnabled],
            emailConfirmed: [model.emailConfirmed],
            enableMFA: [model.enableMFA],
            roles: [model.roles],
            userProduct: this.builder.array(map(model.userProduct, function (product) { return _this.builder.group({
                id: product.id || '',
                name: product.name || '',
                checked: product.checked || false,
                roles: _this.builder.array(map(product.roles, function (attachedRole) { return _this.builder.group({
                    code: attachedRole.code || '',
                    name: attachedRole.name || '',
                    checked: attachedRole.checked || false,
                }); })),
            }); })),
        });
    };
    return UserFormBuilder;
}(FormHelper));
export { UserFormBuilder };
