import { NgModule } from '@angular/core';
import { UserService } from '@modules/Users/services/UserService';
import { CoreModule } from '@core/core.module';
import { UserMapper } from '@modules/Users/services/UserMapper';
import { UsersListComponent } from '@modules/Users/components/users-list/users-list.component';
import { UserEditFormComponent } from './components/user-edit-form/user-edit-form.component';
import { UserFormBuilder } from '@modules/Users/services/UserFormBuilder';
import { ComponentsModule } from '../../components/components.module';
import { UserEditFormActionsComponent } from './components/user-edit-form-actions/user-edit-form-actions.component';
import { UserEditRolesProductsComponent } from './components/user-edit-roles-products/user-edit-roles-products.component';

@NgModule({
  imports: [CoreModule, ComponentsModule],
  providers: [UserService, UserMapper, UserFormBuilder],
  declarations: [UsersListComponent, UserEditFormComponent, UserEditFormActionsComponent, UserEditRolesProductsComponent],
  exports: [UsersListComponent, UserEditFormComponent, UserEditFormActionsComponent, UserEditRolesProductsComponent],
})
export class UsersModule {}
