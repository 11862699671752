import { NgModule } from '@angular/core';
import { NgbTabsetModule, NgbModalModule, NgbDropdownModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastContainerComponent } from './toast/toast-container.component';
import { ToastMessageComponent } from './toast/toast-message.component';
import { ToastService } from './toast/toast.service';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    ToastContainerComponent,
    ToastMessageComponent,
  ],
  imports: [
    NgbTabsetModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbDropdownModule,
    BrowserModule,
  ],
  providers: [ToastService],
  exports: [
    NgbTabsetModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    ToastContainerComponent,
    ToastMessageComponent,
  ],
})
export class ComponentsModule {}
