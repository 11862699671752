<div class="row">
  <div class="col-md-6 col-lg-6">
    <div class="form-group row mt-3">
      <label for="id" class="control col-sm-3 col-form-label text-field-label" *ngIf="showFields">Id</label>
      <input id="id" class="form-control col-md-9 col-lg-9" [formControl]="form.controls['id']" *ngIf="showFields">
    </div>
    <div class="form-group row">
      <label for="name" class="control col-lg-3 col-md-3 col-form-label text-field-label">Name
        <span class="mandetory-input ml-1">*</span>
      </label>
      <input id="name" class="form-control col-lg-9 col-md-9" [formControl]="form.controls['name']" (keyup)="error=null"
      [ngClass]="{ 'is-invalid':((form.controls.name.dirty || form.controls.name.touched) && form.controls.name.errors || form.controls.name.untouched && showMsg && form.controls.name.invalid) || error!==null && (error.status===409 || (error.status===422 && error.error.Message.Name))}"
        required>
      <div *ngIf="(form.controls.name.dirty || form.controls.name.touched) && form.controls.name.errors || form.controls.name.untouched && showMsg && form.controls.name.invalid"
        class="invalid-feedback validation-msg-ir">
        <div *ngIf="form.controls.name.errors.required">Name is required</div>
        <div *ngIf="form.controls.name.errors?.pattern">'-' and '_' special characters allowed only(must not include spaces)</div>
        <div *ngIf="form.controls.name.errors?.maxlength && !form.controls.name.errors.pattern">Should not be more than 75 characters</div>
      </div>
      <div *ngIf="error!==null && error.status===409" class="invalid-feedback display-block validation-msg-ir">Name already exists</div>
      <div *ngIf="error!==null && error.status===422" class="invalid-feedback display-block validation-msg-ir">{{error.error.Message.Name}}</div>
    </div>
    <div class="form-group row">
      <label for="displayName" class="control col-lg-3 col-md-3 col-form-label text-field-label">Display Name
        <span class="mandetory-input ml-1">*</span>
      </label>
      <input id="displayName" class="form-control col-lg-9 col-md-9" [formControl]="form.controls['displayName']" (keyup)="error=null"
      [ngClass]="{ 'is-invalid':((form.controls.displayName.dirty || form.controls.displayName.touched) && form.controls.displayName.errors || form.controls.displayName.untouched && showMsg && form.controls.displayName.invalid) || error!==null && (error.status===422 && error.error.Message.DisplayName)}">
      <div *ngIf="(form.controls.displayName.dirty || form.controls.displayName.touched) && form.controls.displayName.errors || form.controls.displayName.untouched && showMsg && form.controls.displayName.invalid"
      class="validation-msg-ir">
        <div *ngIf="form.controls.displayName.errors.required">Display name is required</div>
        <div *ngIf="form.controls.displayName.errors?.maxlength && !form.controls.displayName.errors.pattern">Should not be more than 75 characters</div>
      </div>
      <div *ngIf="error!==null && error.status===422" class="invalid-feedback display-block validation-msg-ir">{{error.error.Message.DisplayName}}</div>
    </div>
    <div class="form-group row">
      <label for="description" class="control col-lg-3 col-md-3 col-form-label text-field-label">Description</label>
      <textarea id="description" class="form-control col-md-9 col-lg-9" [formControl]="form.controls['description']"
      [ngClass]="{ 'is-invalid':(form.controls.description.dirty || form.controls.description.touched) && form.controls.description.errors || form.controls.description.untouched && showMsg && form.controls.description.invalid}"></textarea>
      <div *ngIf="(form.controls.description.dirty || form.controls.description.touched) && form.controls.description.errors || form.controls.description.untouched && showMsg && form.controls.description.invalid"
      class="validation-msg-ir">
        <div *ngIf="form.controls.description.errors?.maxlength">Should not be more than 1000 characters</div>
      </div>
    </div>
  </div>
  <div class="col-md-6 col-lg-6 mt-md-5">
    <div class="row">
      <div class="col-sm-2 col-lg-2 col-md-2"></div>
      <div class="mx-md-0 mt-3 col-sm-12 col-lg-8">
        <input class="form-checkbox" id="checkenable" type="checkbox" [formControl]="form.controls['enabled']">
        <label for="checkenable">Enabled</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 col-lg-2 col-md-2"></div>
      <div class=" mx-md-0 col-sm-12 col-lg-8">
        <input class="form-checkbox" id="checkrequired" type="checkbox" [formControl]="form.controls['required']">
        <label for="checkrequired">Required</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 col-lg-2 col-md-2"></div>
      <div class="mx-md-0 col-sm-12 col-lg-8">
        <input class="form-checkbox" id="checkempasize" type="checkbox" [formControl]="form.controls['emphasize']">
        <label for="checkempasize">Emphasize</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 col-lg-2 col-md-2"></div>
      <div class=" mx-md-0 col-sm-12 col-lg-8">
        <input class="form-checkbox" id="checkindiscdoc" type="checkbox" [formControl]="form.controls['showInDiscoveryDocument']">
        <label for="checkindiscdoc">Show In Discovery Documents</label>
      </div>
    </div>
  </div>
</div>
<div class="row ml-1 mt-5">
  <fieldset class="col-md-6 col-lg-6">
    <legend>User Claims</legend>
    <div class="text-right mb-2">
      <button type="button" class="btn custom-btn ft-1" (click)="addItem('userClaims')">
        <i aria-hidden="true" class="fa fa-plus"></i> Add</button>
    </div>
    <div class="table-responsive table-wraper">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Claim</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody [formGroup]=form>
          <ng-container formArrayName="userClaims" *ngFor="let userClaim of userClaims; let i = index;">
            <tr *ngIf=" userClaim.get('deleteOperation').value ">
              <td class="col-width">
                <span *ngIf="editId==i && showSaveUserClaim" class="inputPadding">
                  <input type="text" [formControl]="userClaim.controls['type']" class="form-control w-100" [ngClass]="{ 'is-invalid': ((userClaim.controls.type.dirty || userClaim.controls.type.touched) && userClaim.controls.type.errors || userClaim.controls.type.untouched && validationMsg && userClaim.controls.type.invalid) || duplicateNameValidation}">
                  <div *ngIf="(userClaim.controls.type.dirty || userClaim.controls.type.touched) && userClaim.controls.type.errors || userClaim.controls.type.untouched && validationMsg && userClaim.controls.type.invalid" class="table-validation-msg">
                    <div *ngIf="userClaim.controls.type.errors?.required">Claim required</div>
                    <div *ngIf="userClaim.controls.type.errors?.maxlength">Should not be more than 75 characters</div>
                  </div>
                  <div class="table-validation-msg" *ngIf="duplicateIdentifier()">Type already exits</div>
                </span>
                <span *ngIf="editId!==i || !showSaveUserClaim" max="35" title="{{ userClaim.get('type').value }}">{{ userClaim.get('type').value | slice:0:35 }}</span>
              </td>
              <td class="text-center">
                <div class="btn-group" *ngIf="editId===i; else editClaim">
                  <div *ngIf="!showSaveUserClaim; else editClaim1">
                    <button class="btn custom-btn" type="button" (click)="toggle(i, 'userClaims')">
                      <img src="../../../../assets/images/edit.svg" class="Combined-Shape">
                    </button>
                    <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'userClaims', 'deleteUserClaimsIds')">
                      <img src="../../../../assets/images/delete.svg" class="Combined-Shape delete-opacity">
                    </button>
                  </div>
                  <ng-template #editClaim1>
                    <div class="btn-group">
                      <button type="button" type="button" class="btn custom-btn" (click)="update()">
                        <img src="../../../../assets/images/checked.svg" class="Combined-Shape">
                      </button>
                      <button class="btn custom-btn" type="button" (click)="reset('userClaims')">
                        <img src="../../../../assets/images/cancel.svg" class="Combined-Shape">
                      </button>
                    </div>
                  </ng-template>
                </div>
                <ng-template #editClaim>
                  <div class="btn-group">
                    <button class="btn custom-btn" type="button" (click)="toggle(i, 'userClaims')">
                      <img src="../../../../assets/images/edit.svg" class="Combined-Shape">
                    </button>
                    <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'userClaims', 'deleteUserClaimsIds')">
                      <img src="../../../../assets/images/delete.svg" class="Combined-Shape delete-opacity">
                    </button>
                  </div>
                </ng-template>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </fieldset>
</div>
<ng-template #content let-close="close" let-dismiss="dismiss">
  <div class="modal-header">
    <span class="delete-icon-back">
      <img src="../../../../assets/images/delete.svg" class="delete-shape">
    </span>
    <span class="modal-title">Delete Item</span>
    <button type="button" class="close" aria-label="Close" (click)="close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Are you sure you want to delete this items?
    </p>
  </div>
  <div class="modal-footer">
    <div class="actions btn-toolbar mt-3 justify-content-end">
      <div class="mr-3">
        <div (click)="delete()" class="btn-wrapper">
          <button class="btn-custom-primary-modal" type="button" [disabled]="submitting"><label class="btn-lable-primary-modal">{{ 'FE_General_Button_Ok' | translate }}</label></button>
        </div>
      </div>
      <div (click)="close('Close click')" class="btn-wrapper">
        <button class="btn-custom-secondary-modal" type="button"><label class="btn-lable-secondary-modal">{{ 'FE_General_Button_Cancel' | translate }}</label></button>
      </div>
    </div>
  </div>
</ng-template>