/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./identity-resources-edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/forms";
import * as i4 from "../../../modules/IdentityServer/component/edit-form-identity-resources/edit-form-identity-resources.component.ngfactory";
import * as i5 from "../../../modules/IdentityServer/component/edit-form-identity-resources/edit-form-identity-resources.component";
import * as i6 from "../../../modules/IdentityServer/services/IdentityResourceFormBuilder";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "@angular/router";
import * as i9 from "@angular/common";
import * as i10 from "../loading/loading.component.ngfactory";
import * as i11 from "../loading/loading.component";
import * as i12 from "./identity-resources-edit.component";
import * as i13 from "../../../modules/IdentityServer/services/IdentityResourceService";
import * as i14 from "../../../core/services/ExceptionHandlingService";
var styles_IdentityResourcesEditComponent = [i0.styles];
var RenderType_IdentityResourcesEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IdentityResourcesEditComponent, data: {} });
export { RenderType_IdentityResourcesEditComponent as RenderType_IdentityResourcesEditComponent };
function View_IdentityResourcesEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("FE_General_Lable_Edit")); var currVal_1 = _co.client.name; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_IdentityResourcesEditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "form", [["class", "mt-3 col-md-12"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("submit" === en)) {
        var pd_2 = (_co.onSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(2, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "sf-app-edit-form-identityresources", [], null, null, null, i4.View_EditFormIdentityResourcesComponent_0, i4.RenderType_EditFormIdentityResourcesComponent)), i1.ɵdid(6, 114688, [[1, 4]], 0, i5.EditFormIdentityResourcesComponent, [i6.IdentityResourceFormBuilder, i7.NgbModal], { form: [0, "form"], showFields: [1, "showFields"], error: [2, "error"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 12, "div", [["class", "actions btn-toolbar mt-3 justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "btn-wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "btn-custom-primary"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "label", [["class", "btn-lable-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 5, "div", [["class", "btn-wrapper"], ["routerLink", "/identity-resources"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i8.RouterLink, [i8.Router, i8.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["class", "btn-custom-secondary"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "label", [["class", "btn-lable-secondary"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.form; var currVal_9 = true; var currVal_10 = _co.error; _ck(_v, 6, 0, currVal_8, currVal_9, currVal_10); var currVal_13 = "/identity-resources"; _ck(_v, 15, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_11 = _co.submitting; _ck(_v, 10, 0, currVal_11); var currVal_12 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("FE_General_Button_Save")); _ck(_v, 12, 0, currVal_12); var currVal_14 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("FE_General_Button_Cancel")); _ck(_v, 18, 0, currVal_14); }); }
export function View_IdentityResourcesEditComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { formComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "page section-bg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IdentityResourcesEditComponent_1)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "sf-app-loading", [], null, null, null, i10.View_LoadingComponent_0, i10.RenderType_LoadingComponent)), i1.ɵdid(5, 114688, null, 0, i11.LoadingComponent, [], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IdentityResourcesEditComponent_2)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.client; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.form; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_IdentityResourcesEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sf-app-identityresources-edit", [], null, null, null, View_IdentityResourcesEditComponent_0, RenderType_IdentityResourcesEditComponent)), i1.ɵdid(1, 114688, null, 0, i12.IdentityResourcesEditComponent, [i13.IdentityResourceService, i6.IdentityResourceFormBuilder, i8.ActivatedRoute, i8.Router, i14.ExcepionHandlingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IdentityResourcesEditComponentNgFactory = i1.ɵccf("sf-app-identityresources-edit", i12.IdentityResourcesEditComponent, View_IdentityResourcesEditComponent_Host_0, {}, {}, []);
export { IdentityResourcesEditComponentNgFactory as IdentityResourcesEditComponentNgFactory };
