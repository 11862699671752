<div class="form-group row">
  <div class="col-sm-6">
    <div class="form-group row" *ngIf="showFields">
      <label for="id" class="control col-md-4 col-lg-4 col-form-label text-field-label">Id</label>
      <input id="id" class="form-control col-md-7" [formControl]="form.controls['id']">
    </div>
    <div class="form-group row">
      <label for="clientId" class="control col-md-4 col-lg-4 col-form-label text-field-label">Client Id<span class="mandetory-input ml-1">*</span></label>
      <input id="clientId" class="form-control col-md-7" [formControl]="form.controls['clientId']" (keyup)="error=null"
      [ngClass]="{ 'is-invalid': ((form.controls.clientId.dirty || form.controls.clientId.touched) && form.controls.clientId.errors || form.controls.clientId.untouched && showMsg && form.controls.clientId.invalid)  || error!==null && (error.status===409 || (error.status===422 && error.error.Message.ClientId))}" required>
      <div *ngIf="(form.controls.clientId.dirty || form.controls.clientId.touched) && form.controls.clientId.errors || form.controls.clientId.untouched && showMsg && form.controls.clientId.invalid" class="invalid-feedback validation-msg">
        <div *ngIf="form.controls.clientId.errors.required">Client id is required</div>
        <div *ngIf="form.controls.clientId.errors?.pattern">'-' and '_' special characters allowed only(must not include spaces)</div>
        <div *ngIf="form.controls.clientId.errors?.maxlength">Should not be more than 75 characters</div>
      </div>
      <div *ngIf="error!==null && error.status===409" class="invalid-feedback display-block validation-msg">ClientId already exists</div>
      <div *ngIf="error!==null && error.status===422" class="invalid-feedback display-block validation-msg">{{error.error.Message.ClientId}}</div>
    </div>
    <div class="form-group row">
      <label for="name" class="control col-md-4 col-lg-4 col-form-label text-field-label">Client Name<span class="mandetory-input ml-1">*</span></label>
      <input id="name" class="form-control col-md-7" [formControl]="form.controls['clientName']" (keyup)="error=null"
      [ngClass]="{ 'is-invalid': ((form.controls.clientName.dirty || form.controls.clientName.touched) && form.controls.clientName.errors || form.controls.clientName.untouched && showMsg && form.controls.clientName.invalid)  || error!==null && (error.status===422 && error.error.Message.ClientName)}" required>
      <div *ngIf="(form.controls.clientName.dirty || form.controls.clientName.touched) && form.controls.clientName.errors || form.controls.clientName.untouched && showMsg && form.controls.clientName.invalid" class="invalid-feedback validation-msg">
        <div *ngIf="form.controls.clientName.errors.required">Client name is required</div>
        <div *ngIf="form.controls.clientName.errors?.pattern">'-' and '_' special characters allowed only</div>
        <div *ngIf="form.controls.clientName.errors?.maxlength && !form.controls.clientName.errors.pattern">Should not be more than 75 characters</div>
      </div>
      <div *ngIf="error!==null && error.status===422" class="invalid-feedback display-block validation-msg">{{error.error.Message.ClientName}}</div>
    </div>
    <div class="form-group row">
      <label for="protocolType" class="control col-lg-4 col-md-4 col-form-label text-field-label">Protocol Type</label>
      <input list="protocol" class="form-control col-md-7 pr-4" [formControl]="form.controls['protocolType']" [ngClass]="{ 'is-invalid': (form.controls.protocolType.dirty || form.controls.protocolType.touched) && form.controls.protocolType.errors || form.controls.protocolType.untouched && showMsg && form.controls.protocolType.invalid}"/>
      <datalist id="protocol">
        <option *ngFor="let pType of protocolTypes" ngValue="{{pType.key}}">{{pType.value}}
      </datalist>
      <div *ngIf="(form.controls.protocolType.dirty || form.controls.protocolType.touched) && form.controls.protocolType.errors || form.controls.protocolType.untouched && showMsg && form.controls.protocolType.invalid" class="invalid-feedback validation-msg">
        <div *ngIf="form.controls.protocolType.errors?.maxlength">Should not be more than 75 characters</div>
      </div>
    </div>
    <div class="form-group row">
      <label for="clientUri" class="control col-md-4 col-lg-4 col-form-label text-field-label">Client Uri</label>
      <input id="clientUri" class="form-control col-md-7" [formControl]="form.controls['clientUri']"  [ngClass]="{ 'is-invalid': (form.controls.clientUri.dirty || form.controls.clientUri.touched) && form.controls.clientUri.errors || form.controls.clientUri.untouched && showMsg && form.controls.clientUri.invalid}">
      <div *ngIf="(form.controls.clientUri.dirty || form.controls.clientUri.touched) && form.controls.clientUri.errors || form.controls.clientUri.untouched && showMsg && form.controls.clientUri.invalid" class="invalid-feedback validation-msg">
        <div *ngIf="form.controls.clientUri.errors?.maxlength && !form.controls.clientUri.errors.pattern">Should not be more than 75 characters</div>
        <div *ngIf="form.controls.clientUri.errors?.pattern">Please enter valid URL</div>
      </div>
    </div>
    <div class="form-group row">
      <label for="logoUri" class="control col-md-4 col-lg-4 col-form-label text-field-label">Logo Uri</label>
      <input id="logoUri" class="form-control col-md-7" [formControl]="form.controls['logoUri']"  [ngClass]="{ 'is-invalid': (form.controls.logoUri.dirty || form.controls.logoUri.touched) && form.controls.logoUri.errors || form.controls.logoUri.untouched && showMsg && form.controls.logoUri.invalid}">
      <div *ngIf="(form.controls.logoUri.dirty || form.controls.logoUri.touched) && form.controls.logoUri.errors || form.controls.logoUri.untouched && showMsg && form.controls.logoUri.invalid" class="invalid-feedback validation-msg">
        <div *ngIf="form.controls.logoUri.errors?.maxlength && !form.controls.logoUri.errors.pattern">Should not be more than 75 characters</div>
        <div *ngIf="form.controls.logoUri.errors?.pattern">Please enter valid URL </div>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div>
      <input class="form-checkbox" id="requireClientSecret" type="checkbox" [formControl]="form.controls['requireClientSecret']">
      <label for="requireClientSecret">RequireClientSecret</label>
    </div>
    <div>
      <input class="form-checkbox" id="requireConsent" type="checkbox" [formControl]="form.controls['requireConsent']">
      <label for="requireConsent">RequireConsent</label>
    </div>
    <div>
      <input class="form-checkbox" id="allowRememberConsent" type="checkbox" [formControl]="form.controls['allowRememberConsent']">
      <label for="allowRememberConsent">AllowRememberConsent</label>
    </div>
    <div>
      <input class="form-checkbox" id="requirePkce" type="checkbox" [formControl]="form.controls['requirePkce']">
      <label for="requirePkce">RequirePkce</label>
    </div>
    <div>
      <input class="form-checkbox" id="allowPlainTextPkce" type="checkbox" [formControl]="form.controls['allowPlainTextPkce']">
      <label for="allowPlainTextPkce">AllowPlainTextPkce</label>
    </div>
    <div>
      <input class="form-checkbox" id="alwaysIncludeUserClaimsInIdToken" type="checkbox" [formControl]="form.controls['alwaysIncludeUserClaimsInIdToken']">
      <label for="alwaysIncludeUserClaimsInIdToken">AlwaysIncludeUserClaimsInIdToken</label>
    </div>
    <div>
      <input class="form-checkbox" id="updateAccessTokenClaimsOnRefresh" type="checkbox" [formControl]="form.controls['updateAccessTokenClaimsOnRefresh']">
      <label for="updateAccessTokenClaimsOnRefresh">UpdateAccessTokenClaimsOnRefresh</label>
    </div>
    <div>
      <input class="form-checkbox" id="enableLocalLogin" type="checkbox" [formControl]="form.controls['enableLocalLogin']">
      <label for="enableLocalLogin">EnableLocalLogin</label>
    </div>
    <div>
      <input class="form-checkbox" id="allowAccessTokensViaBrowser" type="checkbox" [formControl]="form.controls['allowAccessTokensViaBrowser']">
      <label for="allowAccessTokensViaBrowser">AllowAccessTokensViaBrowser</label>
    </div>
    <div>
      <input class="form-checkbox" id="includeJwtId" type="checkbox" [formControl]="form.controls['includeJwtId']">
      <label for="includeJwtId">IncludeJwtId</label>
    </div>
    <div>
      <input class="form-checkbox" id="alwaysSendClientClaims" type="checkbox" [formControl]="form.controls['alwaysSendClientClaims']">
      <label for="alwaysSendClientClaims">AlwaysSendClientClaims</label>
    </div>
  </div>
</div>
<div class="wrapper">
  <ngb-tabset>
    <ngb-tab title="Urls">
      <ng-template ngbTabContent>
        <sf-app-identity-clients-form-urls [form]="form" [client]="client" [error]="error"></sf-app-identity-clients-form-urls>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Scopes & Secrets">
      <ng-template ngbTabContent>
        <sf-app-identity-clients-form-scopes-secrets [form]="form" [client]="client" [error]="error"></sf-app-identity-clients-form-scopes-secrets>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Others">
      <ng-template ngbTabContent>
        <sf-app-identity-clients-form-others [form]="form" [client]="client"></sf-app-identity-clients-form-others>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>