/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./api-resource-edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/forms";
import * as i4 from "../../../modules/IdentityServer/component/api-resource-edit/api-resource-edit.component.ngfactory";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../../modules/IdentityServer/component/api-resource-edit/api-resource-edit.component";
import * as i7 from "../../../modules/IdentityServer/services/ApiResourceFormBuilder";
import * as i8 from "../../../modules/IdentityServer/services/ApiResourceService";
import * as i9 from "../../../core/services/ExceptionHandlingService";
import * as i10 from "@angular/router";
import * as i11 from "../loading/loading.component.ngfactory";
import * as i12 from "../loading/loading.component";
import * as i13 from "@angular/common";
import * as i14 from "./api-resource-edit.component";
var styles_ApiResourceEditPageComponent = [i0.styles];
var RenderType_ApiResourceEditPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApiResourceEditPageComponent, data: {} });
export { RenderType_ApiResourceEditPageComponent as RenderType_ApiResourceEditPageComponent };
function View_ApiResourceEditPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("FE_General_Lable_Edit")); var currVal_1 = _co.resource.name; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ApiResourceEditPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "form", [["class", "col-sm-12"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("submit" === en)) {
        var pd_2 = (_co.onSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(2, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "sf-api-resource-edit", [], null, null, null, i4.View_ApiResourceEditComponent_0, i4.RenderType_ApiResourceEditComponent)), i1.ɵprd(4608, null, i5.NgbDateAdapter, i5.NgbDateNativeAdapter, []), i1.ɵdid(7, 114688, [[1, 4]], 0, i6.ApiResourceEditComponent, [i7.ApiResourceFormBuilder, i5.NgbModal, i8.ApiResourceService, i9.ExcepionHandlingService], { form: [0, "form"], resource: [1, "resource"], showFields: [2, "showFields"], error: [3, "error"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 12, "div", [["class", "actions btn-toolbar mt-3 justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "mr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "btn-wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["class", "btn-custom-primary"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "label", [["class", "btn-lable-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "btn-wrapper"], ["routerLink", "/identity/api-resources"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i10.RouterLink, [i10.Router, i10.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["class", "btn-custom-secondary"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "label", [["class", "btn-lable-secondary"]], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.form; var currVal_9 = _co.resource; var currVal_10 = true; var currVal_11 = _co.error; _ck(_v, 7, 0, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_14 = "/identity/api-resources"; _ck(_v, 16, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_12 = _co.submitting; _ck(_v, 11, 0, currVal_12); var currVal_13 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("FE_General_Button_Save")); _ck(_v, 13, 0, currVal_13); var currVal_15 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("FE_General_Button_Cancel")); _ck(_v, 19, 0, currVal_15); }); }
export function View_ApiResourceEditPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { formComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "page page section-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "sf-app-loading", [], null, null, null, i11.View_LoadingComponent_0, i11.RenderType_LoadingComponent)), i1.ɵdid(3, 114688, null, 0, i12.LoadingComponent, [], { loading: [0, "loading"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApiResourceEditPageComponent_1)), i1.ɵdid(5, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApiResourceEditPageComponent_2)), i1.ɵdid(7, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.resource; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.form; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_ApiResourceEditPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ApiResourceEditPageComponent_0, RenderType_ApiResourceEditPageComponent)), i1.ɵdid(1, 114688, null, 0, i14.ApiResourceEditPageComponent, [i10.ActivatedRoute, i7.ApiResourceFormBuilder, i8.ApiResourceService, i10.Router, i9.ExcepionHandlingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApiResourceEditPageComponentNgFactory = i1.ɵccf("ng-component", i14.ApiResourceEditPageComponent, View_ApiResourceEditPageComponent_Host_0, {}, {}, []);
export { ApiResourceEditPageComponentNgFactory as ApiResourceEditPageComponentNgFactory };
