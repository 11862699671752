/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clients-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../modules/AccessManager/component/client-select/client-select.component.ngfactory";
import * as i3 from "../../../modules/AccessManager/component/client-select/client-select.component";
import * as i4 from "./clients-select.component";
import * as i5 from "../../../modules/AccessManager/services/client-select.service";
var styles_ClientsSelectPageComponent = [i0.styles];
var RenderType_ClientsSelectPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientsSelectPageComponent, data: {} });
export { RenderType_ClientsSelectPageComponent as RenderType_ClientsSelectPageComponent };
export function View_ClientsSelectPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sf-client-select", [], null, null, null, i2.View_ClientSelectComponent_0, i2.RenderType_ClientSelectComponent)), i1.ɵdid(1, 114688, null, 0, i3.ClientSelectComponent, [], { clientData: [0, "clientData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.clientData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ClientsSelectPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sf-clients-select", [], null, null, null, View_ClientsSelectPageComponent_0, RenderType_ClientsSelectPageComponent)), i1.ɵdid(1, 114688, null, 0, i4.ClientsSelectPageComponent, [i5.ClientSelectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientsSelectPageComponentNgFactory = i1.ɵccf("sf-clients-select", i4.ClientsSelectPageComponent, View_ClientsSelectPageComponent_Host_0, {}, {}, []);
export { ClientsSelectPageComponentNgFactory as ClientsSelectPageComponentNgFactory };
