import { forEach, set } from 'lodash';
import { FormBuilder } from '@angular/forms';
var FormHelper = /** @class */ (function () {
    function FormHelper(builder) {
        this.builder = builder;
    }
    FormHelper.prototype.toModel = function (form) {
        return this.map(form, {});
    };
    FormHelper.prototype.map = function (form, model) {
        forEach(form.controls, function (control, key) {
            set(model, key, control.value);
        });
        return model;
    };
    return FormHelper;
}());
export { FormHelper };
