<div class="page section-bg">
  <h1 class="mb-3">{{ 'FE_General_Lable_Create' | translate }} {{ 'FE_Headers_Label_ApiResources' | translate }}</h1>
  <form [formGroup]="form" (submit)="onSubmit()" class="col-md-12">
    <sf-api-resource-edit [form]="form" [showFields]="false" [showMsg]="showMsg" [error]="error"></sf-api-resource-edit>
    <div class="actions btn-toolbar mt-3 justify-content-end">
      <div class="mr-3">
        <div (click)="onSubmit()" class="btn-wrapper"><button class="btn-custom-primary" type="button" [disabled]="submitting"><label class="btn-lable-primary">{{ 'FE_General_Button_Save' | translate }}</label></button></div>
      </div>
      <div routerLink="/identity/api-resources" class="btn-wrapper"><button class="btn-custom-secondary" type="button"><label class="btn-lable-secondary">{{ 'FE_General_Button_Cancel' | translate }}</label></button></div>
    </div>
  </form>
</div>