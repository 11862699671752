<div class="page">
  <div class="jumbotron">
    <h1 class="display-4">{{ 'FE_Dashboard_Welcome' | translate }}</h1>
    <p class="lead">  {{ 'FE_Dashboard_Welcome_Title' | translate }}</p>
    <hr class="my-4">
    <p class="">
      <button class="btn-custom-primary mr-1" routerLink="/customers" role="button"><label routerLink="/customers" class="btn-lable-primary">{{ 'FE_Headers_Label_Customers' | translate }}</label></button>
      <button class="btn-custom-primary" routerLink="/products" role="button"><label routerLink="/products" class="btn-lable-primary">{{ 'FE_General_Lable_Products' | translate }}</label></button>
    </p>
  </div>
</div>