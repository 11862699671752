<div class="page page section-bg">
  <sf-app-loading [loading]="loading"></sf-app-loading>
  <h1 *ngIf="resource">{{ 'FE_General_Lable_Edit' | translate }} {{resource.name}}</h1>
  <form *ngIf="form" [formGroup]="form" (submit)="onSubmit()" class="col-sm-12">
    <sf-api-resource-edit [form]="form" [resource]="resource" [showFields]="true" [error]="error"></sf-api-resource-edit>
    <div class="actions btn-toolbar mt-3 justify-content-end">
      <div class="mr-3">
        <div (click)="onSubmit()" class="btn-wrapper"><button class="btn-custom-primary" type="submit" [disabled]="submitting"><label class="btn-lable-primary">{{ 'FE_General_Button_Save' | translate }}</label></button></div>
      </div>
      <div routerLink="/identity/api-resources" class="btn-wrapper"><button class="btn-custom-secondary" type="button"><label class="btn-lable-secondary">{{ 'FE_General_Button_Cancel' | translate }}</label></button></div>
    </div>
  </form>
   
</div>