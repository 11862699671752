var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { reduce } from 'lodash';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlBuilder } from '@core/services/UrlBuilder';
import { StaticEndpoints } from '@config/endpoints';
var DataService = /** @class */ (function () {
    function DataService(urlBuilder, http) {
        this.urlBuilder = urlBuilder;
        this.http = http;
    }
    DataService.prototype.get = function (url, query) {
        var _a = this.constructRequest(url, query), path = _a.path, params = _a.params;
        var subscription = this.http.get(path, { params: params });
        return subscription.toPromise();
    };
    DataService.prototype.delete = function (url, query) {
        var _a = this.constructRequest(url, query), path = _a.path, params = _a.params;
        var subscription = this.http.delete(path, { params: params });
        return subscription.toPromise();
    };
    DataService.prototype.post = function (url, data, query) {
        var _a = this.constructRequest(url, query), path = _a.path, params = _a.params;
        var subscription = this.http.post(path, data, { params: params });
        return subscription.toPromise();
    };
    DataService.prototype.put = function (url, data, query) {
        var _a = this.constructRequest(url, query), path = _a.path, params = _a.params;
        var subscription = this.http.put(path, data, { params: params });
        return subscription.toPromise();
    };
    DataService.prototype.request = function (endpoint, req) {
        if (req === void 0) { req = {}; }
        var link = (req && req.links) ?
            this.urlBuilder.getEndpoint(endpoint, req.links) :
            this.urlBuilder.findEndpoint(endpoint);
        var query = this.constructQuery(link.query, req.query);
        var stream = this.http.request(link.method, link.href, {
            body: req && req.data,
            params: query,
            headers: req.headers,
        });
        return stream.toPromise();
    };
    DataService.prototype.defineEndpoints = function () {
        return __awaiter(this, void 0, void 0, function () {
            var endpoints;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.get(StaticEndpoints.Root)];
                    case 1:
                        endpoints = _a.sent();
                        this.urlBuilder.setEndpoints(endpoints);
                        return [2 /*return*/, endpoints];
                }
            });
        });
    };
    DataService.prototype.getClient = function () {
        return this.http;
    };
    DataService.prototype.constructRequest = function (url, query) {
        var path = this.urlBuilder.build(url);
        var params;
        if (query) {
            params = this.constructQuery(query);
        }
        return { path: path, params: params };
    };
    DataService.prototype.constructQuery = function () {
        var params = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            params[_i] = arguments[_i];
        }
        var fromObject = reduce(params, function (result, current) {
            return __assign({}, result, current);
        }, {});
        return new HttpParams({ fromObject: fromObject });
    };
    return DataService;
}());
export { DataService };
