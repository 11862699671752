<div [formGroup]="formUser">
  <div formArrayName="userProduct">
    <div *ngFor="let product of userProduct; let i=index">
      <div class="panel-body" [formGroupName]="i" class="row mt-3 ml-1">
        <div class="col-md-6">
            <div>
              <input class="form-checkbox" id="productGet{{i}}" type="checkbox" [formControl]="product.controls['checked']">
              <label for="productGet{{i}}">{{ product.get('name').value }}</label>
            </div>
        </div>
        <div class="col-md-6" formArrayName="roles">
            <div *ngFor="let attachedRole of product.controls.roles.controls; let j=index">
              <div class="row col-sm-12" [formGroupName]="j">
                <div>
                  <input  type="checkbox" class="form-checkbox" id="attachRole{{i}}{{j}}" [formControl]="attachedRole.controls['checked']" [attr.disabled]="isProductRolesDisabled(i) ?  '' : null">
                  <label for="attachRole{{i}}{{j}}">{{ attachedRole.get('name').value }}</label>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-12">
        <hr>
      </div>
    </div>
  </div>
</div>
