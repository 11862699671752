<div class="page">
  <h1 *ngIf="customer" class="mb-3 table-heading">{{ 'FE_General_Lable_Edit' | translate }} {{customer.name}} {{ 'FE_Headers_Label_Customers' | translate }}</h1>
  <sf-app-loading [loading]="loading"></sf-app-loading>
  <div class="wrapper mt-3" *ngIf="customer">
    <ngb-tabset #tabs [activeId]="selectedTab" (tabChange)="onTabChange($event)">
      <ngb-tab id="general" title="{{ 'FE_General_Lable_General' | translate }}">
        <ng-template ngbTabContent>
          <div class="mt-3 col-md-12" *ngIf="formCustomer">
            <form [formGroup]="formCustomer" (submit)="onSubmit()">
              <sf-customer-edit-form [formCustomer]="formCustomer" [imagePath]="imagePath" [customer]="customer" (loadingToggle)="loadingToggle()" [error]="error"></sf-customer-edit-form>
              <div class="actions btn-toolbar mt-3 justify-content-end">
                <div class="mr-3">
                  <div (click)="onSubmit()" class="btn-wrapper"><button class="btn-custom-primary" type="submit" [disabled]="submitting"><label class="btn-lable-primary">{{ 'FE_General_Button_Save' | translate }}</label></button></div>
                </div>
                <div routerLink="/customers" class="btn-wrapper"><button class="btn-custom-secondary" type="button"><label class="btn-lable-secondary">{{ 'FE_General_Button_Cancel' | translate }}</label></button></div>
              </div>
            </form>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="products" title="{{ 'FE_General_Lable_Products' | translate }}">
        <ng-template ngbTabContent>
          <div class="mt-3" *ngIf="products">
            <div class="row">
              <div class="col-md-6 offset-md-6 col-sm-12">
                <div class="search-box">
                  <i class="fa fa-search fa-2x"></i>
                  <input type="text" class="search" placeholder="{{ 'FE_General_Search_PlaceHolder' | translate }}" [ngModel]="searchText" sfDebouncedInput (debouncedChange)="searchProducts($event)" [debounceTime]="700">
                </div>
              </div>
            </div>
            <sf-products-list *ngIf="!loading" [products]="products" (update)="loadCustomer()" [actions]="['attach']" [customer]="customer"></sf-products-list>
            <ngb-pagination *ngIf="totalProductPages>1" class="d-flex justify-content-center" [collectionSize]="productCollectionSize" [(page)]="productCurrentPageNumber" [maxSize]="5" 
             (pageChange)="loadProducts(productCurrentPageNumber)"></ngb-pagination>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab id="users" title="{{ 'FE_General_Lable_Users' | translate }}" >
        <ng-template ngbTabContent>
          <div class="mt-3">
            <div class="row pb-3">
              <div class="col-md-6">
                <button class="custom-btn" (click)="editModel(newcontent)"><i class="fa fa-user" aria-hidden="true"></i> {{ 'FE_General_Create_User' | translate }}</button>
                <ng-template #newcontent let-close="close" let-dismiss="dismiss">
                  <div class="modal-header">
                    <span class="modal-title">{{ 'FE_General_Create_User' | translate }}</span>
                    <button type="button" class="close" aria-label="Close" (click)="dismiss('Cross click'); resetValidationMsg();">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body-user">
                    <form [formGroup]="formUser" (submit)="onSubmit()" class="col-md-12">
                      <sf-app-user-edit-form [formUser]="formUser" [showMsg]="showMsg"></sf-app-user-edit-form>
                      <div class="actions btn-toolbar mt-3 justify-content-end">
                        <button class="btn-custom-primary-modal mr-3" type="button" (click)="validateUser(validateContent)" [disabled]="submitting"><label (click)="validateUser(validateContent)" class="btn-lable-primary-modal">{{ 'FE_General_Button_Save' | translate }}</label></button>
                        <button class="btn-custom-secondary-modal mr-3" type="button" (click)="close('Close click'); resetValidationMsg();"><label (click)="close('Close click'); resetValidationMsg();" class="btn-lable-secondary-modal">{{ 'FE_General_Button_Cancel' | translate }}</label></button>
                      </div>
                    </form>
                  </div>
                </ng-template> 
                <ng-template #validateContent let-close="close" let-dismiss="dismiss">
                  <div class="modal-header">
                    <span class="delete-icon-back"><img src="../../../../assets/images/info.svg" class="delete-shape"></span>
                    <span class="modal-title">{{ 'FE_General_Label_Alert' | translate }}</span>
                    <button type="button" class="close" aria-label="Close" (click)="dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p *ngIf="!canCreate">{{ 'FE_General_User_Exists_Message' | translate }}</p>
                    <p *ngIf="canCreate">{{ 'FE_General_User_Available_Message' | translate }}</p>
                  </div>
                  <div class="modal-footer">
                    <div class="actions btn-toolbar mt-3 justify-content-end">
                      <div class="mr-3">
                        <div (click)="onSubmit()" class="btn-wrapper">
                          <button class="btn-custom-primary-modal" type="button" (click)="createUser()" *ngIf="canCreate" [disabled]="submitting"><label (click)="createUser()" *ngIf="canCreate" class="btn-lable-primary-modal">{{ 'FE_General_Button_Ok' | translate }}</label></button>
                        </div>
                      </div>
                      <div (click)="close('Close click')" class="btn-wrapper"><button class="btn-custom-secondary" type="button" (click)="close('Close click')"><label (click)="close('Close click')" class="btn-lable-secondary">{{ 'FE_General_Button_Cancel' | translate }}</label></button></div>
                    </div>
                  </div>
                </ng-template> 
              </div>
              <div class="col-md-6">
                <div class="search-box">
                  <i class="fa fa-search fa-2x"></i>
                  <input type="text" class="search" placeholder="{{ 'FE_General_Search_PlaceHolder' | translate }}" [(ngModel)]="searchText" sfDebouncedInput (debouncedChange)="searchUsers($event)" [debounceTime]="700">
                </div>
              </div>
            </div>
            <sf-users-list *ngIf="!loading" [users]="users" [userCurrentPageNumber]="userCurrentPageNumber" [customer]="customer" [searchText]="searchText" (update)="loadUsers(userCurrentPageNumber)"></sf-users-list>
            <ngb-pagination *ngIf="totalUserPages > 1" class="d-flex justify-content-center" [collectionSize]="userCollectionSize" [(page)]="userCurrentPageNumber" [maxSize]="5" 
             (pageChange)="loadUsers(userCurrentPageNumber)"></ngb-pagination>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
</div>
