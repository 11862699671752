import { EventEmitter } from '@angular/core';
var FileDropDirective = /** @class */ (function () {
    function FileDropDirective() {
        this.filesDropped = new EventEmitter();
    }
    FileDropDirective.prototype.onDrop = function ($event) {
        $event.preventDefault();
        var transfer = $event.dataTransfer;
        this.filesDropped.emit(transfer.files);
    };
    FileDropDirective.prototype.onDragOver = function ($event) {
        $event.preventDefault();
    };
    return FileDropDirective;
}());
export { FileDropDirective };
