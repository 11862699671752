/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./roles-file-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./roles-file-upload.component";
import * as i6 from "../../services/role.service";
var styles_RolesFileUploadComponent = [i0.styles];
var RenderType_RolesFileUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RolesFileUploadComponent, data: {} });
export { RenderType_RolesFileUploadComponent as RenderType_RolesFileUploadComponent };
function View_RolesFileUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file.name; _ck(_v, 1, 0, currVal_0); }); }
function View_RolesFileUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("FE_General_Lable_Choosefile")); _ck(_v, 1, 0, currVal_0); }); }
export function View_RolesFileUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { form: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "col-12 row mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "custom-file col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["form", 1]], null, 10, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i3.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(5, 4210688, null, 0, i3.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(7, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "input", [["accept", ".xls,.xlsx"], ["class", "custom-file-input"], ["id", "customFile"], ["placeholder", "Upload file"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "label", [["class", "custom-file-label"], ["for", "customFile"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RolesFileUploadComponent_1)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RolesFileUploadComponent_2)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["class", "btn-custom-secondary mr-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.upload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "label", [["class", "btn-lable-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.upload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.file; _ck(_v, 11, 0, currVal_7); var currVal_8 = !_co.file; _ck(_v, 13, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("FE_General_Button_Upload")); _ck(_v, 17, 0, currVal_9); }); }
export function View_RolesFileUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sf-roles-file-upload", [], null, null, null, View_RolesFileUploadComponent_0, RenderType_RolesFileUploadComponent)), i1.ɵdid(1, 49152, null, 0, i5.RolesFileUploadComponent, [i6.RoleService], null, null)], null, null); }
var RolesFileUploadComponentNgFactory = i1.ɵccf("sf-roles-file-upload", i5.RolesFileUploadComponent, View_RolesFileUploadComponent_Host_0, { productId: "productId" }, { loadingToggle: "loadingToggle" }, []);
export { RolesFileUploadComponentNgFactory as RolesFileUploadComponentNgFactory };
