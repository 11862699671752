import { AbstractControl, ValidatorFn } from '@angular/forms';
export class CustomValidators {
    public static validUrl: boolean;
    public static pattern(reg: any): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const value = <string>control.value;
            if (value !== null && value !== '') {
                const pattern1 = reg[0].test(control.value);
                const pattern2 = reg[1].test(control.value);
                this.validUrl = (pattern1 || pattern2) ? false : true;
                return (pattern1 || pattern2) ? null : { pattern: true } ;
            }
        };
    }
}
