/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./toast-message.component";
var styles_ToastMessageComponent = [i0.styles];
var RenderType_ToastMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastMessageComponent, data: {} });
export { RenderType_ToastMessageComponent as RenderType_ToastMessageComponent };
function View_ToastMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toast.subtitle; _ck(_v, 1, 0, currVal_0); }); }
function View_ToastMessageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "footer", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toast.footer; _ck(_v, 1, 0, currVal_0); }); }
export function View_ToastMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "header", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastMessageComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastMessageComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "container"; var currVal_1 = _co.toast.type; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.toast.subtitle; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.toast.footer; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.toast.title; _ck(_v, 5, 0, currVal_2); }); }
export function View_ToastMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sf-toast-message", [], null, null, null, View_ToastMessageComponent_0, RenderType_ToastMessageComponent)), i1.ɵdid(1, 49152, null, 0, i3.ToastMessageComponent, [], null, null)], null, null); }
var ToastMessageComponentNgFactory = i1.ɵccf("sf-toast-message", i3.ToastMessageComponent, View_ToastMessageComponent_Host_0, { toast: "toast" }, { close: "close" }, []);
export { ToastMessageComponentNgFactory as ToastMessageComponentNgFactory };
