<div class="col-md-12" *ngIf="form">
  <div class="row">
    <fieldset class="col-md-12 col-lg-5 mt-5">
      <legend>Allowed Scopes</legend>
      <div class="text-right mb-2">
        <button type="button" class="btn custom-btn ft-1" (click)="addItem('allowedScopes')">
          <i aria-hidden="true" class="fa fa-plus"></i> Add</button>
      </div>
      <div class="table-responsive table-wraper">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="w-100">Scope</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody [formGroup]=form>
            <ng-container formArrayName="allowedScopes" *ngFor="let allowedScope of form.get('allowedScopes').controls; let i = index;">
              <tr *ngIf=" allowedScope.get('deleteOperation').value ">
                <td>
                  <span *ngIf="editId==i && showSaveScopes">
                    <input type="text" [formControl]="allowedScope.controls['scope']" class="form-control w-100" #input [ngClass]="{ 'is-invalid': ((allowedScope.controls.scope.dirty || allowedScope.controls.scope.touched) && allowedScope.controls.scope.errors || allowedScope.controls.scope.untouched && validationMsg && allowedScope.controls.scope.invalid) || duplicateNameValidation}">
                    <div *ngIf="(allowedScope.controls.scope.dirty || allowedScope.controls.scope.touched) && allowedScope.controls.scope.errors || allowedScope.controls.scope.untouched && validationMsg && allowedScope.controls.scope.invalid" class="table-validation-msg">
                      <div *ngIf="allowedScope.controls.scope.errors?.required">Scope is required</div>
                      <div *ngIf="allowedScope.controls.scope.errors?.maxlength && !allowedScope.controls.scope.errors.pattern">Should not be more than 75 characters</div>
                    </div>
                    <div class="table-validation-msg" *ngIf="duplicateIdentifier('scope')">Scope already exits</div>
                  </span>
                  <span *ngIf="editId!==i || !showSaveScopes" max="35" title="{{ allowedScope.get('scope').value }}">{{ allowedScope.get('scope').value | slice:0:35 }}</span>
                </td>
                <td class="text-center">
                  <div class="btn-group" *ngIf="editId===i; else editCors">
                    <div class="btn-group" *ngIf="!showSaveScopes; else editCors1">
                        <button class="btn custom-btn" type="button" (click)="toggle(i, 'allowedScopes')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                        <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'allowedScopes', 'deleteAllowedScopesIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape delete-opacity"></button>
                    </div>
                    <ng-template #editCors1>
                      <div class="btn-group">
                        <button class="btn custom-btn" type="button" (click)="update()"><img src="../../../../assets/images/checked.svg" class="Combined-Shape"></button>
                        <button class="btn custom-btn" type="button" (click)="resetRedirectUri('allowedScopes')"><img src="../../../../assets/images/cancel.svg" class="Combined-Shape"></button>
                      </div>
                    </ng-template>
                  </div>
                  <ng-template #editCors>
                    <div class="btn-group">
                      <button class="btn custom-btn" type="button" (click)="toggle(i, 'allowedScopes')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                      <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'allowedScopes', 'deleteAllowedScopesIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape delete-opacity"></button>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </fieldset>
    <fieldset class="col-md-12 col-lg-5 offset-md-0 offset-lg-2 mt-5">
      <legend>Properties</legend>
      <div class="text-right mb-2">
        <button type="button" class="btn custom-btn ft-1" (click)="addItem('properties')">
          <i aria-hidden="true" class="fa fa-plus"></i> Add</button>
      </div>
      <div class="table-responsive table-wraper">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="w-50">Key</th>
              <th class="w-50">Value</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody [formGroup]=form>
            <ng-container formArrayName="properties" *ngFor="let property of form.get('properties').controls; let i = index;">
              <tr *ngIf=" property.get('deleteOperation').value ">
                <td>
                  <span *ngIf="editId==i && showSaveProperties">
                    <input type="text" [formControl]="property.controls['key']" class="form-control-table" [ngClass]="{ 'is-invalid': ((property.controls.key.dirty || property.controls.key.touched) && property.controls.key.errors || property.controls.key.untouched && validationMsg && property.controls.key.invalid) || duplicateNameValidation}">
                    <div *ngIf="(property.controls.key.dirty || property.controls.key.touched) && property.controls.key.errors || property.controls.key.untouched && validationMsg && property.controls.key.invalid" class="table-validation-msg">
                      <div *ngIf="property.controls.key.errors?.required">key is required</div>
                      <div *ngIf="property.controls.key.errors?.maxlength">Should not be more than 75 characters</div>
                    </div>
                    <div class="table-validation-msg" *ngIf="duplicateIdentifier('key')">Key already exits</div>
                  </span>
                  <span *ngIf="editId!==i || !showSaveProperties" max="35" title="{{ property.get('key').value }}">{{ property.get('key').value | slice:0:35 }}</span>
                </td>
                <td>
                  <span *ngIf="editId==i && showSaveProperties">
                    <input type="text" [formControl]="property.controls['value']" class="form-control-table" [ngClass]="{ 'is-invalid': (property.controls.value.dirty || property.controls.value.touched) && property.controls.value.errors || property.controls.value.untouched && validationMsg && property.controls.value.invalid}">
                    <div *ngIf="(property.controls.value.dirty || property.controls.value.touched) && property.controls.value.errors || property.controls.value.untouched && validationMsg && property.controls.value.invalid" class="table-validation-msg">
                      <div *ngIf="property.controls.value.errors?.required">Value is required</div>
                      <div *ngIf="property.controls.value.errors?.maxlength">Should not be more than 75 characters</div>
                    </div>
                  </span>
                  <span *ngIf="editId!==i || !showSaveProperties" max="35" title="{{ property.get('value').value }}">{{ property.get('value').value | slice:0:35 }}</span>
                </td>
                <td class="text-center">
                  <div class="btn-group" *ngIf="editId===i; else editProperty">
                    <div class="btn-group" *ngIf="!showSaveProperties; else editProperty1">
                      <button class="btn custom-btn" type="button" (click)="toggle(i, 'properties')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                      <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'properties', 'deletePropertiesIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape delete-opacity"></button>
                    </div>
                    <ng-template #editProperty1>
                      <div class="btn-group">
                        <button class="btn custom-btn" type="button" (click)="update()"><img src="../../../../assets/images/checked.svg" class="Combined-Shape"></button>
                        <button class="btn custom-btn" type="button" (click)="resetRedirectUri('properties')"><img src="../../../../assets/images/cancel.svg" class="Combined-Shape"></button>
                      </div>
                    </ng-template>
                  </div>
                  <ng-template #editProperty>
                    <div class="btn-group">
                      <button class="btn custom-btn" type="button" (click)="toggle(i, 'properties')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                      <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'properties', 'deletePropertiesIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape delete-opacity"></button>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </fieldset>
  </div>
  
</div>
<div class="row mt-5" *ngIf="form">
  <div class=" col-sm-12 text-right">
    <button type="button" class="btn-custom-secondary btn-label-secondary mb-2" (click)="addItem('clientSecrets')">Add secret</button>
    <div class="table-responsive table-wraper">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Id</th>
            <th class="w-25">Expiration</th>
            <th class="w-25">Description</th>
            <th class="w-25">Value</th>
            <th class="w-25">Type</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody [formGroup]=form>
          <ng-container formArrayName="clientSecrets" *ngFor="let clientSecret of form.get('clientSecrets').controls; let i = index;">
            <tr *ngIf=" clientSecret.get('deleteOperation').value ">
              <td>
                <span title="{{ clientSecret.get('id').value }}">{{ clientSecret.get('id').value}}</span>
              </td>
              <td>
                <div *ngIf="editId==i && showSaveClientSecretes">
                  <div class="form-row">
                        <input class="form-control col-8" placeholder="YYYY-MM-DD"
                         name="d2" ngbDatepicker #d2="ngbDatepicker" [formControl]="clientSecret.controls['expiration']">
                          <button class="btn btn-outline-secondary btn-line-height col-3" (click)="d2.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                    </div>
                </div>
                <span *ngIf="editId!==i || !showSaveClientSecretes" title="{{ clientSecret.get('expiration').value }}">{{ clientSecret.get('expiration').value  | date: 'yyyy-MM-dd'}}</span>
              </td>
              <td>
                <span *ngIf="editId==i && showSaveClientSecretes">
                  <input type="text" [formControl]="clientSecret.controls['description']" class="form-control-table" #input
                  [ngClass]="{ 'is-invalid val-margin-top': (clientSecret.controls.description.dirty || clientSecret.controls.description.touched) && clientSecret.controls.description.errors || clientSecret.controls.description.untouched && validationMsg && clientSecret.controls.description.invalid}">
                  <div *ngIf="(clientSecret.controls.description.dirty || clientSecret.controls.description.touched) && clientSecret.controls.description.errors || clientSecret.controls.description.untouched && validationMsg && clientSecret.controls.description.invalid" class="table-validation-msg text-left">
                    <div *ngIf="clientSecret.controls.description.errors.maxlength">Should not be more than 1000 characters</div>
                  </div>
                </span>
                <span *ngIf="editId!==i || !showSaveClientSecretes" title="{{ clientSecret.get('description').value }}">{{ clientSecret.get('description').value | slice:0:20}}</span>
              </td>
              <td>
                <span *ngIf="editId!==i || !showSaveClientSecretes" title="{{ clientSecret.get('value').value }}">{{ clientSecret.get('value').value | slice:0:20}}</span>
                <div *ngIf="editId==i && showSaveClientSecretes">
                  <input type="text" [formControl]="clientSecret.controls['value']" class="form-control-table d-inline width-size" title="{{ clientSecret.get('value').value }}"
                    readonly #input value="{{this.form.controls['clientSecrets'].value[i]['value']}}" 
                    [ngClass]="{ 'is-invalid val-margin-top': (clientSecret.controls.value.dirty || clientSecret.controls.value.touched) && clientSecret.controls.value.errors || clientSecret.controls.value.untouched && validationMsg && clientSecret.controls.value.invalid}">
                  <button type="button" (click)="updateHashValue(i)" class="d-inline btn btn-outline-secondary btn-line-height">
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                  </button>
                </div>
                <div *ngIf="(clientSecret.controls.value.dirty || clientSecret.controls.value.touched) && clientSecret.controls.value.errors || clientSecret.controls.value.untouched && validationMsg && clientSecret.controls.value.invalid" class="table-validation-msg val-margin-left text-left">
                  <div *ngIf="clientSecret.controls.value.errors?.required">Value is required</div>
                  <div *ngIf="clientSecret.controls.value.errors?.maxlength">Should not be more than 75 characters</div>
                </div>
                <div *ngIf="editId==i && showSaveClientSecretes && isHide">
                  <input type="text" class="form-control-table d-inline width-size" readonly value="{{hashValue}}">
                  <button type="button" (click)="isHide=false;" class="d-inline btn btn-outline-secondary btn-line-height">
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </button>
                </div>
              </td>
              <td>
                <span *ngIf="editId==i && showSaveClientSecretes">
                  <select id="country" [formControl]="clientSecret.controls['type']" class="dropdown-control" [ngClass]="{ 'is-invalid val-margin-top': (clientSecret.controls.type.dirty || clientSecret.controls.type.touched) && clientSecret.controls.type.errors || clientSecret.controls.type.untouched && validationMsg && clientSecret.controls.type.invalid}">
                    <option value="SharedSecret">SharedSecret</option>
                    <option value="X509Thumbprint">X509Thumbprint</option>
                    <option value="X509Name">X509Name</option>
                    <option value="X509CertificateBase64">X509CertificateBase64</option>
                  </select>
                  <div *ngIf="(clientSecret.controls.type.dirty || clientSecret.controls.type.touched) && clientSecret.controls.type.errors || clientSecret.controls.type.untouched && validationMsg && clientSecret.controls.type.invalid" class="table-validation-msg val-margin-left text-left">
                    <div *ngIf="clientSecret.controls.type.errors?.required">Type is required</div>
                    <div *ngIf="clientSecret.controls.type.errors?.maxlength">Should not be more than 75 characters</div>
                  </div>
                </span>
                <span *ngIf="editId!==i || !showSaveClientSecretes" title="{{ clientSecret.get('type').value }}">{{ clientSecret.get('type').value | slice:0:10}}</span>
              </td>
              <td class="text-center">
                <div class="btn-group" *ngIf="editId===i; else editCors">
                  <div class="btn-group" *ngIf="!showSaveClientSecretes; else editCors1">
                    <button class="btn custom-btn" type="button" (click)="toggle(i, 'clientSecrets')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                    <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'clientSecrets', 'deleteClientSecretsIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape delete-opacity"></button>
                  </div>
                  <ng-template #editCors1>
                    <div class="btn-group">
                      <button class="btn custom-btn" type="button" (click)="update()"><img src="../../../../assets/images/checked.svg" class="Combined-Shape"></button>
                      <button class="btn custom-btn" type="button" (click)="resetRedirectUri('clientSecrets')"><img src="../../../../assets/images/cancel.svg" class="Combined-Shape"></button>
                    </div>
                  </ng-template>
                </div>
                <ng-template #editCors>
                  <div class="btn-group">
                    <button class="btn custom-btn" type="button" (click)="toggle(i, 'clientSecrets')"><img src="../../../../assets/images/edit.svg" class="Combined-Shape"></button>
                    <button class="btn custom-btn" type="button" (click)="confirmModal(content, i, 'clientSecrets', 'deleteClientSecretsIds')"><img src="../../../../assets/images/delete.svg" class="Combined-Shape delete-opacity"></button>
                  </div>
                </ng-template>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #content let-close="close" let-dismiss="dismiss">
  <div class="modal-header">
    <span class="delete-icon-back"><img src="../../../../assets/images/delete.svg" class="delete-shape"></span><span class="modal-title">Delete Item</span>
    <button type="button" class="close" aria-label="Close" (click)="close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to delete this item?</p>
  </div>
  <div class="modal-footer">
    <div class="actions btn-toolbar mt-3 justify-content-end">
      <div class="mr-3">
        <div (click)="deleteRedirectUri()" class="btn-wrapper">
          <button class="btn-custom-primary-modal" type="button" [disabled]="submitting"><label class="btn-lable-primary-modal">{{ 'FE_General_Button_Ok' | translate }}</label></button>
        </div>
      </div>
      <div (click)="close('Close click')" class="btn-wrapper">
        <button class="btn-custom-secondary-modal" type="button"><label class="btn-lable-secondary-modal">{{ 'FE_General_Button_Cancel' | translate }}</label></button>
      </div>
    </div>
  </div>
</ng-template>