import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from '@modules/Customer/services/CustomerService';
import { CustomerFormBuilder } from '@modules/Customer/services/CustomerFormBuilder';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  templateUrl: 'customer-create.page.html',
  styleUrls: ['customer-create.page.scss'],
})
export class CustomerCreatePageComponent implements OnInit {
  public submitting = false;
  public error: any = null;
  public formCustomer: FormGroup;
  public disableLogoChange = true;
  public showMsg = false;

  constructor(
    private service: CustomerService,
    private formBuilder: CustomerFormBuilder,
    private router: Router,
    private exception: ExcepionHandlingService,
  ) { }

  ngOnInit() {
    this.createForm();
  }

  async createForm() {
    this.formCustomer = this.formBuilder.createEmpty();
  }

  async onSubmit() {
    if (this.formCustomer.invalid) {
      this.showMsg = true;
      return;
    }

    const customer = this.formBuilder.toModel(this.formCustomer);
    try {
      this.error = null;
      this.submitting = true;
      const result = await this.service.create(customer);
      this.router.navigate(['customers', result.result.id, 'general']);
    } catch (error) {
      this.error = this.exception.errorDisplay(error);
    } finally {
      this.submitting = false;
    }
  }
}
