import { DataService } from '@core/services/DataService';
import { Injectable } from '@angular/core';
import { IServerResponse, ILink, IServerCollectionResponse } from '@core/core.interfaces';
import { IIdentityServerClientResponse, IHashResponse, IGrantTypes } from '@modules/IdentityServer/identityServer.model';
import { find } from 'lodash';
import { Endpoints } from '@config/endpoints';

@Injectable()
export class IdentityClientService {
  public endpoints: ILink[];

  constructor(private dataService: DataService) { }

  async getAllIdentityServerClient(pageNumber: number, id: number = null, searchText: string = '') {
    const data = await this.dataService.request<IServerCollectionResponse<IIdentityServerClientResponse>>(Endpoints.GetIdentityServerClient,
      {
        query: {
          pageSize: 10,
          pageNumber: pageNumber,
          id: id,
          clientName: searchText,
        },
      });
    this.endpoints = data.result.links;
    return {
      records: data.result.records,
      pageInfo: data.pageInfo,
    };
  }

  async getIdentityClientByID(id: number): Promise<IIdentityServerClientResponse> {
    const { records } = await this.getAllIdentityServerClient(1, id);
    const client = find(records, { id });
    if (!client) {
      throw new Error('Client not found');
    }
    return client;
  }

  async updateIdentityServerClient(client: IIdentityServerClientResponse) {
    return this.dataService.request<IServerResponse<IIdentityServerClientResponse>>(Endpoints.UpdateIdentityServerClient, {
      links: client.links,
      data: client,
    });
  }

  async createIdentityServerClient(client: IIdentityServerClientResponse) {
    return this.dataService.request<IServerResponse<IIdentityServerClientResponse>>(Endpoints.CreateIdentityServerClient, {
      data: client,
    });
  }

  async getGrantTypes() {
    return this.dataService.request<IServerResponse<IGrantTypes>>(Endpoints.GetGrantTypes);
  }

  async updateHash() {
    return this.dataService.request<IServerResponse<IHashResponse>>(Endpoints.UpdateHash);
  }

  async delete(client: IIdentityServerClientResponse) {
    await this.dataService.request<IServerResponse<IIdentityServerClientResponse>>(Endpoints.DeleteClientResource, {
      links: client.links,
    });
  }

}
