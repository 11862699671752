import { User } from '@modules/Users/user.model';
var UserMapper = /** @class */ (function () {
    function UserMapper() {
    }
    UserMapper.prototype.deserialize = function (response) {
        var model = {
            id: response.id,
            username: response.username,
            firstName: response.firstName,
            lastName: response.lastName,
            lastLogin: response.lastLogin,
            links: response.links,
            isActive: response.isActive,
            email: response.email,
            phone: response.phone,
            phoneNumber: response.phoneNumber,
            changePasswordOnNextLogin: response.changePasswordOnNextLogin,
            lockoutEnd: response.lockoutEnd,
            lockoutEnabled: response.lockoutEnabled,
            emailConfirmed: response.emailConfirmed,
            roles: response.roles,
            userProduct: response.userProduct,
            loginType: response.loginType,
            enableMFA: response.enableMFA,
        };
        return new User(model);
    };
    UserMapper.prototype.deserializeMany = function (response) {
        var _this = this;
        return response.map(function (item) { return _this.deserialize(item); });
    };
    UserMapper.prototype.serialize = function (model) {
        return {
            id: model.id,
            username: model.username,
            firstName: model.firstName,
            lastName: model.lastName,
            isActive: model.isActive,
            lastLogin: model.lastLogin,
            email: model.email,
            phone: model.phone,
            phoneNumber: model.phoneNumber,
            changePasswordOnNextLogin: model.changePasswordOnNextLogin,
            lockoutEnd: model.lockoutEnd,
            lockoutEnabled: model.lockoutEnabled,
            emailConfirmed: model.emailConfirmed,
            roles: model.roles,
            userProduct: model.userProduct,
            loginType: model.loginType,
            enableMFA: model.enableMFA,
        };
    };
    return UserMapper;
}());
export { UserMapper };
