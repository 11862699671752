var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResourceFormBuilder } from '@modules/IdentityServer/services/ApiResourceFormBuilder';
import { ApiResourceService } from '@modules/IdentityServer/services/ApiResourceService';
import { ApiResourceEditComponent } from '@modules/IdentityServer/component/api-resource-edit/api-resource-edit.component';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';
var ApiResourceEditPageComponent = /** @class */ (function () {
    function ApiResourceEditPageComponent(route, formBuilder, service, router, exception) {
        this.route = route;
        this.formBuilder = formBuilder;
        this.service = service;
        this.router = router;
        this.exception = exception;
        this.loading = false;
        this.submitting = false;
        this.error = null;
    }
    ApiResourceEditPageComponent.prototype.ngOnInit = function () {
        this.loadIdentityResourceClient();
    };
    Object.defineProperty(ApiResourceEditPageComponent.prototype, "apiResourceId", {
        get: function () {
            return parseInt(this.route.snapshot.params.id, 10);
        },
        enumerable: true,
        configurable: true
    });
    ApiResourceEditPageComponent.prototype.loadIdentityResourceClient = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apiResources, form, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.loading = true;
                        return [4 /*yield*/, this.service.getById(this.apiResourceId)];
                    case 1:
                        apiResources = _a.sent();
                        form = this.formBuilder.fromModel(apiResources);
                        this.resource = apiResources;
                        this.form = form;
                        return [3 /*break*/, 4];
                    case 2:
                        err_1 = _a.sent();
                        this.error = this.exception.errorDisplay(err_1);
                        return [3 /*break*/, 4];
                    case 3:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ApiResourceEditPageComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.formComponent.removeItem();
                        if (this.form.invalid) {
                            return [2 /*return*/];
                        }
                        this.resource = this.formBuilder.map(this.form, this.resource);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.submitting = true;
                        this.loading = true;
                        return [4 /*yield*/, this.service.update(this.resource)];
                    case 2:
                        _a.sent();
                        this.router.navigate(['identity/api-resources']);
                        return [3 /*break*/, 5];
                    case 3:
                        error_1 = _a.sent();
                        this.error = this.exception.errorDisplay(error_1);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading = false;
                        this.submitting = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return ApiResourceEditPageComponent;
}());
export { ApiResourceEditPageComponent };
