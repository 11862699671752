import { tap, mergeMap } from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SessionService } from '@core/services/Session';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(session) {
        this.session = session;
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return this.session.observeSession().pipe(mergeMap(function (session) {
            var request = req;
            var accessToken = session.access_token;
            if (accessToken) {
                var duplicate = req.clone({
                    setHeaders: {
                        'Authorization': "Bearer " + accessToken,
                    },
                });
                request = duplicate;
            }
            var res = next.handle(request).pipe(tap(function (response) {
                if (response instanceof HttpResponse) {
                    _this.handleSuccessResponse(response);
                }
            }, function (error) {
                if (error instanceof HttpErrorResponse) {
                    _this.handleError(error);
                }
            }));
            return res;
        }));
    };
    AuthInterceptor.prototype.handleSuccessResponse = function (response) {
        if (response.status < 400) {
            // To consider
        }
    };
    AuthInterceptor.prototype.handleError = function (error) {
        if (error.status === 401) {
            this.session.resetSession();
        }
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
