var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Validators } from '@angular/forms';
import { FormHelper } from '@core/services/FormHelper';
import { customerRegexName } from '@config/constants';
var CustomerFormBuilder = /** @class */ (function (_super) {
    __extends(CustomerFormBuilder, _super);
    function CustomerFormBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomerFormBuilder.prototype.createEmpty = function () {
        return this.fromModel({
            name: '',
            databaseName: '',
            connectionString: '',
            products: [],
            logoImage: '',
        });
    };
    CustomerFormBuilder.prototype.fromModel = function (model) {
        return this.builder.group({
            name: [model.name, [
                    Validators.required,
                    Validators.pattern(customerRegexName),
                    Validators.maxLength(75)
                ],
            ],
            databaseName: [model.databaseName],
            connectionString: [model.connectionString],
            products: [model.products],
            logoImage: [model.logoImage],
        });
    };
    return CustomerFormBuilder;
}(FormHelper));
export { CustomerFormBuilder };
