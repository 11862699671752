/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./toast-message.component.ngfactory";
import * as i3 from "./toast-message.component";
import * as i4 from "@angular/common";
import * as i5 from "./toast-container.component";
import * as i6 from "./toast.service";
var styles_ToastContainerComponent = [i0.styles];
var RenderType_ToastContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastContainerComponent, data: { "animation": [{ type: 7, name: "fadeAnimation", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [], options: null }, { type: 1, expr: ":leave", animation: { type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translateY(10px) scale(.98)", height: 0, marginTop: 0 }, offset: null }, timings: 300 }, options: null }], options: {} }] } });
export { RenderType_ToastContainerComponent as RenderType_ToastContainerComponent };
function View_ToastContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "toast-wrapper"]], [[24, "@fadeAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "sf-toast-message", [["class", "toast"]], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.onClose(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ToastMessageComponent_0, i2.RenderType_ToastMessageComponent)), i1.ɵdid(2, 49152, null, 0, i3.ToastMessageComponent, [], { toast: [0, "toast"] }, { close: "close" })], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = "in"; _ck(_v, 0, 0, currVal_0); }); }
export function View_ToastContainerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { container: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 2, "div", [["class", "toast-container "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastContainerComponent_1)), i1.ɵdid(3, 802816, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toasts; var currVal_1 = _co.trackOrder; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_ToastContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sf-toast-container", [], null, null, null, View_ToastContainerComponent_0, RenderType_ToastContainerComponent)), i1.ɵdid(1, 8634368, null, 0, i5.ToastContainerComponent, [i6.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToastContainerComponentNgFactory = i1.ɵccf("sf-toast-container", i5.ToastContainerComponent, View_ToastContainerComponent_Host_0, {}, {}, []);
export { ToastContainerComponentNgFactory as ToastContainerComponentNgFactory };
