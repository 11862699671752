var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Validators } from '@angular/forms';
import { FormHelper } from '@core/services/FormHelper';
import { StringHelper } from '@core/utils/StringHelper';
import { map } from 'lodash';
var ApiResourceFormBuilder = /** @class */ (function (_super) {
    __extends(ApiResourceFormBuilder, _super);
    function ApiResourceFormBuilder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ApiResourceFormBuilder.prototype.createEmpty = function () {
        return this.fromModel({
            name: '',
            displayName: '',
            description: '',
            enabled: true,
            apiSecrets: [],
            scopes: [],
            userClaims: [],
            deleteApiSecretResourceIds: [],
            deleteApiScopeResourceIds: [],
        });
    };
    ApiResourceFormBuilder.prototype.fromModel = function (model) {
        var _this = this;
        return this.builder.group({
            id: [model.id || 0],
            name: [model.name, [
                    Validators.required,
                    Validators.pattern('[0-9A-Za-z_-]+'),
                    Validators.maxLength(75)
                ],
            ],
            displayName: [model.displayName, [
                    Validators.required,
                    Validators.maxLength(75)
                ],
            ],
            description: [model.description, [Validators.maxLength(1000)]],
            enabled: [model.enabled],
            userClaims: [model.userClaims],
            apiSecrets: this.builder.array(map(model.apiSecrets, function (secret) { return _this.createSecrets(secret); })),
            scopes: this.builder.array(map(model.scopes, function (scope) { return _this.createScopes(scope); })),
            deleteApiSecretResourceIds: [model.deleteApiSecretResourceIds],
            deleteApiScopeResourceIds: [model.deleteApiScopeResourceIds],
        });
    };
    ApiResourceFormBuilder.prototype.map = function (form, model) {
        _super.prototype.map.call(this, form, model);
        model.userClaims = StringHelper.toArray(model.userClaims);
        return model;
    };
    ApiResourceFormBuilder.prototype.createSecrets = function (secret) {
        return this.builder.group({
            id: secret.id || 0,
            description: [secret.description || '', [Validators.maxLength(1000)]],
            value: [secret.value || '', [Validators.required, Validators.minLength(0), Validators.maxLength(75)]],
            expiration: new Date(secret.expiration || new Date()),
            type: [secret.type || '', [Validators.required, Validators.minLength(0), Validators.maxLength(75)]],
            deleteOperation: 1,
        });
    };
    ApiResourceFormBuilder.prototype.createScopes = function (scope) {
        return this.builder.group({
            id: scope.id || 0,
            name: [scope.name || '', [Validators.required, Validators.minLength(0), Validators.maxLength(75)]],
            displayName: [scope.displayName || '', [Validators.required, Validators.minLength(0), Validators.maxLength(75)]],
            description: [scope.description || '', [Validators.maxLength(1000)]],
            required: scope.required || false,
            emphasize: scope.emphasize || false,
            showInDiscoveryDocument: scope.showInDiscoveryDocument || false,
            userClaims: scope.userClaims || [],
            deleteOperation: 1,
        });
    };
    return ApiResourceFormBuilder;
}(FormHelper));
export { ApiResourceFormBuilder };
