// tslint:disable-next-line:variable-name
export const Constants = {
  accessTokenAlias: 'simplifai.token',
};

export const DEFAULT_TOAST_TIMEOUT = 5000;
export const regexUrl1 = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._:/?#[\]@\$&=.]+$/;
export const regexUrl2 = /^(?:http(s)?:\/\/)[\w.-]+(:)+([a-zA-Z0-9-./])+$/;
export const regexName = /^([a-zA-Z\xC0-\uFFFF]{1,60}[ \-\']{0,1}){1,10}$/;
export const customerRegexName = /^[0-9A-Za-z\Æ\Ø\Å\æ\ø\å\_\-\s*]+$/;
