import { Component, OnInit } from '@angular/core';
import { config } from '@environments/environment';
import { Router } from '@angular/router';

@Component({
  templateUrl: 'logout-callback.page.html',
  styleUrls: ['logout-callback.page.scss'],
})
export class LogoutCallbackPageComponent implements OnInit {

  public url: string = config.publicUrl;
  constructor( private router: Router) { }

  ngOnInit() {
    this.router.navigate(['signout']);
  }

}
