<div class="col-12 row mt-2">
  <div class="custom-file col-md-4">
    <form #form>
      <input type="file" accept=".xls,.xlsx" class="custom-file-input" id="customFile" placeholder="Upload file" (change)="onFileChange($event)">
      <label class="custom-file-label" for="customFile">
        <span *ngIf="file">{{ file.name }}</span>
        <span *ngIf="!file">{{ 'FE_General_Lable_Choosefile' | translate }}</span>
      </label>
    </form>
  </div>
  <div class="col-md-2">
      <button class="btn-custom-secondary mr-3" type="button" (click)="upload()"><label (click)="upload()" class="btn-lable-secondary">{{ 'FE_General_Button_Upload' | translate }}</label></button>
  </div>
</div>
