import { OnInit } from '@angular/core';
var ProductsSelectPageComponent = /** @class */ (function () {
    function ProductsSelectPageComponent() {
        this.productData = [
            {
                'src': '../../../../assets/upload.png',
                'imgCaption': 'Simplifai Admin',
                'alt': 'simplifai admin',
            },
            {
                'src': '../../../../assets/upload.png',
                'imgCaption': 'Digital Assistant',
                'alt': 'digital assistant',
            },
            {
                'src': '../../../../assets/upload.png',
                'imgCaption': 'Invoice Reader',
                'alt': 'invoice reader',
            },
        ];
    }
    ProductsSelectPageComponent.prototype.ngOnInit = function () {
    };
    return ProductsSelectPageComponent;
}());
export { ProductsSelectPageComponent };
