import { Component, OnInit } from '@angular/core';
import { IPageInfoResponse } from '@core/core.interfaces';
import { IProduct } from '@modules/Product/product.model';
import { ProductService } from '@modules/Product/services/ProductService';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';

@Component({
  templateUrl: 'products.page.html',
  styleUrls: ['products.page.scss'],
})
export class ProductsPageComponent implements OnInit {

  public loading = false;
  public error: any = null;
  public products: IProduct[];
  public pageInfo: IPageInfoResponse;
  public page = 1;
  public searchText: string;
  public pageSize: number;
  public totalRecords: number;
  public currentPage: number;
  public totalPages: number;
  constructor(private service: ProductService, private exception: ExcepionHandlingService) { }

  ngOnInit() {
    this.loadProducts(1);
  }

  search(search: string) {
    this.searchText = search;
    this.loadProducts(1);
  }

  async loadProducts(pageNumber: number) {
    try {
      this.loading = true;
      const result = await this.service.getAll(pageNumber, this.searchText);
      this.products = result.records;
      this.pageInfo = result.pageInfo;
      this.totalRecords = this.pageInfo['totalRecords'];
      this.pageSize = this.pageInfo['pageSize'];
      this.currentPage = pageNumber;
      this.totalPages = this.pageInfo.totalPages;
    } catch (err) {
      this.error = this.exception.errorDisplay(err);
    } finally {
      this.loading = false;
    }
  }
}
