import { PipeTransform } from '@angular/core';
import { StringHelper } from '@core/utils/StringHelper';
var RolePermissionFilterPipe = /** @class */ (function () {
    function RolePermissionFilterPipe() {
    }
    RolePermissionFilterPipe.prototype.transform = function (roles, searchText) {
        if (!roles || !searchText) {
            return roles;
        }
        return StringHelper.search(roles, searchText);
    };
    return RolePermissionFilterPipe;
}());
export { RolePermissionFilterPipe };
