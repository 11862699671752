var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '@modules/Customer/services/CustomerService';
import { CustomerFormBuilder } from '@modules/Customer/services/CustomerFormBuilder';
import { ProductService } from '@modules/Product/services/ProductService';
import { UserService } from '@modules/Users/services/UserService';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserFormBuilder } from '@modules/Users/services/UserFormBuilder';
import { DomSanitizer } from '@angular/platform-browser';
import { ExcepionHandlingService } from '@core/services/ExceptionHandlingService';
var CustomerEditPageComponent = /** @class */ (function () {
    function CustomerEditPageComponent(customerService, productService, customerformBuilder, userFormBuilder, route, router, userService, modalService, sanitizer, exception) {
        this.customerService = customerService;
        this.productService = productService;
        this.customerformBuilder = customerformBuilder;
        this.userFormBuilder = userFormBuilder;
        this.route = route;
        this.router = router;
        this.userService = userService;
        this.modalService = modalService;
        this.sanitizer = sanitizer;
        this.exception = exception;
        this.loading = false;
        this.submitting = false;
        this.error = null;
        this.productCurrentPageNumber = 1;
        this.userCurrentPageNumber = 1;
        this.ngbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            centered: true,
            size: 'lg',
        };
        this.showMsg = false;
    }
    CustomerEditPageComponent.prototype.onTabChange = function ($event) {
        this.searchText = '';
        this.selectedTab = $event.nextId;
        if ($event.nextId === 'general') {
            this.loadCustomer();
        }
        else if ($event.nextId === 'products') {
            this.loadProducts(1);
        }
        else {
            this.loadUsers(1);
        }
        this.router.navigateByUrl(this.tabRoutes[$event.nextId]);
    };
    Object.defineProperty(CustomerEditPageComponent.prototype, "customerId", {
        get: function () {
            return this.route.snapshot.params.id;
        },
        enumerable: true,
        configurable: true
    });
    CustomerEditPageComponent.prototype.resetValidationMsg = function () {
        this.showMsg = false;
    };
    CustomerEditPageComponent.prototype.ngOnInit = function () {
        this.tabRoutes = {
            general: '/customers/' + this.customerId + '/general',
            products: '/customers/' + this.customerId + '/products',
            users: '/customers/' + this.customerId + '/users',
        };
        this.selectedTab = this.route.snapshot.params.tab;
        this.loadCustomer();
    };
    CustomerEditPageComponent.prototype.loadingToggle = function () {
        this.loading = !this.loading;
    };
    CustomerEditPageComponent.prototype.loadCustomer = function () {
        return __awaiter(this, void 0, void 0, function () {
            var customer, form, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.loading = true;
                        this.error = null;
                        return [4 /*yield*/, this.customerService.getById(this.customerId)];
                    case 1:
                        customer = _a.sent();
                        form = this.customerformBuilder.fromModel(customer);
                        this.customer = customer;
                        this.formCustomer = form;
                        if (this.selectedTab === 'products') {
                            this.loadProducts(1);
                        }
                        else if (this.selectedTab === 'users') {
                            this.loadUsers(1);
                        }
                        else if (this.selectedTab === 'general') {
                            this.getLogo(this.customer);
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        err_1 = _a.sent();
                        this.error = this.exception.errorDisplay(err_1);
                        return [3 /*break*/, 4];
                    case 3: return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CustomerEditPageComponent.prototype.getLogo = function (customer) {
        return __awaiter(this, void 0, void 0, function () {
            var logo, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, this.customerService.getLogo(customer)];
                    case 1:
                        logo = _a.sent();
                        if (logo.logoImage) {
                            this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + logo.logoImage);
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        err_2 = _a.sent();
                        this.error = this.exception.errorDisplay(err_2);
                        return [3 /*break*/, 4];
                    case 3:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CustomerEditPageComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var customer, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.formCustomer.invalid) {
                            return [2 /*return*/];
                        }
                        customer = this.customerformBuilder.map(this.formCustomer, this.customer);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.submitting = true;
                        return [4 /*yield*/, this.customerService.update(customer)];
                    case 2:
                        _a.sent();
                        this.router.navigate(['customers']);
                        return [3 /*break*/, 5];
                    case 3:
                        err_3 = _a.sent();
                        this.error = this.exception.errorDisplay(err_3);
                        return [3 /*break*/, 5];
                    case 4:
                        this.submitting = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CustomerEditPageComponent.prototype.searchUsers = function (search) {
        this.searchText = search;
        this.loadUsers(1);
    };
    CustomerEditPageComponent.prototype.searchProducts = function (search) {
        this.searchText = search;
        this.loadProducts(1);
    };
    CustomerEditPageComponent.prototype.loadProducts = function (productCurrentPageNumber) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.products = [];
                        this.totalProductPages = 1;
                        this.loading = true;
                        return [4 /*yield*/, this.productService.getAll(productCurrentPageNumber, this.searchText)];
                    case 1:
                        result = _a.sent();
                        this.products = result.records;
                        this.pageInfo = result.pageInfo;
                        this.productCollectionSize = this.pageInfo.totalRecords;
                        this.totalProductPages = this.pageInfo.totalPages;
                        return [3 /*break*/, 4];
                    case 2:
                        err_4 = _a.sent();
                        this.error = this.exception.errorDisplay(err_4);
                        return [3 /*break*/, 4];
                    case 3:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CustomerEditPageComponent.prototype.loadUsers = function (userCurrentPageNumber) {
        return __awaiter(this, void 0, void 0, function () {
            var result, err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        this.users = [];
                        this.totalUserPages = 1;
                        this.loading = true;
                        this.error = null;
                        return [4 /*yield*/, this.userService.getAll(this.customer, userCurrentPageNumber, this.searchText)];
                    case 1:
                        result = _a.sent();
                        this.users = result.records;
                        this.userPageInfo = result.pageInfo;
                        this.userCollectionSize = this.userPageInfo.totalRecords;
                        this.totalUserPages = this.userPageInfo.totalPages;
                        return [3 /*break*/, 4];
                    case 2:
                        err_5 = _a.sent();
                        this.error = this.exception.errorDisplay(err_5);
                        return [3 /*break*/, 4];
                    case 3:
                        this.loading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CustomerEditPageComponent.prototype.editModel = function (content) {
        this.modalReference = this.modalService.open(content, this.ngbModalOptions);
        this.createForm();
    };
    CustomerEditPageComponent.prototype.createForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.formUser = this.userFormBuilder.createEmpty();
                return [2 /*return*/];
            });
        });
    };
    CustomerEditPageComponent.prototype.createUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            var user, err_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.formUser.invalid) {
                            this.showMsg = true;
                            return [2 /*return*/];
                        }
                        user = this.userFormBuilder.toModel(this.formUser);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.error = null;
                        this.submitting = true;
                        if (this.validateModalReference !== undefined) {
                            this.validateModalReference.close();
                        }
                        return [4 /*yield*/, this.userService.create(user)];
                    case 2:
                        _a.sent();
                        this.loadUsers(1);
                        return [3 /*break*/, 5];
                    case 3:
                        err_6 = _a.sent();
                        this.error = this.exception.errorDisplay(err_6);
                        return [3 /*break*/, 5];
                    case 4:
                        this.submitting = false;
                        this.formUser.reset();
                        this.modalReference.close();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    CustomerEditPageComponent.prototype.validateUser = function (content) {
        return __awaiter(this, void 0, void 0, function () {
            var user, result, err_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.formUser.invalid) {
                            this.showMsg = true;
                            return [2 /*return*/];
                        }
                        user = this.userFormBuilder.toModel(this.formUser);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        this.error = null;
                        this.submitting = true;
                        return [4 /*yield*/, this.userService.validateUserByUserName(user)];
                    case 2:
                        result = _a.sent();
                        this.canCreate = result.canCreate;
                        if (result.isExists) {
                            this.validateModalReference = this.modalService.open(content, { centered: true });
                        }
                        else {
                            this.createUser();
                        }
                        return [3 /*break*/, 5];
                    case 3:
                        err_7 = _a.sent();
                        this.error = this.exception.errorDisplay(err_7);
                        this.validateModalReference.close();
                        return [3 /*break*/, 5];
                    case 4:
                        this.submitting = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return CustomerEditPageComponent;
}());
export { CustomerEditPageComponent };
