import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { ProductSelectComponent } from '@modules/AccessManager/component/product-select/product-select.component';
import { ClientSelectComponent } from '@modules/AccessManager/component/client-select/client-select.component';
import { ClientSelectService } from '@modules/AccessManager/services/client-select.service';

@NgModule({
  imports: [CoreModule],
  providers: [ClientSelectService],
  declarations: [ClientSelectComponent, ProductSelectComponent],
  exports: [ClientSelectComponent, ProductSelectComponent],
})
export class AccessManagerModule {}
